
<p-toolbar styleClass="p-mb-4">
  <ng-template pTemplate="left">
    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
    <button class="mg-r-15" pButton icon="fa fa-plus" label="CRÉER" pTooltip="Créer un plat ou une denrée"
      (click)="produitMenu.toggle($event)" [disabled]="!canCreate()" [class.disable]="!canCreate()">
    </button>
    <p-menu appendTo="body" #produitMenu [popup]="true" [model]="itemsProduitMenu"></p-menu>
    <span class="p-input-icon-left">
      <dx-text-box class="tagbox" [width]="300" valueChangeEvent="keyup" (onValueChanged)="announceGlobalFilterSearch($event)" placeholder="Filtrer par recherche globale..."></dx-text-box>
    </span>
    <dx-tag-box class="tagbox" [multiline]="false" [width]="250" (onValueChanged)="onChangeSitesFilter($event)" placeholder="Filtrer par site..." displayExpr="libelle" valueExpr="id" [searchEnabled]="true" [dataSource]="sitesUtilisateurConnecteItems"></dx-tag-box>
    <dx-tag-box class="tagbox" [multiline]="false" [width]="250" (onValueChanged)="onChangeTypesProduitFilter($event)" placeholder="Filtrer par type de produit..." displayExpr="libelle" valueExpr="id" [searchEnabled]="true" [dataSource]="allTypesProduitsItems"></dx-tag-box>
    <dx-tag-box class="tagbox" [multiline]="false" [width]="250" (onValueChanged)="onChangeDeclinaisonsFilter($event)" placeholder="Filtrer par déclinaison..." displayExpr="libelle" valueExpr="id" [searchEnabled]="true" [dataSource]="allDeclinaisonsItems"></dx-tag-box>
    <dx-tag-box class="tagbox" [multiline]="false" [width]="250" (onValueChanged)="onChangeFamillesProduitFilter($event)" placeholder="Filtrer par famille de produit..." displayExpr="libelle" valueExpr="id" [searchEnabled]="true" [dataSource]="allFamillesProduitsItems"></dx-tag-box>
    <dx-tag-box class="tagbox" [multiline]="false" [width]="250" (onValueChanged)="onChangeRegimesFilter($event)" placeholder="Filtrer par régime..." displayExpr="libelle" valueExpr="id" [searchEnabled]="true" [dataSource]="allRegimesItems"></dx-tag-box>
  </ng-template>
</p-toolbar>

<dx-data-grid
  [dataSource]="dataSource"
  [remoteOperations]="true"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(182)"
  width="100%"
  [allowColumnResizing]="true"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  #grid>
  <dxo-filter-row [visible]="true"></dxo-filter-row>

  <dxi-column dataField="id" caption="Actions" cellTemplate="cellActionsTemplate" width="140" [allowSorting]="false" [allowFiltering]="false"></dxi-column>
  <dxi-column dataField="code" caption="Code" width="auto"></dxi-column>
  <dxi-column dataField="hasPhoto" caption="Photo" cellTemplate="cellPhotoTemplate" width="140" [allowSorting]="false" [allowFiltering]="false"></dxi-column>
  <dxi-column dataField="site.libelle" caption="Site" cellTemplate="cellSiteTemplate" [allowFiltering]="false"></dxi-column>
  <dxi-column dataField="typeProduit.libelle" caption="Types de produit" cellTemplate="cellTypesProduitTemplate" [allowFiltering]="false"></dxi-column>
  <dxi-column dataField="libelle" caption="Produit" cellTemplate="cellProduitTemplate" width="auto"></dxi-column>
  <dxi-column dataField="produitDeclinaisons.declinaison.libelle" caption="Déclinaison" cellTemplate="cellDeclinaisonsTemplate" [allowFiltering]="false"></dxi-column>
  <dxi-column dataField="familleProduit.libelle" caption="Famille de produit" cellTemplate="cellFamilleProduitTemplate" [allowFiltering]="false"></dxi-column>
  <dxi-column dataField="produitContrainteAlimList" caption="Régimes" cellTemplate="cellRegimeTemplate" [allowSorting]="false" [allowFiltering]="false"></dxi-column>
  <dxi-column dataField="actif" caption="Actif" width="auto"></dxi-column>

  <ng-container *dxTemplate="let cell of 'cellActionsTemplate'">
    <yo-cell-button (yoNavigation)="openFicheIdentite(cell.row.data)" [yoTooltipShowDelay]="1500" [yoMaxWidth]="true"
                    [yoWidthPercent]="30" [yoTextAlign]="'center'" [yoIconClass]="'fa fa-eye'" tooltipPosition="right" showDelay="500"
                    [pTooltip]="'Voir le produit ' + cell.row.data.libelle">
    </yo-cell-button>
    <ng-container *ngIf="auth2Svc.isSiteLocal(cell.row.data.site.id)">
      <yo-cell-button (yoNavigation)="confirmDelete(cell.row.data)" [yoTooltipShowDelay]="1500" [yoMaxWidth]="true" [yoWidthPercent]="30"
                      [yoTextAlign]="'center'" [yoIconClass]="'fa fa-trash'" tooltipPosition="right" showDelay="500"
                      [pTooltip]="'Supprimer le produit ' + cell.row.data.libelle">
      </yo-cell-button>
    </ng-container>
    <ng-container *ngIf="cell.row.data.typeProduit.fabrique">
      <yo-cell-button (yoNavigation)="openPanelDuplication($event, cell.row.data)" [yoTooltipShowDelay]="1500" [yoMaxWidth]="true"
                      [yoWidthPercent]="30" [yoTextAlign]="'center'" [yoIconClass]="'fa fa-copy'" tooltipPosition="right" showDelay="500"
                      [pTooltip]="'Dupliquer le produit ' + cell.row.data.libelle">
      </yo-cell-button>
    </ng-container>
  </ng-container>

  <ng-container *dxTemplate="let cell of 'cellPhotoTemplate'">
    <ng-container [ngSwitch]="cell.row.data.hasPhoto">
      <ng-container *ngSwitchCase="true">
        <yo-img-entity [entityId]="cell.row.data.id" [refresh]="1" [entityName]="produitsSvc.getEntityName()" [height]="50"
                       [openable]="true" format="thumb" [displayNoImage]="false" [title]="cell.row.data.libelle" [ordre]="1">
        </yo-img-entity>
      </ng-container>
      <ng-container *ngSwitchCase="false">
        <yo-img-entity [entityId]="cell.row.data.id" [refresh]="1" [height]="50" format="thumb" [displayNoImage]="true"
                       [title]="cell.row.data.libelle" [ordre]="1">
        </yo-img-entity>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *dxTemplate="let cell of 'cellSiteTemplate'">
    <yo-site [siteId]="cell.row.data.site.id" [siteLibelle]="cell.row.data.site.libelle" [yoDelayMsTooltip]="1500"></yo-site>
  </ng-container>

  <ng-container *dxTemplate="let cell of 'cellTypesProduitTemplate'">
    <yo-icon-produitfab [fabrique]="cell.row.data.typeProduit.fabrique" [yoDelayMsTooltip]="1500"></yo-icon-produitfab>
    {{ cell.row.data.typeProduit.libelle | uppercase }}
  </ng-container>

  <ng-container *dxTemplate="let cell of 'cellProduitTemplate'">
    <span class="hover-background-grey no-overflow" style="width:200px;" showDelay="300" tooltipPosition="left"
          (click)="openFicheIdentite(cell.row.data)" [pTooltip]="'Voir le produit '+ cell.row.data.libelle">
      {{ cell.row.data.libelle}}
    </span>
  </ng-container>

  <ng-container *dxTemplate="let cell of 'cellFamilleProduitTemplate'">
      <label class="badge badge-secondary zoom cursor">
        {{ cell.row.data.familleProduit.libelle }}
      </label>
  </ng-container>

  <ng-container *dxTemplate="let cell of 'cellDeclinaisonsTemplate'">
    <table>
      <tr>
        <td style="vertical-align: top;padding:4px; width:40px !important;">
          <yo-cell-button [yoTooltipShowDelay]="1500" [yoMaxWidth]="true" [yoWidthPercent]="45" yoTextAlign="center" showDelay="500"
                          tooltipPosition="left" [pTooltip]="tooltipCreationDeclinaison()" [yoDisabled]="!canCreate()" yoIconClass="fa fa-plus"
                          (yoNavigation)="openProduitDeclinaison(cell.row.data,undefined)">
          </yo-cell-button>
        </td>
        <td style="vertical-align: top;padding:4px;">
          <ng-container *ngFor="let pd of cell.row.data.produitDeclinaisons;trackBy : utils.trackByFn">
            <div>
              <span *ngIf="!pd.fabrique; else plat">
                <label class="badge badge-secondary zoom cursor" pTooltip="Fiche de la déclinaison {{pd.libelle}}" tooltipPosition="left" showDelay="800"
                       (click)="openProduitDeclinaison(cell.row.data,pd)">
                  {{(pd.libelle.length > 15) ? (pd.libelle | slice:0:15) + '..' : (pd.libelle) | uppercase}}
                </label>
              </span>
              <ng-template #plat>
                <label class="badge badge-secondary zoom cursor" pTooltip="Fiche de la déclinaison {{pd.declinaison.libelle}}" tooltipPosition="left" showDelay="800"
                       (click)="openProduitDeclinaison(cell.row.data,pd)">
                  {{pd.declinaison.libelle | uppercase}}
                </label>
              </ng-template>
            </div>
          </ng-container>
        </td>
      </tr>
    </table>

  </ng-container>

  <ng-container *dxTemplate="let cell of 'cellRegimeTemplate'">
    <div style="vertical-align: top;padding:4px;" *ngFor="let contrainte of produitContrainteAlimSvc.convertToContraintes(cell.row.data.produitContrainteAlimList)">
      <label class="badge badge-secondary zoom cursor">
        {{ contrainte.libelle }}
      </label>
    </div>
  </ng-container>

  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} produit(s)">
  </dxo-pager>

</dx-data-grid>

<!--PANEL DUPLICATION-->
<p-overlayPanel #opDuplication>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group ">
        <label
          [class.requiredControl]="true"
          class="genlabel  font-16"
          pTooltip=""
          showDelay="500">Site sur lequel dupliquer le produit</label>
        <div class="col-sm-10">
          <p-dropdown [options]="auth2Svc.utilisateur.siteListLocaux"
                      optionLabel="libelle"
                      filter="true"
                      [(ngModel)]="duplicationSiteSelected"
                      placeholder="sélection..."
                      [autoDisplayFirst]="false"
                      [style]="{'width':'360px'}">
            <ng-template let-site pTemplate="selectedItem">
          <span style="vertical-align:middle; margin-left: .5em">
            <yo-site [siteId]="site.id" [siteLibelle]="site.libelle"></yo-site></span>
            </ng-template>
            <ng-template let-site pTemplate="item">
              <div style="position: relative;height: 25px;">
                <div style="font-size:14px;margin-top:4px">
                  <yo-site [siteId]="site.id" [siteLibelle]="site.libelle"></yo-site>
                </div>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 text-right">
      <button pButton
              label="Dupliquer"
              icon="fas fa-copy"
              (click)="duplicateProduit()"
              [disabled]="duplicationSiteSelected === undefined ? true : false">
      </button>
    </div>
  </div>
</p-overlayPanel>
