<div class="inline">

  <ng-container *ngIf="workflowInstance.codeWorkflowStatut != 'end'">

    <p-slideMenu #menu [model]="nextStepItems"
                 [style]="{'width':'350px'}"
                 [menuWidth]="350"
                 appendTo="body" [popup]="true"></p-slideMenu>
    <button
      *ngIf="play"
      pButton
      class="p-button-secondary"
      [disabled]="!workflowSvc.canModifyCurrentStep(workflowInstance)"
      icon="fas fa-play"
      pTooltip="Passer à une des étapes suivantes"
      showDelay="800"
      tooltipPosition="top"
      (click)="menu.toggle($event)">
    </button>

    <button
      *ngIf="!play"
      pButton
      iconPos="right"
      type="button"
      class="p-button-primary"
      label="Suivant"
      pTooltip="Passer à une des étapes suivantes"
      tooltipPosition="bottom"
      showDelay="800"
      icon="fas fa-angle-right"
      [disabled]="!workflowSvc.canModifyCurrentStep(workflowInstance)"
      (click)="menu.toggle($event)">
    </button>


  </ng-container>


</div>

<p-dialog
  [dismissableMask]="false"
  header="CONFIRMATION DU PASSAGE EN COMMANDE"
  [contentStyle]="{'overflow':'visible'}"
  [responsive]="true"
  [modal]="true"
  [minY]="200"
  [style]="{'width':'800px'}"
  [(visible)]="displayDialogPassageCommande">

  <div class="card">

    <div class="card-body">
      <p>🗣️ ATTENTION, vous ne pourrez plus modifier votre liste de besoins après votre passage en commande.</p>
      <br/>
      <p>
        <i class="fas fa-arrow-right space"></i>{{lbdActifs}} article(s) sélectionné(s) dans votre liste de besoins.
      </p>
      <br/>
      <ng-container *ngIf="listeBesoinEnErreur">
        <p><i class="fas fa-exclamation-triangle danger-color space"></i><b>Votre liste de besoins a {{lbErreurs}}
          article(s) en erreur, veuillez le(s) corriger. </b></p>
        <p style="line-height: 35px;">
          <p-checkbox [(ngModel)]="continueWithErrorsToPassageCommande" binary="true"></p-checkbox>
          Continuer quand même. <em>Les articles en erreur ne seront pas pris en compte.</em>
        </p>

      </ng-container>
    </div>
  </div>

  <ng-template pTemplate="footer">

    <ng-container *ngIf="!listeBesoinEnErreur">
      <button type="button" pButton icon="fas fa-check" (click)="goToCommande(workflowInstance)"
              label="PASSER EN COMMANDE"></button>
    </ng-container>
    <ng-container *ngIf="listeBesoinEnErreur">
      <ng-container *ngIf="continueWithErrorsToPassageCommande">
        <button type="button" pButton icon="fas fa-check" (click)="goToCommande(workflowInstance)"
                label="PASSER EN COMMANDE"></button>
      </ng-container>
      <ng-container *ngIf="!continueWithErrorsToPassageCommande">
        <button type="button" pButton icon="fas fa-check" disabled (click)="goToCommande(workflowInstance)"
                label="PASSER EN COMMANDE"></button>
      </ng-container>


    </ng-container>

    <button type="button" pButton icon="fas fa-close" (click)="displayDialogPassageCommande=false" label="ANNULER"
            class="p-button-secondary"></button>
  </ng-template>

</p-dialog>
