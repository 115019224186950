import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ModelViewMatierePremiere} from '../model-view-matiere-premiere';
import {SearchSupplierWrapper} from '../../../../core/suppliers/wrappers/search-supplier-wrapper';
import {UtilsService} from '../../../../core/utils/utils.service';
import {ProduitArticleService} from '../../../../core/services/entities/produit-article.service';
import {StocksService} from '../../../../core/services/gestion-stock/stocks.service';
import {StockDTO} from '../../../../core/dtos/stock-dto';
import {DATEPICKER_FR, MSG_KEY, MSG_SEVERITY} from '../../../../core/constants';
import {StockMouvementStatutDTO} from '../../../../core/dtos/stock-mouvement-statut-dto';
import {StockMouvementDTO} from '../../../../core/dtos/stock-mouvement-dto';
import {StockMouvementStatutService} from '../../../../core/services/gestion-stock/stock-mouvement-statut.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {GenericFormService} from '../../../../core/services/generics/generic-form.service';
import {ToastService} from "../../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-dialog-sortie',
  templateUrl: './dialog-sortie.component.html',
  styleUrls: ['./dialog-sortie.component.scss']
})
export class DialogSortieComponent implements OnInit, OnDestroy {

  subAction: Subscription;

  displayDialog = false;
  selectedStock: StockDTO;
  ssw: SearchSupplierWrapper;
  matierePremiere:ModelViewMatierePremiere;

  form: FormGroup;

  mouvementStatutList: StockMouvementStatutDTO[] = [];


  localeFr = DATEPICKER_FR;

  constructor(public utils: UtilsService,
              private produitArticleSvc: ProduitArticleService,
              public stockSvc: StocksService,
              private gfs: GenericFormService,
              public stockMvtStatutSvc: StockMouvementStatutService,
              private toastSvc: ToastService) {
  }

  initForm = () => {

    let max = 0;

    if (this.selectedStock && this.selectedStock.quantite) {
      max = this.selectedStock.quantite;
    }

    this.form = new FormGroup({
      quantite: new FormControl(0, [Validators.required, Validators.min(0.0001), Validators.max(max)]),
      dateSortie: new FormControl(new Date(), Validators.required),
      motif: new FormControl(undefined, Validators.required),
      remarque: new FormControl(undefined),
    });
  };

  ngOnInit() {

    this.initForm();

    this.subAction = this.stockSvc.openDialogSortie$.subscribe(response => {

      // console.log('subOpenDialog', response);
      this.displayDialog = true;
      this.selectedStock = response.selectedStock;
      this.ssw = response.ssw;
      this.matierePremiere = response.matierePremiere;

      this.stockMvtStatutSvc.getAll(true, false)
        .subscribe(o => this.mouvementStatutList = o.resultList );

      this.initForm();

    });

  }

  closeDialog = () => {
    this.displayDialog = false;
  };

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subAction);
  }

  sortirLot = () => {


    this.gfs.validateAllFormFields(this.form);

    if (this.form.valid) {

      const dateSortie = this.form.controls['dateSortie'].value;
      const remarque = this.form.controls['remarque'].value;
      const quantite = this.form.controls['quantite'].value;
      const motif = this.form.controls['motif'].value;

      if(this.selectedStock.dateEntree <= dateSortie){

      const stockMouvement = new StockMouvementDTO(dateSortie, remarque, quantite, 0, this.selectedStock.id, motif.code, motif.id, dateSortie);

      this.stockMvtStatutSvc.saveSortie(stockMouvement).subscribe(response => {

        if (!this.utils.isNullOrEmpty(response)) {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `La sortie a été enregistrée avec succès`);
          this.stockSvc.announceLoadStocks(this.matierePremiere);
          this.closeDialog();
        }

      });
      }else{
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `La date de sortie ne peut pas être inférieure ou égale à la date d'entrée`);
      }
    }
  };

  startError = (property: string) => `Le champ "${property.toUpperCase()}" `;
}
