<div class="d-flex">
  <div class="mg-r-30" style="width: 60%; overflow-y: auto;">
    <p-toolbar styleClass="p-mb-4">
      <ng-template pTemplate="left">
        <yo-help class="mg-r-15" lang="fr" [file]="pathFileProfils" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
        <button pButton
                type="button"
                icon="fas fa-plus"
                label="Créer"
                [pTooltip]="'Créer un profil'"
                (click)="addProfilDialog()">
        </button>
      </ng-template>
    </p-toolbar>

    <dx-data-grid
      [dataSource]="profilList"
      keyExpr="id"
      [height]="utils.getWindowAvailableHeight(182)"
      width="100%"
      [hoverStateEnabled]="true"
      [rowAlternationEnabled]="true"
      [showRowLines]="true"
      [showBorders]="true"
      #grid>

      <dxi-column dataField="id" caption="Actions" cellTemplate="templateActions" [width]="100" [allowFiltering]="false"></dxi-column>
      <dxi-column dataField="libelle" caption="Libellé" cellTemplate="templateLabel" [width]="150"></dxi-column>
      <dxi-column dataField="code" caption="Code" [width]="200"></dxi-column>
      <dxi-column dataField="description" caption="Description" cellTemplate="templateDesc" [width]="350"></dxi-column>
      <dxi-column dataField="actif" caption="Actif" [width]="100"></dxi-column>
      <dxi-column dataField="roles" caption="Rôles associés" cellTemplate="templateRoles" [allowFiltering]="false"></dxi-column>

      <div *dxTemplate="let cell of 'templateActions'">
        <ng-container *ngIf="canDoActions(cell.row.data)">
          <span class="mg-r-5">
            <button pButton class="p-button-secondary" icon="fas fa-edit" (click)="onChangeProfil(cell.row.data)" pTooltip="Modifier le profil"></button>
          </span>
          <span class="mg-r-5">
            <button pButton class="p-button-secondary" icon="fas fa-trash" (click)="deleteProfil(cell.row.data)" pTooltip="Supprimer le profil"></button>
          </span>
        </ng-container>
      </div>
      <div *dxTemplate="let cell of 'templateLabel'">
        <strong>{{ cell.row.data.libelle }}</strong>
      </div>
      <div *dxTemplate="let cell of 'templateDesc'" style="white-space: pre-wrap;">
        {{ cell.row.data.description }}
      </div>
      <div *dxTemplate="let cell of 'templateRoles'">
        <ng-container *ngTemplateOutlet="tplRoles;context:{profil: cell.row.data}"></ng-container>
      </div>

      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-sorting mode="multiple"></dxo-sorting>

      <dxo-pager
        [showPageSizeSelector]="true"
        [showNavigationButtons]="false"
        [allowedPageSizes]="[20, 50, 100, 200]"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} profil(s)">
      </dxo-pager>

    </dx-data-grid>
  </div>

  <div style="width: 40%">
    <p-toolbar styleClass="p-mb-4">
      <ng-template pTemplate="left">
        <yo-help class="mg-r-15" lang="fr" [file]="pathFileRoles" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
        Description des rôles
      </ng-template>
    </p-toolbar>
    <dx-data-grid
      [dataSource]="roles"
      keyExpr="id"
      [height]="utils.getWindowAvailableHeight(182)"
      width="100%"
      [hoverStateEnabled]="true"
      [rowAlternationEnabled]="true"
      [showRowLines]="true"
      [showBorders]="true"
      #grid>
      <dxi-column dataField="libelle" caption="Libellé"></dxi-column>
      <dxi-column dataField="description" caption="Description"></dxi-column>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-sorting mode="multiple"></dxo-sorting>
      <dxo-pager
        [showPageSizeSelector]="true"
        [showNavigationButtons]="false"
        [allowedPageSizes]="[20, 50, 100, 200]"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} description(s)">
      </dxo-pager>
    </dx-data-grid>

  </div>

</div>
<!--TEMPLATE ROLES ASSOCIES AU PROFIL-->
<ng-template #tplRoles let-profil="profil">

  <ng-container *ngFor="let role of profil.roleList">
    <div class="row">
      <div class="col-md-12">
        <span class="mg-r-5  font-12" [pTooltip]="role.description"
              showDelay="500">{{role.libelle}}</span>
      </div>
    </div>
  </ng-container>

</ng-template>

<!--AJOUT - MODIFICATION D'UN PROFIL-->
<p-dialog

  (onHide)="closeDialog()"
  [dismissableMask]="false"
  positionTop="80"
  positionLeft="80"
  [contentStyle]="{'overflow':'visible'}"
  [style]="{'min-width':'1000px'}"
  [(visible)]="displayProfilRoleDialog"
  showEffect="fade"
  [modal]="true"
>

  <ng-template pTemplate="header">
    <div class="p-dialog-title">

      <ng-container *ngIf="isEditMode">
        <i class="fas fa-id-card-alt mg-r-5"></i> Modification du profil
        <strong>{{dialogProfil?.libelle}}</strong>
      </ng-container>

      <ng-container *ngIf="!isEditMode">
        Nouveau profil
      </ng-container>
    </div>

  </ng-template>

  <ng-container *ngIf="dialogProfil">

    <!--  CONTENU-->
    <div class="row">
      <div class="col-md-6">
        <div class="form-group row">
          <div class="col-sm-3 col-form-label">
            <h3 class="first"> Profil</h3>
          </div>
          <div class="col-sm-9">
            <input type="text" class="form-control form-control-sm "
                   [(ngModel)]="dialogProfil.libelle">
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-3 col-form-label">
            <h3 class="first"> Description</h3>
          </div>
          <div class="col-sm-9">
      <textarea
        [(ngModel)]="dialogProfil.description"
        class="form-control form-control-sm"
        rows="10"
      >
        </textarea>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <h3 class="first">Rôles</h3>
        <p-listbox [options]="roles"
                   [style]="{'max-width':'400px','width':'400px'}"
                   [listStyle]="{'max-height':'400px'}"
                   (onChange)="changeRoles($event)"
                   [(ngModel)]="selectedRoles"
                   multiple="multiple"
                   checkbox="checkbox"
                   filter="filter"
                   optionLabel="libelle">
          <ng-template let-item pTemplate="item">
            {{item.libelle}}
          </ng-template>
        </p-listbox>
      </div>
    </div>

    <div class="row mg-t-10">
      <div class="col-md-12" style="height:68px">
        <div *ngFor="let errMsg of dialogErrMsgList" class="danger-color font-14">
          {{errMsg}}
        </div>
      </div>
    </div>

  </ng-container>

  <ng-template pTemplate="footer">
       <span class="mg-r-5">
     <button
       pButton
       icon="fas fa-save"
       (click)="saveProfil()"
       showDelay="500"
       pTooltip="Mettre à jour"
     >
     </button>
    </span>


    <button type="button" pButton icon="fas fa-times" class="p-button-secondary" (click)="closeDialog()"
            pTooltip="Fermer"
            showDelay="500"></button>
  </ng-template>


</p-dialog>
