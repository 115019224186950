<div class="d-flex flex-grow-1" [style.height]="'calc(68vh)'">
  <div>

    <span class="mg-l-5">
      <p-menu appendTo="body" #ajoutArticleMenu [popup]="true" [model]="itemsArticleMenu"></p-menu>
      <button type="button"
              pButton
              label="Ajouter"
              pTooltip="Ajouter des articles au lot marché"
              icon="fa fa-plus"
              class="mg-r-5"
              [disabled]="isDisabled()"
              (click)="ajoutArticleMenu.toggle($event)"
              tooltipPosition="bottom"
              showDelay="500">
      </button>
    </span>

    <dx-data-grid
      [dataSource]="caOwnedByLotMarche"
      keyExpr="id"
      [height]="utils.getWindowAvailableHeight(350)"
      [width]="utils.getWindowAvailableWidth(200)"
      [hoverStateEnabled]="true"
      [allowColumnResizing]="true"
      [rowAlternationEnabled]="true"
      [showRowLines]="true"
      [showBorders]="true"
      (onCellPrepared) ="onCellPrepared($event)"
      (onRowUpdating)="onRowUpdating($event)"
      #gridSelectedCatalogueAchat>

      <!--  COLONNES-->
      <dxi-column dataField="id" alignment="center" caption="Actions"
                  [allowFiltering]="false"
                  [allowGrouping]="false"
                  [allowEditing]="false"
                  cellTemplate="actionsCellTemplate">
      </dxi-column>
      <dxi-column dataField="produitArticle.produitDeclinaison.libelle" alignment="left" caption="Denrée"  [groupIndex]="0"
                  [allowEditing]="false"
                  [allowFiltering]="true">

      </dxi-column>
      <dxi-column dataField="fournisseur.libelle" alignment="left" caption="Fournisseur"  [groupIndex]="1"
                  [allowEditing]="false"
                  [allowFiltering]="true" cellTemplate="fournisseurCellTemplate">
      </dxi-column>
      <dxi-column dataField="produitArticle.libelle" alignment="left" caption="Article"
                  [allowEditing]="false"
                  [allowFiltering]="true">
      </dxi-column>
      <dxi-column dataField="referenceArticle" alignment="left" caption="Référence article"
                  [allowEditing]="false"
                  [allowFiltering]="true">
      </dxi-column>

      <dxi-column dataField="uniteDeCommande.id" alignment="left" caption="Unité de commande"
                  cellTemplate="uniteDeCommandeCellTemplate"
                  [allowFiltering]="true">
        <dxo-lookup
          [dataSource]="uniteDeMesureList"
          displayExpr="libelle"
          valueExpr="id">
        </dxo-lookup>
      </dxi-column>

      <dxi-column dataField="ratioUniteCommandeUniteBase" alignment="left" caption="Ratio(UC)"
                  [allowEditing]="true"
                  [allowFiltering]="true">
      </dxi-column>

      <dxi-column dataField="uniteDeStockage.id" alignment="left" caption="Unité de stockage"
                  cellTemplate="uniteDeStockageCellTemplate"
                  [allowFiltering]="true">
        <dxo-lookup
          [dataSource]="uniteDeMesureList"
          displayExpr="libelle"
          valueExpr="id">
        </dxo-lookup>
      </dxi-column>

      <dxi-column dataField="ratioUniteStockageUniteBase" alignment="left" caption="Ratio(US)"
                  [allowEditing]="true"
                  [allowFiltering]="true">
      </dxi-column>

      <dxi-column dataField="uniteDeFacturation.id" alignment="left" caption="Unité de facturation"
                  cellTemplate="uniteDeFacturationCellTemplate"
                  [allowFiltering]="true">
        <dxo-lookup
          [dataSource]="uniteDeMesureList"
          displayExpr="libelle"
          valueExpr="id">
        </dxo-lookup>
      </dxi-column>

      <dxi-column dataField="ratioUniteFacturationUniteBase" alignment="left" caption="Ratio(UF)"
                  [allowEditing]="true"
                  [allowFiltering]="true">
      </dxi-column>

      <dxi-column dataField="prix" alignment="left" caption="Prix(UF)"
                  [allowEditing]="true"
                  [allowFiltering]="true"
                  cellTemplate="prixCellTemplate">
      </dxi-column>

      <!--  TEMPLATE-->
      <div *dxTemplate="let cell of 'actionsCellTemplate'">
        <yo-cell-button (yoNavigation)="removeArticle(cell.row.data)"
                        [yoTooltipShowDelay]="1500"
                        [yoMaxWidth]="true"
                        [yoWidthPercent]="30"
                        [yoDisabled]="isDisabled()"
                        [yoTextAlign]="'center'"
                        [yoIconClass]="'fa fa-trash'"
                        pTooltip="Supprimer l'article"
                        tooltipPosition="right"
                        showDelay="500">
        </yo-cell-button>
      </div>

      <div *dxTemplate="let cell of 'produitDecliCellTemplate'">
        {{cell.row.data.produitArticle.produitDeclinaison.libelle}}
      </div>

      <div class="d-flex justify-content-between" *dxTemplate="let cell of 'fournisseurCellTemplate'">
        <label class="font-12">
          {{cell.row.data.fournisseur.libelle}}
        </label>
        <div class="size-icon" *ngIf="!utils.isNullOrEmpty(cell.row.data.fournisseurOwnerLibelle)"
             [pTooltip]="'Hérité du founisseur : ' +cell.row.data.fournisseurOwnerLibelle">
          <span class="fa-stack fa-2x">
            <i class="fas fa-circle fa-stack-2x"></i>
            <strong class="fa-stack-1x custom-letter">H</strong>
          </span>
        </div>
      </div>

      <div *dxTemplate="let cell of 'uniteDeCommandeCellTemplate'">
        {{cell.row.data.uniteDeCommande.abreviation}}
      </div>

      <div *dxTemplate="let cell of 'uniteDeStockageCellTemplate'">
        {{cell.row.data.uniteDeStockage.abreviation}}
      </div>

      <div *dxTemplate="let cell of 'uniteDeFacturationCellTemplate'">
        {{cell.row.data.uniteDeFacturation.abreviation}}
      </div>

      <div *dxTemplate="let cell of 'prixCellTemplate'">
        {{cell.row.data.prix}} /{{cell.row.data.uniteDeFacturation.abreviation}}
      </div>

      <!--  OPTIONS-->
      <dxo-search-panel
        [visible]="true">
      </dxo-search-panel>
      <dxo-editing mode="cell" [allowUpdating]="true" [confirmDelete]="false"></dxo-editing>
      <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>
      <dxo-pager
        [showPageSizeSelector]="false"
        [showNavigationButtons]="true"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} articles(s)">
      </dxo-pager>
    </dx-data-grid>
  </div>
</div>

<hr/>
<div class="d-flex justify-content-between">
  <div class="d-flex flex-column">
    <div>🗣️<em> N'oubliez pas d'enregistrer votre travail.</em></div>
    <div><label class="font-12 genlabel"><i class="fas fa-info-circle mg-r-5"></i> Liste des articles affectés à ce lot
      marché </label></div>
  </div>
  <div>
    <span class="mg-r-5">
      <button pButton icon="fas fa-save" (click)="saveArticles()"
              [disabled]="isDisabledSaveBtn()" pTooltip="Enregistrer" tooltipPosition="top"
              showDelay="500" class="p-button-success">
      </button>
    </span>
    <span class="mg-r-5">
      <button pButton icon="fas fa-times" pTooltip="Fermer" (click)="closeDialog()"
              class="p-button-secondary">
      </button>
    </span>
  </div>
</div>

<yo-search-articles></yo-search-articles>
