import {ObjectDTO} from "./object-dto";
import {AdresseDTO} from "./adresse-dto";
import {ContactDTO} from "./contact-dto";
import {ClientDTO} from "./client-dto";
import ModeleConditionnementPlcDTO from "./conditionnement/plc/modele-conditionnement-plc-dto";

export class PointDeLivraisonDTO extends ObjectDTO {

  code: string;
  libelle: string;
  actif: boolean;
  guid: string;
  adresse: AdresseDTO;
  contact1: ContactDTO;
  contact2: ContactDTO;

  client:ClientDTO;
  modeleConditionnementPlc: ModeleConditionnementPlcDTO;

  offreAlimentaire: string;
  prestation: string;

}
