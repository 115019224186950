import {Component, OnDestroy, OnInit} from "@angular/core";
import {UtilsService} from "../../../core/utils/utils.service";
import {FK_ORIGINES, OrigineService} from "../../../core/services/entities/origines-service";
import {OrigineDTO} from "../../../core/dtos/origine-dto";
import {FormGroup} from "@angular/forms";
import {GROUP_POSITION, GroupFormFields} from "../../../core/suppliers/group-formfields";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {FormFieldBaseSupplier} from "../../../core/suppliers/form-fieldbase-supplier";
import {GenericFormService} from "../../../core/services/generics/generic-form.service";
import {ResponseWrapper} from "../../../core/suppliers/wrappers/response-wrapper";
import {LotMarcheDTO} from "../../../core/dtos/gerstion-marche/lot-marche-dto";
import {MSG_KEY, MSG_SEVERITY} from "../../../core/constants";
import {Subscription} from "rxjs";
import {ToastService} from "../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-dialog-origine',
  templateUrl: './dialog-origine.component.html',
  styleUrls: ['./dialog-origine.component.scss']
})
export class DialogOrigineComponent implements OnInit, OnDestroy {

  subOpenDialogOrigine :Subscription;

  title :string;
  logo : string;
  isVisibleForm: boolean = false;

  displayDialog: boolean = false;
  origine: OrigineDTO;
  form: FormGroup;
  fieldsInfosGenerales: FormFieldBaseSupplier<any>[];
  formInfoGenerales: FormGroup = new FormGroup({});
  groups: GroupFormFields[] = [];

  constructor(public utils: UtilsService,
              private origineSvc: OrigineService,
              private gfs: GenericFormService,
              private auth2Svc: Auth2Service,
              private toastSvc: ToastService){}

  ngOnInit(): void {
    this.subOpenDialogOrigine =  this.origineSvc.openDialogOrigine$.subscribe(response => {
      this.origine = response;
      this.displayDialog= true;
      this.initTitle(this.origine);
      this.initForm(this.origine);
      this.isVisibleForm = true;
    });
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subOpenDialogOrigine);
  }

  closeDialog = () => {
    this.displayDialog = false;
  };

  initTitle = (origine: OrigineDTO) => {
    if(origine.id === 0){
      this.logo = 'fa fa-edit';
      this.title = 'CRÉER UNE ORIGINE'
    }else{
      if(this.auth2Svc.isSiteLocal(origine.site.id)){
        this.logo = 'fa fa-edit';
        this.title =  'MODIFIER L\'ORIGINE '+origine.libelle
      }else{
        this.logo = 'fas fa-eye';
        this.title =  'VOIR L\'ORIGINE '+origine.libelle
      }
    }
};

  saveOrigine = () => {


    this.origine.libelle = this.formInfoGenerales.controls[FK_ORIGINES.libelle].value;
    // @ts-ignore
    this.origine.site = this.utils.getLightSite( this.formInfoGenerales.controls[FK_ORIGINES.site].value);
    this.origine.actif = this.formInfoGenerales.controls[FK_ORIGINES.actif].value;

    this.origineSvc.saveOrigine(this.origine).subscribe((response: ResponseWrapper<LotMarcheDTO>) => {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `L'origine a été enregistrée avec succès`);
      this.origineSvc.announceRefreshOrigineListGrid(true);
      this.closeDialog();
    });
  };

  isDisabledSaveBtn = () => {
    if (this.origine && this.form) {
      if(this.origine.id === 0){
        return false;
      }else{
        return !(this.form.valid && this.auth2Svc.isSiteLocal(this.origine.site.id));
      }
    }
  };

  initForm = (origine: OrigineDTO) => {

    this.fieldsInfosGenerales = this.origineSvc.getFields(origine);

    //desactiver les placeholder
    this.fieldsInfosGenerales.map(field => field.displayPlaceholder = false);
    this.formInfoGenerales = this.gfs.toFormGroup(this.fieldsInfosGenerales);
    this.form = new FormGroup({
      formInfoGenerales: this.formInfoGenerales
    });

    this.groups = [
      {
        titlePanel: 'information générale',
        fields: this.fieldsInfosGenerales,
        form: this.formInfoGenerales,
        titleIcon: 'fas fa-info-circle',
        position: GROUP_POSITION.COL1,
        colsOrganization: {
          col1: origine.id ===0 ?[FK_ORIGINES.site, FK_ORIGINES.libelle, FK_ORIGINES.actif] :
            [FK_ORIGINES.site, FK_ORIGINES.libelle, FK_ORIGINES.code, FK_ORIGINES.actif]
        }
      }
    ];
  };
}
