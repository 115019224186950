
<p-toolbar styleClass="p-mb-4">
  <ng-template pTemplate="left">
    <yo-help class="mg-r-15" [file]="pathFileHelp" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
    <button type="button"
            pButton
            icon="fa fa-plus"
            label="CRÉER"
            (click)="openDialog(undefined)"
            [disabled]="!canCreate()"
            [pTooltip]="tooltipBoutonCreer()"
            [class.disable]="!canCreate()"
            *ngIf="service.displayCreationButton()">
    </button>
  </ng-template>
  <ng-template pTemplate="right">
    <button type="button"
            pButton
            (click)="exportXslx()"
            icon="fas fa-download"
            class="mg-r-5"
            pTooltip="Exporter les éléments format Excel">
    </button>

    <button type="button"
            pButton
            icon="fas fa-upload"
            class="mg-r-5"
            (click)="opImportXls.toggle($event)"
            pTooltip="Importer les éléments à partir d'une feuille Excel"
            [disabled]="!canCreate()"
            [class.disable]="!canCreate()">
    </button>

    <button
      pButton
      type="button"
      icon="fas fa-trash"
      (click)="deleteValues()"
      [disabled]="!canModify()"
      pTooltip="Supprimer les éléments sélectionnés">
    </button>

  </ng-template>
</p-toolbar>


<!--[totalRecords]="totalRecords"-->
<dx-data-grid
  [dataSource]="values"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(182)"
  width="100%"
  [allowColumnResizing]="true"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [(selectedRowKeys)]="selectedRows"
  #grid>
  <dxo-selection
    [selectAllMode]="allMode"
    [showCheckBoxesMode]="checkBoxesMode"
    mode="multiple"
  ></dxo-selection>
    <ng-container *ngFor="let col of cols">
      <ng-container [ngSwitch]="col?.field">
        <dxi-column *ngSwitchCase="'actions'" dataField="id" caption="Actions" [width]="100"
                    [allowFiltering]="false"
                    [allowGrouping]="false"
                    cellTemplate="tplActions">
        </dxi-column>
        <dxi-column *ngSwitchCase="'site'" dataField="site" caption="Site"
                    [filterOperations]="['contains']"
                    [allowFiltering]="true"
                    cellTemplate="templateSite">
        </dxi-column>
        <dxi-column *ngSwitchCase="'couleur'" dataField="couleur.libelle" caption="Couleur"
                    [filterOperations]="['contains']"
                    [allowFiltering]="true"
                    cellTemplate="tplCouleur">
        </dxi-column>
        <dxi-column *ngSwitchCase="'libelle'" dataField="libelle" caption="Libellé"
                    [filterOperations]="['contains']"
                    [allowFiltering]="true">
        </dxi-column>
        <dxi-column *ngSwitchCase="'code'" dataField="code" caption="Code"
                    [filterOperations]="['contains']"
                    [allowFiltering]="true">
        </dxi-column>
        <dxi-column *ngSwitchCase="'ordre'" dataField="ordre" caption="Ordre"
                    [filterOperations]="['contains']"
                    [allowFiltering]="true">
        </dxi-column>
        <dxi-column *ngSwitchCase="'actif'" dataField="actif" caption="Actif"
                    [filterOperations]="['contains']"
                    [allowFiltering]="true">
        </dxi-column>
        <dxi-column *ngSwitchCase="'file'" caption="Logo" dataField="file" [filterOperations]="['contains']" [allowFiltering]="true" cellTemplate="tplFile"></dxi-column>
        <dxi-column *ngSwitchCase="'externalRef'" dataField="externalRef.libelle" [filterOperations]="['contains']" [allowFiltering]="true" ></dxi-column>
        <dxi-column *ngSwitchDefault cellTemplate="tplDefault" [filterOperations]="['contains']" [allowFiltering]="false" [dataField]="col?.field" [caption]="col?.header"></dxi-column>
      </ng-container>
    </ng-container>

  <div *dxTemplate="let cell of 'tplActions'">
    <yo-cell-button (yoNavigation)="openDialog(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoViewMode]="!gds.canModify(cell.row.data)"
                    [yoMaxWidth]="true"
                    [yoWidthPercent]="45"
                    class="space"
                    yoTextAlign="center"
                    yoIconClass="fa fa-edit"
                    yoTooltip="Modifier"
    ></yo-cell-button>
  </div>

  <div *dxTemplate="let cell of 'templateSite'">
     <yo-site [siteLibelle]="cell.row.data.site.libelle"
             [siteId]="cell.row.data.site.id"></yo-site>
  </div>

  <div *dxTemplate="let cell of 'tplCouleur'">
    <yo-couleur [hexColor]="cell.row.data?.couleur?.hexa" class="mg-r-5"></yo-couleur>
    {{cell.row.data?.couleur?.libelle}}
  </div>

  <div *dxTemplate="let cell of 'tplFile'">
    <yo-img-entity [entityId]="cell.row.data.id" [entityName]="service.getEntityName()"
                   [height]="64"
                   [openable]="true"
                   format="thumb"
                   [displayNoImage]="true"
                   [title]="cell.row.data.libelle"
                   [ordre]="1"></yo-img-entity>
  </div>

  <div *dxTemplate="let cell of 'tplDefault'">
    <ng-container *ngIf="mapFields.get(cols[cell.column.index].field) && mapFields.get(cols[cell.column.index].field).controlType === 'selectbutton'">
      <i
        class="{{ getDisplayFieldSelectButton(cell.row.data[cols[cell.column.index].field], mapFields.get(cols[cell.column.index].field),'icon')}} mg-r-5"></i>  {{getDisplayFieldSelectButton(cell.row.data[cols[cell.column.index].field], mapFields.get(cols[cell.column.index].field), 'label')}}
    </ng-container>
    <ng-container *ngIf="isBoolean(cell.row.data[cols[cell.column.index].field])">
      <dx-check-box [value]="cell.row.data[cols[cell.column.index].field]"></dx-check-box>
    </ng-container>
    <ng-container *ngIf="mapFields.get(cols[cell.column.index].field).controlType !== 'selectbutton' && !isBoolean(cell.row.data[cols[cell.column.index].field])">
      {{ (!isString(cell.row.data[cols[cell.column.index].field]) && !isNumber(cell.row.data[cols[cell.column.index].field])) ? cell.row.data[cols[cell.column.index].field].libelle : cell.row.data[cols[cell.column.index].field]}}
    </ng-container>
  </div>

  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-sorting mode="multiple"></dxo-sorting>

  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} modèles de plats">
  </dxo-pager>

</dx-data-grid>

<!--DIALOG D'AJOUT MODIFICATION-->
<p-dialog [header]="dialogTitle"

          [dismissableMask]="false"
          (onHide)="closeDialog()"
          [contentStyle]="{'overflow':'visible','max-height':'600px'}"
          [(visible)]="displayDialog"
          [responsive]="true" showEffect="fade"
          [modal]="true"
          [style]="{'width':'1200px'}"
>

  <form (ngSubmit)="save()" [formGroup]="form">
    <ng-container *ngFor="let field of fields; trackBy : utils.trackByFn">
      <yo-dynamic-form-field-bs [fields]="fields" [field]="field"
                                [form]="form"></yo-dynamic-form-field-bs>
    </ng-container>

    <div class="d-flex justify-content-end">
      <button
        pButton type="submit"
        icon="fas fa-save"
        label="Enregistrer"
        [disabled]="!canModifyEditObject()"
        [class.disable]="!canModifyEditObject()"

      >
      </button>
      <button
        class="mg-l-5 p-button-secondary"
        pButton type="button"
        (click)="closeDialog()"
        icon="fas fa-times"
      >
      </button>
    </div>
  </form>

</p-dialog>

<p-overlayPanel #opImportXls>
  <input type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
         (change)="importXslx($event)">
</p-overlayPanel>

<p-dialog

  [dismissableMask]="false"
  (onHide)="closeDialog()"
  (onHide)="closeDialog()"
  [contentStyle]="{'overflow':'visible','max-height':'600px'}"
  [(visible)]="displayDialogEtatImportXls"
  [responsive]="true"
  showEffect="fade"
  [modal]="true"
  [style]="{'width':'1000px'}"
>
  <ng-template pTemplate="header">
    <div class="p-dialog-title">
    <span class="mg-r-5">
      {{service.getTitle()}}
    </span>Rapport d'import Excel
    </div>
  </ng-template>

  <ng-container *ngIf="!utils.isCollectionNullOrEmpty(rapportXlsLignes)">
    <p-scrollPanel [style]="{width: '100%', height: '500px'}">
      <div *ngFor="let ligne of rapportXlsLignes">
         <span class="mg-r-5">
           <yo-check [checked]="ligne.statut"></yo-check>
         </span>
        <span class="mg-r-5"> {{ligne.message}}</span>
      </div>
    </p-scrollPanel>

  </ng-container>

  <ng-template pTemplate="footer">
    <button
      pButton type="button"
      (click)="displayDialogEtatImportXls=false"
      icon="fas fa-times"
      class="p-button-secondary"
      label="Fermer"
    >
    </button>
  </ng-template>


</p-dialog>
