import {Component, OnDestroy, OnInit} from '@angular/core';
import {MSG_KEY, MSG_SEVERITY} from '../../core/constants';
import {Auth2Service} from '../../core/services/security/auth2.service';
import {PasswordService} from '../../core/services/mail/password.service';
import {UtilsService} from '../../core/utils/utils.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Subscription} from "rxjs";
import {ToastService} from "../../core/services/technique/toast.service";

@Component({
  selector: 'yo-mot-de-passe-oublie',
  templateUrl: './mot-de-passe-oublie.component.html',
  styleUrls: ['./mot-de-passe-oublie.component.scss']
})
export class MotDePasseOublieComponent implements OnInit, OnDestroy {

  theEmail: string;
  mailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  displayDialog = false;
  subOpenDialog: Subscription;

  form: FormGroup;

  constructor(private passwordService: PasswordService,
              private utils: UtilsService,
              private auth2Svc: Auth2Service,
              private formBuilder: FormBuilder,
              private toastSvc: ToastService) {

  }
l
  ngOnInit() {
    this.form = this.initForm(this.form);

    this.openDialogSubscription();
  }


  openDialogSubscription() {
    this.subOpenDialog = this.auth2Svc.openMotDePasseOublieDialog$.subscribe(response => {
      this.theEmail = '';
      this.displayDialog = true;
    });
  }

  initForm(form:FormGroup) {
    return  this.formBuilder.group({});
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  resetPasswordStep1() {
    this.passwordService.resetPasswordStep1(this.theEmail)
      .toPromise()
      .then(response => {

        if (response.inError) {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, response.resultMessage);
        } else {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, response.resultMessage);
          this.displayDialog = false;
        }

      }).catch(this.handleError);
  }

  isEnvoyerDisabled(): boolean {
    let disabled = this.utils.isNullOrEmpty(this.theEmail) || !this.emailOk();
    return disabled;
  }

  emailOk(): boolean {
    return this.mailPattern.test(this.theEmail);
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subOpenDialog);
  }

  closeDialog() {
    this.displayDialog = false;
  }

}
