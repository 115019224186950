import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MenuItem} from 'primeng/api';

import {switchMap} from 'rxjs/operators';
import {BonCfDTO} from '../../../core/dtos/boncfs-dto';
import {CommandesService} from '../../../core/services/gestion-commandes/commandes.service';
import {UtilsService} from '../../../core/utils/utils.service';
import {SearchSupplierWrapper} from '../../../core/suppliers/wrappers/search-supplier-wrapper';
import {SearchSupplier} from '../../../core/suppliers/search-supplier';
import {BCF_MAIL_STATUT, BCF_STATUT, DATEPICKER_FR, HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from '../../../core/constants';
import {ValueDTO} from '../../../core/dtos/value-dto';
import {Auth2Service} from '../../../core/services/security/auth2.service';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {cloneDeep as _cloneDeep, uniqBy as _uniqBy} from 'lodash';
import {BonCfDetailDTO} from '../../../core/dtos/boncf-detail-dto';
import {WorkflowsService} from '../../../core/services/entities/workflows.service';
import {saveAs as fs_saveAs} from 'file-saver';
import {DomSanitizer} from '@angular/platform-browser';
import {BoncfService} from '../../../core/services/entities/boncf.service';
import {MailHistoService} from '../../../core/services/entities/mail-histo.service';
import {of, Subscription} from 'rxjs';
import CustomStore from "devextreme/data/custom_store";
import {Sort} from "../../../core/suppliers/generics/generic-request-supplier";
import {DxDataGridComponent} from "devextreme-angular";
import {DevextremeService, FilterItem} from "../../../core/services/technique/devextreme.service";
import {confirm} from "devextreme/ui/dialog";
import {ToastService} from "../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-bc-confirmes',
  templateUrl: './bc-a-valider.component.html',
  styleUrls: ['./bc-a-valider.component.scss']
})
export class BcAValiderComponent implements OnInit, OnDestroy {

  commandesAValider: BonCfDTO[];
  totalRecords = 0;
  loadingTable = false;

  // dialog edit
  bonCf: BonCfDTO;
  lignesBonCf: BonCfDetailDTO[];
  displayEditDialog = false;
  dialogTitle = 'Détails';

  // items pour le filtre de sélection des listes de besoin
  filterItemsListeBesoin: ValueDTO[];
  // items pour le filtre de sélection des unités de production
  filterItemsUdp: ValueDTO[];
  // items pour le filtre de sélection des fournisseurs
  filterItemsFournisseur: ValueDTO[];

  filterDatesLivraisons: Date[];

  fr: any;

  subMailsBonCfEnvoyes: Subscription;

  BONCF_MAIL_STATUT = BCF_MAIL_STATUT;

  nextSteps: MenuItem[] = [
    {
      label: `Passer les commandes sélectionnées à l'étape suivante :`,
      items: [
        {
          label: 'À réceptionner',
          icon: 'fas fa-angle-double-right',
          command: (event) => this.updateToCommandeAReceptionner()
        },
        {
          label: 'Refusées',
          icon: 'fas fa-angle-double-left',
          command: (event) => this.updateToCommandeRefusees()
        },
      ]
    },
  ];

  @ViewChild("grid") grid: DxDataGridComponent;

  dataSource: CustomStore;
  allMode: string;
  checkBoxesMode: string;

  listesBesoinsIdsSelected: number[] = [];
  udpIdsSelected: number[] = [];
  fournisseursIdsSelected: number[] = [];

  isActionDisabled: boolean = true;

  pathFile: string = HELP_FOLDERS.COMMANDES_FOURNISSEURS + '/bon-a-valider';

  constructor(public auth2Svc: Auth2Service,
              public commandesSvc: CommandesService,
              private gds: GenericDatagridService,
              public wkfSvc: WorkflowsService,
              public utils: UtilsService,
              public domSanitizer: DomSanitizer,
              private bonCfSvc: BoncfService,
              public mailHistoSvc: MailHistoService,
              private dxSvc: DevextremeService,
              private toastSvc: ToastService) {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
  }

  ngOnInit() {
    this.loadingTable = true;
    this.fr = DATEPICKER_FR;
    this.mailsBonCfEnvoyesSubscription();
    this.initFilters();
    this.initCustomStore();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subMailsBonCfEnvoyes);
  }

  sendEmail = (): void => {
    this.grid.instance.getSelectedRowKeys()
      .then(async rowsSelected => {
        const selectedBonCfs: BonCfDTO[] = rowsSelected.map(id => this.commandesAValider.find(p => id === p.id));
        const result = confirm('Confirmez-vous l\'envoi de des commandes sélectionnées aux fournisseurs ?', 'Envoi d\'un mail');
        const isDeleted: boolean = await result;
        if(isDeleted) {
          this.commandesSvc.sendMailToFournisseurs(selectedBonCfs);
        }
      });
  };

  mailsBonCfEnvoyesSubscription: () => void = () => {
    this.subMailsBonCfEnvoyes = this.commandesSvc.mailsBonCfEnvoyes$
      .pipe(
        switchMap(bonsCfDTO => {
          return this.gds.search(this.commandesSvc.filterStatusMails(bonsCfDTO));
        }),
        switchMap(response => {
          this.commandesSvc.updateStatusBonsCommandesFromRefresh(response.resultList, this.commandesAValider, true);
          return of(response.resultList);
        }),
        switchMap((mailsEnvoyes) => this.commandesSvc.refreshStatusMailsPeriodically(mailsEnvoyes, this.commandesAValider))
      )
      .subscribe(response => {
        const mails = _uniqBy(response.resultList, mail => mail.extraInfos);
        this.commandesSvc.updateStatusBonsCommandesFromRefresh(mails, this.commandesAValider, false);
        this.grid.instance.refresh();
      });
  };

  onChangeFilterDateLivraison = (): void => {
    if (this.grid)
      this.grid.instance.refresh();
  };

  onChangeFilterFournisseurs = ($event): void => {
    if (this.grid) {
      this.fournisseursIdsSelected = $event.value;
      this.grid.instance.refresh();
    }
  };

  onChangeFilterUdps = ($event): void => {
    if (this.grid) {
      this.udpIdsSelected = $event.value;
      this.grid.instance.refresh();
    }
  };

  onChangeFilterListesBesoins = ($event): void => {
    if (this.grid) {
      this.listesBesoinsIdsSelected = $event.value;
      this.grid.instance.refresh();
    }
  };

  checkDisabledButtons = (): void => {
    this.grid.instance.getSelectedRowKeys()
      .then(rowsSelected => {
        this.isActionDisabled = rowsSelected.length === 0;
      });
  }

  initCustomStore = (): void => {
    this.dataSource = new CustomStore({
      key: 'id',
      load: (loadOptions: any) => {
        const pageSize: number = loadOptions.take || this.grid.instance.pageSize();
        const page: number = this.grid.instance.pageIndex();
        const sorts: Sort[] = this.dxSvc.dxToGrsSorts(loadOptions.sort);
        const ssWrapper = new SearchSupplierWrapper();
        const filters: FilterItem[] = this.dxSvc.dxToGrsFilters(loadOptions.filter);

        ssWrapper.filtersMap['statutCode'] = new SearchSupplier(BCF_STATUT.COMMANDE_A_VALIDER);
        if (!this.utils.isCollectionNullOrEmpty(this.filterDatesLivraisons)) {
          const startDate = this.filterDatesLivraisons[0].getTime();
          let stopDate = _cloneDeep(startDate);
          if (this.filterDatesLivraisons[1]) {
            stopDate = this.filterDatesLivraisons[1].getTime();
          }
          ssWrapper.filtersMap['startDateLivraison'] = new SearchSupplier(startDate);
          ssWrapper.filtersMap['stopDateLivraison'] = new SearchSupplier(stopDate);
        }

        if (this.listesBesoinsIdsSelected && this.listesBesoinsIdsSelected.length)
          ssWrapper.filtersMap['listesBesoins'] = new SearchSupplier(undefined, this.listesBesoinsIdsSelected);
        if (this.udpIdsSelected && this.udpIdsSelected.length)
          ssWrapper.filtersMap['udps'] = new SearchSupplier(undefined, this.udpIdsSelected);
        if (this.fournisseursIdsSelected && this.fournisseursIdsSelected.length)
          ssWrapper.filtersMap['ffs'] = new SearchSupplier(undefined, this.fournisseursIdsSelected);

        let urlPaginationParams = this.gds.getUrlPaginationParamsFromDataGridDx(pageSize, page, sorts);
        if (loadOptions && loadOptions.select && loadOptions.select[0] === 'id') {
          // Si je coche tout => Il faut omettre la pagination
          urlPaginationParams = this.gds.getUrlPaginationParamsFromDataGridDx(null, null, sorts);
        }

        return this.commandesSvc.searchCommandes(ssWrapper, urlPaginationParams).toPromise().then(response => {
          this.commandesAValider = response.resultList;

          const resultSelectedRows = this.dxSvc.getRowsSelectedForDeferredMode(filters, response.resultList);
          if (resultSelectedRows) return resultSelectedRows;

          return {
            data: response.resultList,
            totalCount: response.totalElements
          }
        });
      },
      update: (key, values) => { return null; }
    });
  }

  deleteBonCfs = async (): Promise<void> => {
    let rowsSelected: any[] = await this.grid.instance.getSelectedRowKeys();

    const selectedBonCfs: BonCfDTO[] = rowsSelected.map(id => this.commandesAValider.find(p => id === p.id));
    // on ne doit pas supprimer les bons de commandes des sites secondaires
    const bonCfsToDelete = selectedBonCfs.filter(boncf => this.auth2Svc.isSiteLocal(boncf.site.id));
    const bonCfsToNoNotDelete = selectedBonCfs.filter(boncf => !this.auth2Svc.isSiteLocal(boncf.site.id));

    if (!this.utils.isCollectionNullOrEmpty(bonCfsToNoNotDelete)) {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Vous ne pouvez pas supprimer la/les commande(s) ${bonCfsToNoNotDelete
        .map(item => item.numeroBcf)
        .join(', ')}. La/Les commande(s) appartiennent à un site référent.`);
    }

    if (!this.utils.isCollectionNullOrEmpty(bonCfsToDelete)) {
      await this.dxSvc.dxDeleteItemsConfirmation(rowsSelected, () => {
        this.commandesSvc.delete(bonCfsToDelete).subscribe(response => {
          if (!this.utils.isResponseSupplierError(response)) {
            this.grid.instance.refresh();
          }
        });
      });
    }

  };

  initFilters = (): void => {
    this.commandesSvc.getFilterItemsListeBesoin(BCF_STATUT.COMMANDE_A_VALIDER)
      .subscribe(response => this.filterItemsListeBesoin = response.resultList);
    this.commandesSvc.getFilterItemsUdp(BCF_STATUT.COMMANDE_A_VALIDER)
      .subscribe(response => this.filterItemsUdp = response.resultList);
    this.commandesSvc.getFilterItemsFfs(BCF_STATUT.COMMANDE_A_VALIDER)
      .subscribe(response => this.filterItemsFournisseur = response.resultList);
  };

  updateToCommandeAReceptionner: () => void = () => {
    this.grid.instance.getSelectedRowKeys()
      .then(rowsSelected => {
        const selectedBonCfs: BonCfDTO[] = rowsSelected.map(id => this.commandesAValider.find(p => id === p.id));
        this.commandesSvc.updateCommandesStatut(selectedBonCfs.map(b => b.id), BCF_STATUT.COMMANDE_A_RECEPTIONNER).subscribe(response => {
          if (!this.utils.isResponseSupplierError(response)) {
            this.grid.instance.refresh();
          }
        });
      });
  };

  updateToCommandeRefusees = (): void => {
    this.grid.instance.getSelectedRowKeys()
      .then(rowsSelected => {
        const selectedBonCfs: BonCfDTO[] = rowsSelected.map(id => this.commandesAValider.find(p => id === p.id));
        this.commandesSvc.updateCommandesStatut(selectedBonCfs.map(b => b.id), BCF_STATUT.COMMANDE_REFUSEE).subscribe(response => {
          if (!this.utils.isResponseSupplierError(response)) {
            this.grid.instance.refresh();
          }
        });
      });
  };

  openObject = (bonCf: BonCfDTO): void => {
    this.bonCf = undefined;
    this.commandesSvc.getLignesCommandeFournisseur(bonCf.id).subscribe(response => {
      this.lignesBonCf = [];
      if (!this.utils.isResponseSupplierError(response)) {
        this.displayEditDialog = true;
        this.dialogTitle = this.commandesSvc.getCfTitle(bonCf);
        this.lignesBonCf = response.resultList;
        this.bonCf = _cloneDeep(bonCf);
      }
    });
  };

  printPDFCommandes = (): void => {
    this.grid.instance.getSelectedRowKeys()
      .then(rowsSelected => {
        const selectedBonCfs: BonCfDTO[] = rowsSelected.map(id => this.commandesAValider.find(p => id === p.id));
        this.commandesSvc.printPDFCommandes(selectedBonCfs).subscribe(response => {
          const blob = new Blob([response], {type: 'application/pdf'});
          fs_saveAs(blob, 'commandes-confirmees.pdf');
        });
      });
  };

  help = (): void => undefined;

  openHistoriqueMails = (bonCf: BonCfDTO): void => {
    this.bonCfSvc.announceHistoriqueMailBonCf(bonCf);
  };

  onBonCommandSaved = (): void => {
    this.grid.instance.refresh();
  };

}
