<p-toolbar styleClass="p-mb-4">
  <ng-template pTemplate="left" *ngIf="canEdit()">
    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
    <button pButton
            class="mg-r-15"
            label="AJOUTER"
            pTooltip="Ajouter un ou plusieurs point(s) de livraison client"
            icon="fa fa-plus"
            (click)="openDialogAjoutPlcs()">
    </button>
  </ng-template>
  <ng-template pTemplate="right">
    <button
      pButton
      type="button"
      icon="fas fa-trash"
      (click)="deleteValues()"
      [disabled]="!canEdit() || !selectedRows.length"
      pTooltip="Supprimer les éléments sélectionnés">
    </button>
  </ng-template>
</p-toolbar>
<dx-data-grid
  [dataSource]="plcList"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(250)"
  width="100%"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [(selectedRowKeys)]="selectedRows"
  #grid>
  <dxo-selection
    [selectAllMode]="allMode"
    [showCheckBoxesMode]="checkBoxesMode"
    mode="multiple"
  ></dxo-selection>
  <dxi-column dataField="libelleSite" caption="Site"
              cellTemplate="siteCellTemplate"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="libelleClient"
              caption="Client"
              [filterOperations]="['contains']"
              [allowFiltering]="true"></dxi-column>
  <dxi-column dataField="libelle" caption="Point de livraison client"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="offreAlimentaire" caption="Offre alimentaire" [allowFiltering]="true"></dxi-column>
  <dxi-column dataField="prestation" caption="Prestation" [allowFiltering]="true"></dxi-column>

  <div *dxTemplate="let cell of 'siteCellTemplate'">
    <yo-site [siteId]="convertNumber(cell.row.data.idSite)" [siteLibelle]="cell.row.data.libelleSite"
             [yoDelayMsTooltip]="1500"></yo-site>
  </div>

  <dxo-filter-row [visible]="true"></dxo-filter-row>

</dx-data-grid>

<yo-dialog-ajout-mcplc-plc></yo-dialog-ajout-mcplc-plc>
