<p-toolbar styleClass="p-mb-4">
  <ng-template pTemplate="left" *ngIf="canEdit()">
    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
    <button pButton
            class="mg-r-15"
            label="AJOUTER"
            pTooltip="Ajouter un ou plusieurs plat(s)"
            icon="fa fa-plus"
            (click)="openDialogAjoutPlats()">
    </button>
  </ng-template>
  <ng-template pTemplate="right">
    <button
      pButton
      type="button"
      icon="fas fa-trash"
      (click)="deleteValues()"
      [disabled]="!canEdit() || !selectedRows.length"
      pTooltip="Supprimer les éléments sélectionnés">
    </button>
  </ng-template>
</p-toolbar>
<dx-data-grid
  [dataSource]="platsList"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(250)"
  width="100%"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [(selectedRowKeys)]="selectedRows"
  #grid>
  <dxo-selection
    [selectAllMode]="allMode"
    [showCheckBoxesMode]="checkBoxesMode"
    mode="multiple"
  ></dxo-selection>
  <dxi-column dataField="id" caption="Actions" [width]="100"
              [allowFiltering]="false"
              [allowGrouping]="false"
              cellTemplate="actionsCellTemplate"></dxi-column>
  <dxi-column dataField="produit.site.libelle" caption="Site (lié au produit)"
              cellTemplate="siteCellTemplate"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="udp__modeleConditionnementPlat.libelleUniteDeProduction"
              caption="Unité de production"
              [filterOperations]="['contains']"
              [allowFiltering]="true"></dxi-column>
  <dxi-column dataField="produit.libelle" caption="Plat"
              [filterOperations]="['contains']"
              cellTemplate="produitCellTemplate"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="produit.produitDeclinaisons"
              caption="Déclinaison(s)"
              cellTemplate="pdCellTemplate"
              [allowFiltering]="false">
  </dxi-column>

  <div *dxTemplate="let cell of 'actionsCellTemplate'">

    <yo-cell-button (yoNavigation)="visualize(cell.row.data)"
                    [yoTooltipShowDelay]="1500"
                    [yoMaxWidth]="true"
                    [yoWidthPercent]="30"
                    [yoTextAlign]="'center'"
                    [yoIconClass]="'fas fa-calculator'"
                    [pTooltip]="'Visualiser l\'implémentation du modèle'"
                    tooltipPosition="right"
                    showDelay="500">
    </yo-cell-button>

  </div>

  <div *dxTemplate="let cell of 'siteCellTemplate'">
    <yo-site [siteId]="cell.row.data.produit.site.id" [siteLibelle]="cell.row.data.produit.site.libelle"
             [yoDelayMsTooltip]="1500"></yo-site>
  </div>

  <div *dxTemplate="let cell of 'produitCellTemplate'">
    <a pTooltip="Aller sur la fiche technique" showDelay="500"
       (click)="linkToFicheTechnique(cell.row.data.produit.id, cell.data.udp__modeleConditionnementPlat)">
      <span class="cursor save-color">{{ cell.row.data.produit.libelle }}</span>
    </a>
  </div>

  <div class="flex-wrap" *dxTemplate="let cell of 'pdCellTemplate'">
    <label *ngFor="let pd of cell.row.data.produit.produitDeclinaisons" class="badge badge-secondary zoom cursor">
      {{ pd?.declinaison?.libelle }}
    </label>
  </div>

  <dxo-filter-row [visible]="true"></dxo-filter-row>

</dx-data-grid>

<yo-dialog-ajout-mcp-plats></yo-dialog-ajout-mcp-plats>
