import {Component, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {Subscription} from "rxjs";
import {UtilsService} from "../core/utils/utils.service";
import {MSG_KEY, MSG_SEVERITY, TYPE_RESULT_VIEW_GRAPHQL} from "../core/constants";
import {GraphQLService} from "../core/services/technique/graphql.service";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {Auth2Service} from "../core/services/security/auth2.service";
import {JsonService} from "../core/services/technique/json.service";
import {ToastService} from "../core/services/technique/toast.service";

@Component({
  templateUrl: './requeteur-graphql.component.html',
  styleUrls: ['./requeteur-graphql.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RequeteurGraphqlComponent implements OnInit, OnDestroy {

  hasRequeteurGraphQL: boolean;
  dataModelLoaded: boolean;
  dataModel: string = '';

  titleHeaderModels: string;
  titleHeaderParametersVizualisation: string;
  titleHeaderQuery: string;
  labelExec: String;
  titleHeaderViewResults: string;

  TYPE_RESULT_VIEW_GRAPHQL = TYPE_RESULT_VIEW_GRAPHQL;
  typeViewResult: TYPE_RESULT_VIEW_GRAPHQL;

  placeHolderQueryGraphQL: string;
  queryGraphQL: string;

  jsonForExport: SafeUrl;
  namefileJsonExport: string;

  subHasReadGraphQL: Subscription;

  btnModelList: ModelRef[];

  constructor(public utils: UtilsService,
              public sanitizer: DomSanitizer,
              private graphQlSvc: GraphQLService,
              private auth2Svc: Auth2Service,
              private jsonSvc: JsonService,
              private toastSvc: ToastService) {
    this.dataModelLoaded = false;

    this.titleHeaderModels = 'Description du modèle de données'.toUpperCase();
    this.titleHeaderParametersVizualisation = 'Paramètres de visualisation des résultats'.toUpperCase();
    this.titleHeaderQuery = 'Saisie de la requête'.toUpperCase();
    this.labelExec = 'Exécuter';
    this.titleHeaderViewResults = 'Visualisation des résultats'.toUpperCase();

    this.btnModelList = [];
    this.btnModelList.push({ label: "COMMUN", pTooltip: "Consulter le modèle de données commun", action: () => this.loadModelCommon() });
    this.btnModelList.push({ label: 'Référentiel'.toUpperCase(), pTooltip: "Consulter le modèle de données référentiel", action:  () => this.loadModelReferentiel() });
    this.btnModelList.push({ label: 'Marché'.toUpperCase(), pTooltip: "Consulter le modèle de données marché", action:  () => this.loadModelMarche() });
    this.btnModelList.push({ label: "FOURNISSEUR", pTooltip: "Consulter le modèle de données fournisseur", action:  () => this.loadModelFournisseur() });

    this.placeHolderQueryGraphQL = 'Veuillez écrire votre requête GraphQL dans ce champ. Exemple : { allAllergene { id, libelle } }'
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subHasReadGraphQL);
  }

  ngOnInit() {
    this.initHasReadGraphQL();
  }

  initHasReadGraphQL(): void {
    this.subHasReadGraphQL = this.auth2Svc.hasReadGraphQl$.subscribe(response => {
      this.hasRequeteurGraphQL = true;
      this.typeViewResult = TYPE_RESULT_VIEW_GRAPHQL.pretty_json;
    });
  }

  sendQuery(): void {
    if (!this.queryGraphQL) {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, `Veuillez écrire votre requête GraphQL`);
    } else {
      this.graphQlSvc.sendQuery(this.queryGraphQL)
        .subscribe(response => {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `La requête GraphQL a été traité avec succès`);

          if (this.typeViewResult === TYPE_RESULT_VIEW_GRAPHQL.pretty_json || this.typeViewResult === TYPE_RESULT_VIEW_GRAPHQL.plain_json) {
            this.jsonForExport = this.jsonSvc.generateDownloadJsonUri(response);
            this.namefileJsonExport = `export-json-${new Date().getTime()}.json`;
          }

          this.graphQlSvc.announceQueryReceived(response);
        });
    }
  }

  clearExport(): void {
    this.jsonForExport = null;
  }

  closeModel(): void {
    this.dataModelLoaded = false;
    this.dataModel = '';
  }

  loadModelCommon(): void {
    this.graphQlSvc.getModelCommon()
      .subscribe(html => {
        this.dataModelLoaded = true;
        this.dataModel = html;
      });
  }

  loadModelReferentiel(): void {
    this.graphQlSvc.getModelReferential()
      .subscribe(html => {
        this.dataModelLoaded = true;
        this.dataModel = html;
      });
  }

  loadModelMarche(): void {
    this.graphQlSvc.getModelMarche()
      .subscribe(html => {
        this.dataModelLoaded = true;
        this.dataModel = html;
      });
  }

  loadModelFournisseur(): void {
    this.graphQlSvc.getModelFournisseur()
      .subscribe(html => {
        this.dataModelLoaded = true;
        this.dataModel = html;
      });
  }

}

export interface ModelRef {
  action: any,
  pTooltip: string,
  label: string
}
