import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../../../core/utils/utils.service';
import {ProductionService} from '../../../../core/services/gestion-production/production.service';
import {Subscription} from 'rxjs';
import {StocksReservationsService} from '../../../../core/services/gestion-stock/stocks-reservations.service';
import {saveAs} from 'file-saver';
import {MLotArticleStock} from '../../../../core/models/gestion-production/stocks/m-lot-article-stock';
import {MValidationDenree} from '../../../../core/models/gestion-production/stocks/validation/m-validation-denree';
import {DATEPICKER_FR, HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from '../../../../core/constants';
import {ResponseWrapper} from '../../../../core/suppliers/wrappers/response-wrapper';
import {DialogMsgSupplier, Paragraphe} from '../../../../core/suppliers/dialog-msg-supplier';
import {ToastService} from "../../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-validation-stocks',
  templateUrl: './validation-stocks.component.html',
  styleUrls: ['./validation-stocks.component.scss']
})
export class ValidationStocksComponent implements OnInit, OnDestroy {

  subStocksResa: Subscription;

  denreesStock: MValidationDenree[];

  idZoneDeProduction: number;
  idPlanDeProduction: number;
  idZoneDeStockage: number;
  jourFabrication: Date;

  rowGroupMetadata: any;
  fr = DATEPICKER_FR;

  pathFile: string = HELP_FOLDERS.PLANS_PRODUCTION + '/plans-de-production-validation-stocks';

  constructor(public utils: UtilsService, public prodSvc: ProductionService,
              public stocksResaSvc: StocksReservationsService,
              private toastSvc: ToastService) {
  }

  getHelp = (): DialogMsgSupplier => {
    let dms = new DialogMsgSupplier();
    dms.title = `RÉAJUSTER LES SORTIES DE STOCK`;

    let p1: Paragraphe = new Paragraphe();
    p1.title =  `Affiche l'état du stock en cours pour un jour de fabrication d'un plan de production.`;
    p1.lines = [
      `Préfiltré par l'atelier et la zone de stock. `,
      `Il est possible de modifier le numéro de lot du fabriquant, la DLC ainsi que la quantité déjà sortie.`,
      `Une édition de sortie de stocks peut être imprimée. Elle sera groupée par zone de stock / denree / lot article.`,
      `Qté à sortir : quantité nécessaire au plan de production. Elle est exprimée en Unité Technique.`,
      `Qté déjà sortie : quantité en stock déjà réservée pour ce plan de production. Elle est exprimée en Unité Technique.`,
      `Qté en stock : quantité en stock en temps réel.`,
      `Qté à sortir : elle est exprimée en Unité Technique.`,
      `Les indicateurs de stocks sont rouges si le stock est considéré comme épuisé.`,
      `La quantité à sortir affiche un OK sur la denrée si la quantité déjà sortie est supérieure ou égale à la qté à sortir.`,
    ];

    let sp1 = new Paragraphe();
    sp1.title = 'Calcul de la quantité en stock';
    sp1.lines = [
      `A - (B - C) = qte en stock`,
      `A = quantite en stock de la denree.`,
      `B = quantite en stock déjà réservée pour d'autres plans de production de la denree.`,
      `C = quantite en stock déjà réservée pour le plan prod / jour fab en cours.`
    ];

    p1.sousParagraphes = [sp1];


    dms.content = {
      intro: ``,
      paragraphes: [p1]

    };


    return dms;
  };

  ngOnInit() {

    // on recupere les stocks resà à partir d'un jour de plan de prod, des zones de stockage et des ateliers sélectionnés
    this.subStocksResa = this.stocksResaSvc.stocksResas$.subscribe(response => {
      console.log('this.subStocksResa', response);

      if (!this.utils.isResponseSupplierError(response)) {

        this.denreesStock = this.refreshDenreeStockList(response);

        this.idPlanDeProduction = response.additionalProperties['idPlanDeProduction'];
        this.idZoneDeProduction = response.additionalProperties['idZoneDeProduction'];
        this.idZoneDeStockage = response.additionalProperties['idZoneDeStockage'];
        this.jourFabrication = response.additionalProperties['jourFabrication'];

      }


    });

  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subStocksResa);
  }


  /**
   * Imprimer au format pdf un bon de sortie de stocks
   * @param selectedDate --> date de fabrication sélectionnée
   * @param _planProd
   */
  printPDFSortieStocks = () => {

    this.prodSvc.printSortiesStockPDF(this.idPlanDeProduction, this.idZoneDeProduction, this.idZoneDeStockage, this.jourFabrication).subscribe(response => {

      let blob = new Blob([response], {type: 'application/pdf'});
      saveAs(blob, 'sorties-de-stocks.pdf');

    });

  };

  changeQuantiteDejaSortie = ($event: any, lotArticle: MLotArticleStock, denree: MValidationDenree) => {

    lotArticle.quantiteDejaSortie = $event;
    lotArticle.quantiteDejaSortieUT = this.utils.convertQuantiteUdm1ToQuantiteUdm2(lotArticle.quantiteDejaSortie, lotArticle.ratioUniteStockage, denree.ratioUniteTechnique);
    this.isDenreeValid(denree);

  };

  /**
   * Mettre à jour la liste des denree stock à partir du back
   * Fix de la date dlc pas renvoyée correctement par le back
   * @param response reponse du back
   */
  refreshDenreeStockList = (response: ResponseWrapper<MValidationDenree>) => {

    let denreeStockList = response.resultList;
    // fix de la date dlc pas renvoyée correctement par le back
    denreeStockList.forEach(denree => denree.sortieZoneDeStockList.forEach(zds => zds.lotArticleStockList.forEach(lotArticle => lotArticle.dlc = this.utils.convertNumberDateToDate(lotArticle.dlc, true))));
    return denreeStockList;

  };

  saveReajustementStock = () => {

    this.stocksResaSvc.updateValidationStocksReservations(this.idPlanDeProduction, this.idZoneDeProduction, this.idZoneDeStockage, this.jourFabrication, this.denreesStock).subscribe(response => {
      if (!this.utils.isResponseSupplierError(response)) {
        this.denreesStock = this.refreshDenreeStockList(response);
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Les sorties de stock ont été réajustées avec succès`);
      }
    });
  };

  /***
   * On verifie si la qté déjà sortie est >=  à la quantité à sortir
   * On verifie si la qte deja sortie est <= qté en stock
   * Alors retourne vrai
   * @param denreeStock
   */
  isDenreeValid = (denreeStock: MValidationDenree) => {


    const quantiteASortir = denreeStock.quantiteASortir;
    let cumulDejaSortie = 0;

    for (let sortieZds of denreeStock.sortieZoneDeStockList) {
      for (let lotArticle of sortieZds.lotArticleStockList) {
        const qteUT = this.utils.convertQuantiteUdm1ToQuantiteUdm2(lotArticle.quantiteDejaSortie, lotArticle.ratioUniteStockage, denreeStock.ratioUniteTechnique);
        lotArticle.epuise = qteUT > lotArticle.quantiteEnStockUT ? true : false;
        cumulDejaSortie += qteUT;
      }
    }

    denreeStock.denreeValid = cumulDejaSortie >= quantiteASortir ? true : false;
    denreeStock.epuisee = cumulDejaSortie > denreeStock.quantiteEnStockUT ? true : false;
    denreeStock.quantiteDejaSortieUT = cumulDejaSortie;
  };

  /**
   * Si qte deja sortie > qte en stock
   * @param denreeStock
   */
  isDenreeEpuisee = (denreeStock: MValidationDenree) => {

    let cumulDejaSortie = 0;
    for (let sortieZds of denreeStock.sortieZoneDeStockList) {
      for (let lotArticle of sortieZds.lotArticleStockList) {
        const qteUT = this.utils.convertQuantiteUdm1ToQuantiteUdm2(lotArticle.quantiteDejaSortie, lotArticle.ratioUniteStockage, denreeStock.ratioUniteTechnique);
        cumulDejaSortie += qteUT;
      }
    }
    return denreeStock.denreeValid;
  };
}
