
<p-toolbar styleClass="p-mb-4">
  <ng-template pTemplate="left">
    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="familleProduitSvc.getHelp()" [width]="800"></yo-help>

    <span class="mg-r-15">
        <button pButton
                label="Créer"
                icon="fa fa-plus"
                (click)="createFamilleProduit()">
        </button>
      </span>
    <button pButton
            label="Modifier"
            icon="fa fa-edit"
            (click)="updateFamilleProduit()"
            [disabled]="this.selectedFamilleProduit.id === 0">
    </button>
  </ng-template>
</p-toolbar>


    <dx-tree-list
      id="famille-produit-grid"
      [dataSource]="familleProduitList"
      [rootValue]="null"
      keyExpr="id"
      [height]="utils.scrollHeightContent(74)"
      parentIdExpr="parent.id"
      [showRowLines]="true"
      [showBorders]="true"
      [columnAutoWidth]="true"
      (onSelectionChanged)="onSelectionChanged($event)"
      [expandedRowKeys]="[1]">

      <dxo-selection mode="single"></dxo-selection>
      <dxo-filter-row [visible]="true"></dxo-filter-row>

      <dxi-column dataField="libelle" caption="Libellé"></dxi-column>
      <dxi-column dataField="site" caption="Site" cellTemplate="siteCellTemplate"></dxi-column>
      <dxi-column dataField="code" caption="Code"></dxi-column>
      <dxi-column dataField="fabrique" caption="Est fabriqué ?"></dxi-column>
      <dxi-column dataField="actif" caption="Actif"></dxi-column>

      <div *dxTemplate="let cell of 'siteCellTemplate'">
        <yo-site [siteLibelle]="cell.value.libelle"
                 [siteId]="cell.value.id">
        </yo-site>
      </div>

    </dx-tree-list>


<yo-dialog-form-famille-produit
  (onClose)="onCloseDialogForm()"
  [displayDialog]="displayDialog"
  [parentList]="familleProduitList"
  [mode]="mode">
</yo-dialog-form-famille-produit>
