import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {GestionmenusRoutingModule} from './gestionmenus-routing.module';
import {GestionmenusRootComponent} from './gestionmenus-root/gestionmenus-root.component';
import {MenusToolbarComponent} from './menus-toolbar/menus-toolbar.component';
import {SharedModule} from '../shared/shared.module';

import {MenusSettingsComponent} from './menus-settings/menus-settings.component';
import {MenusettingsResolverService} from './menus-settings/menusettings-resolver.service';
import {FamnutsMenucompoComponent} from './famnuts-menucompo/famnuts-menucompo.component';
import {AllergenesMenucompoComponent} from './allergenes-menucompo/allergenes-menucompo.component';
import {MenusTrashComponent} from './menus-trash/menus-trash.component';
import {CoutEffectifComponent} from './cout-effectif/cout-effectif.component';
import {AppellationsMenucompoComponent} from './appellations-menucompo/appellations-menucompo.component';
import {InfosMenucompoComponent} from './infos-menucompo/infos-menucompo.component';
import {DuplicationMenusComponent} from './menus-toolbar/dialog/duplication/duplication.component';
import {MenusPlanning2Component} from './menus-planning2/menus-planning2.component';
import {DecoupageRepas2Component} from './menus-planning2/decoupage-repas2/decoupage-repas2.component';
import {MenuComposition2Component} from './menus-planning2/menu-composition2/menu-composition2.component';
import {DragDropConfig, DragDropService} from 'ng2-dnd';
import {EffectifsRepasJourConvive2Component} from './menus-planning2/effectifs-repas-jour-convive2/effectifs-repas-jour-convive2.component';
import {EffectifMenucompo2Component} from './menus-planning2/effectif-menucompo2/effectif-menucompo2.component';
import {CoutPeriodeComponent} from './cout-periode/cout-periode.component';
import {GemrcnComponent} from './analyse/gemrcn/gemrcn.component';
import {CoutPortionComponent} from './cout-portion/cout-portion.component';
import {CoutPortionPondereComponent} from './cout-portion-pondere/cout-portion-pondere.component';
import {CoutDecoupagePondereComponent} from './cout-decoupage-pondere/cout-decoupage-pondere.component';
import {CoutDecoupagePortionComponent} from './cout-decoupage-portion/cout-decoupage-portion.component';
import {EffectifprevuDecoupageComponent} from './effectifprevu-decoupage/effectifprevu-decoupage.component';
import {EffectifRepasComponent} from './effectif-repas/effectif-repas.component';
import {TauxDePriseMenucompoComponent} from './taux-de-prise-menucompo/taux-de-prise-menucompo.component';
import {TauxDePriseDecoupagerepasComponent} from './taux-de-prise-decoupagerepas/taux-de-prise-decoupagerepas.component';
import {GemrcnResolverService} from './analyse/gemrcn/gemrcn-resolver.service';
import {DialogRecherchePlatsComponent} from './dialog-recherche-plats/dialog-recherche-plats.component';
import {FamnutsDecoupageComponent} from './famnuts-decoupage/famnuts-decoupage.component';
import {DialogPrintBilanAllergeneComponent} from './menus-toolbar/dialog/print-bilan-allergene/print-bilan-allergene.component';
import {CoutRepasComponent} from './cout-repas/cout-repas.component';
import {ValidationMenuComponent} from './menus-toolbar/dialog/validation-menu/validation-menu.component';
import {DialogPrintMenuComponent} from './menus-toolbar/dialog/print-menu/print-menu.component';
import {DialogPrintCoutMatiereComponent} from './menus-toolbar/dialog/cout-matiere/print-cout-matiere.component';
import {PrintCoutMatiereService} from '../core/services/gestionmenus/print-cout-matiere.service';
import {FamilleComponent} from './analyse/gemrcn/famille/famille.component';
import {CoutsComponent} from './analyse/couts/couts.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DropdownModule} from 'primeng/dropdown';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {MenuModule} from 'primeng/menu';
import {TooltipModule} from 'primeng/tooltip';
import {CheckboxModule} from 'primeng/checkbox';
import {SelectButtonModule} from 'primeng/selectbutton';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {AccordionModule} from 'primeng/accordion';
import {StepsModule} from 'primeng/steps';
import {DialogModule} from 'primeng/dialog';
import {MultiSelectModule} from 'primeng/multiselect';
import {FileUploadModule} from 'primeng/fileupload';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {ToolbarModule} from 'primeng/toolbar';
import {InputNumberModule} from "primeng/inputnumber";
import {ListboxModule} from "primeng/listbox";
import {
  DxBarGaugeModule,
  DxChartModule,
  DxDataGridModule,
  DxDropDownBoxModule,
  DxNumberBoxModule,
  DxPieChartModule,
  DxPopupModule,
  DxTagBoxModule,
  DxTextBoxModule,
  DxTreeViewModule
} from "devextreme-angular";
import {MenusEgalimComponent} from "./analyse/egalim/menus-egalim.component";


@NgModule({
  imports: [
    CommonModule,
    GestionmenusRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,

    DropdownModule,
    ButtonModule,
    CalendarModule,
    MenuModule,
    TooltipModule,
    CheckboxModule,
    SelectButtonModule,
    TableModule,
    TabViewModule,
    AccordionModule,
    StepsModule,
    DialogModule,
    MultiSelectModule,
    FileUploadModule,
    OverlayPanelModule,


    ToolbarModule,
    InputNumberModule,
    ListboxModule,
    DxDataGridModule,
    DxTagBoxModule,
    DxDropDownBoxModule,
    DxTreeViewModule,
    DxTextBoxModule,
    DxNumberBoxModule,
    DxPopupModule,
    DxPieChartModule,
    DxChartModule,
    DxBarGaugeModule

  ],
  declarations: [
    GestionmenusRootComponent,
    MenusToolbarComponent,
    MenusSettingsComponent,
    FamnutsMenucompoComponent,
    AllergenesMenucompoComponent,
    MenusTrashComponent,
    CoutEffectifComponent,
    AppellationsMenucompoComponent,
    InfosMenucompoComponent,
    GemrcnComponent,
    DuplicationMenusComponent,
    MenusPlanning2Component,
    DecoupageRepas2Component,
    MenuComposition2Component,
    EffectifsRepasJourConvive2Component,
    EffectifMenucompo2Component,
    CoutPeriodeComponent,
    CoutPortionComponent,
    CoutDecoupagePondereComponent,
    CoutPortionPondereComponent,
    CoutDecoupagePortionComponent,
    EffectifprevuDecoupageComponent,
    CoutRepasComponent,
    EffectifRepasComponent,
    TauxDePriseMenucompoComponent,
    TauxDePriseDecoupagerepasComponent,
    DialogRecherchePlatsComponent,
    FamnutsDecoupageComponent,
    DialogPrintBilanAllergeneComponent,
    DialogPrintCoutMatiereComponent,
    ValidationMenuComponent,
    DialogPrintMenuComponent,
    FamilleComponent,
    CoutsComponent,
    MenusEgalimComponent

  ],
  entryComponents: [

    DuplicationMenusComponent,
    InfosMenucompoComponent
  ],
  exports: [
    GestionmenusRootComponent,
    DialogRecherchePlatsComponent
  ],
  providers: [
    MenusettingsResolverService,
    DragDropService,
    DragDropConfig,
    GemrcnResolverService,
    PrintCoutMatiereService

  ]
})
export class GestionmenusModule {
}
