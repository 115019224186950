// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]4.3.0-8689(04961cf78c)-C23/06/2022-12:08:49-B23/06/2022-12:09:25' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]4.3.0-8689(04961cf78c)-C23/06/2022-12:08:49-B23/06/2022-12:09:25",
  branch: "master",
  latestTag: "4.3.0",
  revCount: "8689",
  shortHash: "04961cf78c",
  longHash: "04961cf78c5c3b17992697974e2ca77bf3662645",
  dateCommit: "23/06/2022-12:08:49",
  dateBuild: "23/06/2022-12:09:25",
  buildType: "Ansible",
  } ;
