import {Injectable} from '@angular/core';

import frMessages from 'devextreme/localization/messages/fr.json';
import config from 'devextreme/core/config';


import frCldrData from 'devextreme-cldr-data/fr.json';
import {loadMessages, locale} from 'devextreme/localization';
import {MSG_SEVERITY, PREDICAT_OPERATOR} from '../../constants';
import {UtilsService} from '../../utils/utils.service';
import {GenericRequestSupplier, Sort} from '../../suppliers/generics/generic-request-supplier';
import {confirm} from "devextreme/ui/dialog";
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  private subjectDisplayToast = new Subject();
  displayToast$ = this.subjectDisplayToast.asObservable();

  displayToast = (key: string, type: string, message: string, urlMessage?: string, time?: number): void => {
    let timeDefault = 8000;
    let timeByType = timeDefault;
    if (!time) {
      switch (type) {
        case MSG_SEVERITY.INFO:
          timeByType = 4000;
          break;
        case MSG_SEVERITY.SUCCESS:
          timeByType = 3000;
          break;
        case MSG_SEVERITY.WARNING:
          timeByType = 8000;
          break;
        case MSG_SEVERITY.ERROR:
          timeByType = 8000;
          break;
      }
    }
    this.subjectDisplayToast.next({key, type, message, urlMessage, time: !time ? timeByType : timeDefault });
  };

}
