import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import {ModeleNutritionnelDTO} from '../../../core/dtos/modele-nutritionnel-dto';
import {RepasDTO} from '../../../core/dtos/repas-dto';
import {DecoupageRepasDTO} from '../../../core/dtos/decoupagerepas-dto';
import {ActivatedRoute} from '@angular/router';
import {GenericFormService} from '../../../core/services/generics/generic-form.service';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {GestionGemrcnService} from '../../../core/services/gestiongemrcn/gestion-gemrcn.service';
import {ModelesNutritionnelsService} from '../../../core/services/entities/modeles-nutritionnels.service';
import {UtilsService} from '../../../core/utils/utils.service';
import {SelectItem} from 'primeng/api';
import {cloneDeep as _cloneDeep, find as _find, remove as _remove, sortBy as _sortBy, uniqBy as _uniqBy} from 'lodash';
import {ModeleNutritionnelPlanningDTO} from '../../../core/dtos/modele-nutritionnel-planning-dto';
import {DATEPICKER_FR, JOURS_SEMAINES, JourSemaine, MSG_KEY, MSG_SEVERITY} from '../../../core/constants';
import {PlanningRowModel} from '../../../core/models/planning-row-model';
import {ContratMenuConviveDTO} from '../../../core/dtos/contratmenuconvive-dto';
import {ModeleNutritionnel__FamilleGemrcnDTO} from '../../../core/dtos/modele-nutritionnel__famille-gemrcn-dto';
import {FamilleGemrcnDTO} from '../../../core/dtos/famille-gemrcn-dto';
import {ToastService} from "../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.scss']
})
export class PlanningComponent implements OnInit, OnDestroy {

  subModeleNutri: Subscription;
  subSave: Subscription;
  planAlimentaire: ModeleNutritionnelDTO;
  repasList: RepasDTO[];
  selectedRepasList: RepasDTO[];
  composantesRepas: DecoupageRepasDTO[];
  selectedComposantesRepas: DecoupageRepasDTO[];
  grillePlanningFromBack: ModeleNutritionnelPlanningDTO[];
  rowsPlanning: PlanningRowModel[];
  joursSemaine: JourSemaine[] = _cloneDeep(JOURS_SEMAINES);
  selectedJoursSemaine: JourSemaine[];
  cells: Map<string, ModeleNutritionnelPlanningDTO>;
  displaySave: boolean;
  prestations: ContratMenuConviveDTO[];
  selectedGridView: number = 1;
  gridViews: SelectItem[];

  // DEBUT DIALOG selection famille gemrcn
  famillesGemrcn: FamilleGemrcnDTO[];
  displayDialogSelectFamilles: boolean = false;
  selectedFamilleGemrcn: FamilleGemrcnDTO;
  selectedCell: ModeleNutritionnelPlanningDTO;
  selectedOrdre: number;
  selectedJourSemaine: string;
  // FIN DIALOG selection famille gemrcn

  cycle = 4;
  cycleItems: SelectItem[] = [];
  selectedSemaineIdx: number = 1;

  cellWidth = 150;
  colRepasWidth = 150;

  localeFr: any;
  rowGroupMetadata: any;

  mapTaillesComposante: Map<string, number> = new Map();

  constructor(public gds: GenericDatagridService,
              public utils: UtilsService,
              private route: ActivatedRoute,
              private gfs: GenericFormService,
              private ggSvc: GestionGemrcnService,
              private modeleNutritionnelSvc: ModelesNutritionnelsService,
              private toastSvc: ToastService) {
  }

  ngOnInit() {
    this.initSetting();
    this.initRouteData();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subModeleNutri);
    this.utils.unsubscribe(this.subSave);
  }

  initSetting() {
    this.localeFr = DATEPICKER_FR;
    this.gridViews = [
      {label: 'Plats', value: 1},
      {label: 'Familles', value: 2}
    ];
  }

  initRouteData(){
    const parentRoute$ = this.route.parent.data;
    const currentRoute$ = this.route.data;
    const all$ = combineLatest([parentRoute$, currentRoute$]);

    this.subModeleNutri = all$
      .subscribe(response => {

        this.planAlimentaire = response[0].planAlimentaireSupplier.planAlimentaire;
        this.cycle = this.planAlimentaire.nbItemsParCycle ? this.planAlimentaire.nbItemsParCycle : 1;
        this.cycleItems = this.updateCycleItems(this.cycle);
        this.selectedSemaineIdx = this.cycleItems ? 1 : undefined;
        this.repasList = response[1].planningSupplier.repasList;
        this.composantesRepas = response[1].planningSupplier.composantesRepas;
        this.grillePlanningFromBack = response[1].planningSupplier.grillePlanning;
        this.rowsPlanning = response[1].planningSupplier.rowsPlanning;
        this.famillesGemrcn = response[1].planningSupplier.famillesGemrcn;
        this.famillesGemrcn = _uniqBy(this.famillesGemrcn, 'id');

        this.selectedComposantesRepas = this.utils.preSelectMultiList(response[1].planningSupplier.selectedComposantesRepas, this.composantesRepas);
        this.selectedRepasList = this.utils.preSelectMultiList(response[1].planningSupplier.selectedRepasList, this.repasList);
        this.selectedJoursSemaine = this.utils.preSelectJourSemaine(response[1].planningSupplier.selectedJoursSemaine, this.joursSemaine);
        this.cells = this.initCells(this.grillePlanningFromBack, this.planAlimentaire, this.selectedJoursSemaine, this.rowsPlanning, this.cycle);

        this.updateRowGroupRepas();
      });
  }

  initCells(grillePlanning: ModeleNutritionnelPlanningDTO[], planAlimentaire: ModeleNutritionnelDTO, selectedJoursSemaine: JourSemaine[], rowsPlanning: PlanningRowModel[], cycle: number): Map<string, ModeleNutritionnelPlanningDTO> {
    const cells = new Map();

    if (!this.utils.isCollectionNullOrEmpty(selectedJoursSemaine) && !this.utils.isCollectionNullOrEmpty(rowsPlanning)) {

      for (let jour of selectedJoursSemaine) {
        for (let row of rowsPlanning) {
          let mnp: ModeleNutritionnelPlanningDTO = undefined;
          for (let mnpBack of grillePlanning) {

            if (mnpBack.idModeleNutritionnel === planAlimentaire.id
              && mnpBack.jourSemaine === jour.value
              && mnpBack.repas.id === row.repas.id
              && mnpBack.decoupageRepas.id === row.composanteRepas.id) {

              mnp = mnpBack;
              break;
            }
          }
          // si existe pas en bdd, alors on le créé à la volée
          if (this.utils.isNullOrEmpty(mnp)) {
            mnp = this.ggSvc.newModeleNutritionnelPlanning(this.planAlimentaire, jour.value, row.repas, row.composanteRepas, 1);
          }
          const key = this.cellKey(jour.value, row.repas, row.composanteRepas);
          cells.set(key, mnp);
        }
      }
    }
    // recalcule des tailles de cellule
    this.computeTailles(cells);
    return cells;
  }

  keyComposanteTaille(repasId: number, decoupageRepasId: number) {
    return repasId + '-' + decoupageRepasId;
  }

  getTailleComposante(rowData: PlanningRowModel) {
    const keyComposanteTaille = this.keyComposanteTaille(rowData.repas.id, rowData.composanteRepas.id);
    const composanteTaille = this.mapTaillesComposante.get(keyComposanteTaille);
    return composanteTaille ? composanteTaille : 40;
  }

  computeTailles(cells: Map<string, ModeleNutritionnelPlanningDTO>) {
    this.mapTaillesComposante = new Map();
    if (cells) {
      cells.forEach((value, key) => {
        const keyComposanteTaille = this.keyComposanteTaille(value.repas.id, value.decoupageRepas.id);
        const valComposanteTaille = this.mapTaillesComposante.get(keyComposanteTaille);

        // si existe
        if (valComposanteTaille) {
          const calcTailleComposante = value.nbPlatsParComposanteRepas * 25 + 25 + 10;
          // on met à jour si taille calculee plus grande que taille actuelle
          if (valComposanteTaille < calcTailleComposante) {
            this.mapTaillesComposante.set(keyComposanteTaille, calcTailleComposante);
          }
        }
        // si n'existe pas
        else {
          this.mapTaillesComposante.set(keyComposanteTaille, value.nbPlatsParComposanteRepas * 25 + 25 + 10);
        }
      });
    }
  }

  cellKey(jourSemaine: number, repas: RepasDTO, composante: DecoupageRepasDTO) {
    return this.planAlimentaire.id + '-' + jourSemaine + '-' + repas.id + '-' + composante.id;
  }

  isGridDisplayable() {
    if (!this.utils.isCollectionNullOrEmpty(this.rowsPlanning) && !this.utils.isCollectionNullOrEmpty(this.selectedJoursSemaine)) {
      return true;
    }
    return false;
  }

  save() {
    const cells = this.prepareCellsForSave(this.cells);
    this.planAlimentaire.nbItemsParCycle = this.cycle > 0 ? this.cycle : 1;

    const planAlimentaire$ = this.gfs.saveOne(this.planAlimentaire, this.modeleNutritionnelSvc.getEntityName());
    const planning$ = this.ggSvc.savePlanning(cells, this.planAlimentaire);

    const all$ = combineLatest([planning$, planAlimentaire$]);
    this.subSave = all$.subscribe(response => {
      this.displaySave = false;
      if (!this.utils.isResponseSupplierError(response[0]) && !this.utils.isResponseSupplierError(response[1]))
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Planning enregistré avec succès`);
    });
  }

  displayDialog() {
    if (this.utils.isNullOrEmpty(this.planAlimentaire) || this.planAlimentaire.id < 1) {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez enregistrer la fiche d'identité avant de paramétrer vos familles de plat`);
    } else if (!this.isGridDisplayable()) {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez sélectionner des repas, des composantes, des familles de plats`);
    } else {
      // recup des prestation qui sont rattachées à ce plan alimentaire
      this.ggSvc.getPrestations(this.planAlimentaire).subscribe(response => {
        this.displaySave = true;
        this.prestations = response.resultList;
      });
    }
  }

  /**
   * On transforme la map de cellules en tableau pour l'envoyer au back. Au passage on applique les regles gemrcn sur chaque cellule active
   * @param mapCells
   * @param mapReglesGemrcn
   */
  prepareCellsForSave(mapCells: Map<string, ModeleNutritionnelPlanningDTO>): ModeleNutritionnelPlanningDTO[] {
    const cells: ModeleNutritionnelPlanningDTO[] = [];
    mapCells.forEach((value, key) => {
      cells.push(value);
    });
    return cells;
  }

  getCell(rowPlanning: PlanningRowModel, jourSemaine: number) {
    const key = this.cellKey(jourSemaine, rowPlanning.repas, rowPlanning.composanteRepas);
    return this.cells.get(key);
  }

  /**
   * Le calcul se fait en multipliant le nombre de repas selectionnés par le nombre de semaines et le nombre de jours dans le cycle
   * On stocke tout ca dans le plan alimentaire
   */
  computeFrequenceRepas() {
    // recalcul de la frequence repas
    this.planAlimentaire.nbRepas = 20;
    if (!this.utils.isCollectionNullOrEmpty(this.selectedRepasList)
      && !this.utils.isCollectionNullOrEmpty(this.selectedJoursSemaine)
    ) {
      // frequenceRepas=  nombre de repas * nombre de semaines * nombre de jours
      this.planAlimentaire.nbRepas = this.selectedRepasList.length * this.selectedJoursSemaine.length * this.cycle;
    }
  }

  refreshGrid($event: any) {
    // recalcul de la frequence repas
    this.computeFrequenceRepas();

    this.selectedRepasList = _sortBy(this.selectedRepasList, 'ordre');
    this.selectedComposantesRepas = _sortBy(this.selectedComposantesRepas, 'ordre');
    this.selectedJoursSemaine = _sortBy(this.selectedJoursSemaine, 'value');

    // recalcul des lignes
    if (!this.utils.isCollectionNullOrEmpty(this.selectedRepasList) && !this.utils.isCollectionNullOrEmpty(this.selectedComposantesRepas)) {
      this.rowsPlanning = [];
      for (let repas of this.selectedRepasList) {
        for (let composante of this.selectedComposantesRepas) {

          let foundFromBack = false;
          let rowPlanning = undefined;
          for (let row of this.grillePlanningFromBack) {
            if (row.repas.id === repas.id && row.decoupageRepas.id === composante.id) {
              rowPlanning = this.ggSvc.newPlanningRowModel(row.repas, row.decoupageRepas, row.nbPlatsParComposanteRepas);
              foundFromBack = true;
              break;
            }
          }
          if (!foundFromBack) {
            rowPlanning = this.ggSvc.newPlanningRowModel(repas, composante, 1);
          }
          this.rowsPlanning.push(rowPlanning);
        }
      }
    }
    // on recalcule les cellules
    this.cells = this.initCells(this.grillePlanningFromBack, this.planAlimentaire, this.selectedJoursSemaine, this.rowsPlanning, this.cycle);
    this.updateRowGroupRepas();
  }

  tooltipPlat(nbPlatsParComposanteRepas: number) {
    if (nbPlatsParComposanteRepas === 0) {
      return 'Aucun plat ne sera proposé.';
    }
    return `Jusqu'à ${nbPlatsParComposanteRepas} plat(s) proposé.`;
  }

  /**
   * Grouper par repas
   */
  updateRowGroupRepas() {
    this.rowGroupMetadata = {};
    if (this.rowsPlanning) {
      for (let i = 0; i < this.rowsPlanning.length; i++) {
        let rowData = this.rowsPlanning[i];
        let repas = rowData.repas;
        if (i == 0) {
          this.rowGroupMetadata[repas.id] = {index: 0, size: 1};
        } else {
          let previousRowData = this.rowsPlanning[i - 1];
          let previousRowGroup = previousRowData.repas;
          if (repas.id === previousRowGroup.id)
            this.rowGroupMetadata[repas.id].size++;
          else
            this.rowGroupMetadata[repas.id] = {index: i, size: 1};
        }
      }
    }
  }

  changeCycleItems($event) {
    this.cycle = $event;
    this.cycleItems = this.updateCycleItems(this.cycle);
    this.selectedSemaineIdx = this.selectedSemaineIdx <= this.cycleItems.length ? this.selectedSemaineIdx : 1;
    this.refreshGrid($event);
  }

  updateCycleItems(cycle: number): SelectItem[] {
    const cycleItems = [];
    for (let i = 1; i <= cycle; i++) {
      cycleItems.push({label: `Semaine ${i}`, value: i})
    }
    return cycleItems;
  }

  /**
   * Recuperer la famille gemrcn qui correspond  à la semaine / jour / repas / composante repas / numero plat
   * @param cell
   * @param ordre
   * @param selectedSemaineIdx
   */
  getSelectedSemaineFamille(cell: ModeleNutritionnelPlanningDTO, ordre: number, selectedSemaineIdx: number): ModeleNutritionnel__FamilleGemrcnDTO {
    if (!this.utils.isNullOrEmpty(cell) && !this.utils.isCollectionNullOrEmpty(cell.modeleNutritionnel__familleGemrcnList)) {
      for (let mnfg of cell.modeleNutritionnel__familleGemrcnList) {
        if (mnfg.ordre === ordre && mnfg.numeroSemaine === selectedSemaineIdx) {
          return mnfg;
        }
      }
    }
    return undefined;
  }

  changeNbPlatsParComposanteRepas(cell: ModeleNutritionnelPlanningDTO, $event) {
    cell.nbPlatsParComposanteRepas = $event;
    this.computeTailles(this.cells);
  }

  openDialogSelectFamilleGemrcn(cell: ModeleNutritionnelPlanningDTO, numeroSemaine: number, ordre: number) {
    this.selectedFamilleGemrcn = undefined;
    this.selectedCell = cell;
    this.selectedOrdre = ordre;
    this.selectedJourSemaine = JOURS_SEMAINES.filter(item => item.value === cell.jourSemaine)[0].viewValue;

    if (this.utils.isCollectionNullOrEmpty(cell.modeleNutritionnel__familleGemrcnList)) {
      cell.modeleNutritionnel__familleGemrcnList = [];
    }

    for (let mnfg of cell.modeleNutritionnel__familleGemrcnList) {
      if (mnfg.numeroSemaine === numeroSemaine && mnfg.ordre === ordre) {
        this.selectedFamilleGemrcn = _find(this.famillesGemrcn, {'id': mnfg.idFamilleNutritionnelle});
        break;
      }
    }
    this.displayDialogSelectFamilles = true;
  }

  onChangeSelectedFamilleGemrcn($event: any) {
    if (this.utils.isCollectionNullOrEmpty(this.selectedCell.modeleNutritionnel__familleGemrcnList)) {
      this.selectedCell.modeleNutritionnel__familleGemrcnList = [];
    }
    const existsMnfg = _find(this.selectedCell.modeleNutritionnel__familleGemrcnList, {
      'numeroSemaine': this.selectedSemaineIdx,
      'ordre': this.selectedOrdre
    });
    // si existe on met à jour la famille Germcn pour la semaine/repas/composante/jour/plat
    if (existsMnfg) {
      existsMnfg.couleurFamilleNutritionnelle = this.selectedFamilleGemrcn.couleur.hexa;
      existsMnfg.idFamilleNutritionnelle = this.selectedFamilleGemrcn.id;
      existsMnfg.libelleFamilleNutritionnelle = this.selectedFamilleGemrcn.libelle;
    }
    // sinon on ajoute la famille gemrcn   pour la semaine/repas/composante/jour/plat
    else {
      const newMnfg = this.ggSvc.newModeleNutritionnelFamilleGemrcn(this.selectedCell, this.selectedSemaineIdx, this.selectedOrdre, this.selectedFamilleGemrcn);
      this.selectedCell.modeleNutritionnel__familleGemrcnList.push(newMnfg);
    }
    this.displayDialogSelectFamilles = false;
  }

  isDialogSelectFamilleDisplayable() {
    if (this.selectedJourSemaine && this.selectedCell && this.selectedOrdre && this.selectedSemaineIdx) {
      return true;
    }
    return false;
  }

  removeSelectedFamilleGemrcn($event: MouseEvent) {
    if (this.utils.isCollectionNullOrEmpty(this.selectedCell.modeleNutritionnel__familleGemrcnList)) {
      this.selectedCell.modeleNutritionnel__familleGemrcnList = [];
    }
    // suppression de la famille gemrcn sur la cellule de planning
    const existsMnfg = _remove(this.selectedCell.modeleNutritionnel__familleGemrcnList, {
      'numeroSemaine': this.selectedSemaineIdx,
      'ordre': this.selectedOrdre
    });
    this.displayDialogSelectFamilles = false;
  }
}

