import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from "../../../../core/utils/utils.service";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {Udp_ModeleConditionnementPlat__ProduitDTO} from "../../../../core/dtos/unite-de-production_modele-conditionnement-plat__produit.dto";
import {UdpMcpProduitsService} from "../../../../core/services/conditionnements/plat/udp-mcp-produits.service";
import {Subscription} from "rxjs/index";
import {ActivatedRoute} from "@angular/router";
import ModeleConditionnementPlatDTO from "../../../../core/dtos/conditionnement/modele-conditionnement-plat-dto";
import {RoutemapService} from "../../../../core/services/routemap.service";
import {ImplementationModelesConditionnementsPlatsService} from "../../../../core/services/conditionnements/plat/implementation-modeles-conditionnements-plats.service";
import {combineLatest} from "rxjs";
import {HELP_FOLDERS} from "../../../../core/constants";
import {confirm} from "devextreme/ui/dialog";
import {UniteDeProduction__ModeleConditionnementPlatDto} from "../../../../core/dtos/unite-de-production-modele-conditionnement-plat-dto";

@Component({
  selector: 'yo-mp-plats',
  templateUrl: './mp-plats.component.html',
  styleUrls: ['./mp-plats.component.scss']
})
export class PlatsComponent implements OnInit, OnDestroy {

  platsList: Udp_ModeleConditionnementPlat__ProduitDTO[] = [];
  modelePlatConditionnement: ModeleConditionnementPlatDTO;

  allMode: string;
  checkBoxesMode: string;
  selectedRows: number[] = [];

  subSupplier: Subscription;
  subBindPlatsSupplier: Subscription;

  hasIDistri = false;

  pathFile: string = HELP_FOLDERS.CONDITIONNEMENT + '/conditionnement-modeles-plats-plats';

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private udpMcpProduitsSvc: UdpMcpProduitsService,
              private route: ActivatedRoute,
              private routeMapSvc: RoutemapService,
              private implMcpSvc: ImplementationModelesConditionnementsPlatsService){
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subSupplier);
    this.utils.unsubscribe(this.subBindPlatsSupplier);
  }

  ngOnInit(): void {
    this.initHasGestionIDistri();
    this.initDataFromSupplierSubscription();
    this.initListenerBindPlatsDoneSubscription();
  }

  initListenerBindPlatsDoneSubscription = () => {
    this.subBindPlatsSupplier = this.implMcpSvc.bindPlatsDone$
      .subscribe(response => {
        this.initUdpMcpProduitsList(this.modelePlatConditionnement.id);
      })
  };

  initDataFromSupplierSubscription = () => {
    this.subSupplier = this.route.parent.data
      .subscribe((data: any) => {
        if (data.modelePlatSupplier) {
          this.modelePlatConditionnement = data.modelePlatSupplier.modelePlatConditionnement;
          this.initUdpMcpProduitsList(data.modelePlatSupplier.idModeleConditionnementPlat);
        }
      });
  };

  initUdpMcpProduitsList = (mcpId): void => {
    this.udpMcpProduitsSvc.findAllByMcpId(mcpId)
      .subscribe(response => {
        this.platsList = response.resultList
      });
  };

  initHasGestionIDistri = (): void => {
    this.auth2Svc.hasGestionIDistri$.subscribe(response => this.hasIDistri = response);
  };

  visualize(instance: Udp_ModeleConditionnementPlat__ProduitDTO): void {
    this.routeMapSvc.goToSecondaryRoute(['gestionproduits', 'produit', instance.produit.typeProduit.fabrique, instance.produit.id, 'conditionnement-visualization', instance.udp__modeleConditionnementPlat.id]);
  }

  canEdit = (): boolean => this.hasIDistri;

  help = (): void => {

  };

  openDialogAjoutPlats = (): void => {
    this.udpMcpProduitsSvc.openDialogBindWithPlats(this.modelePlatConditionnement);
  };

  deleteValues = async (): Promise<void> => {
    const result = confirm('Êtes-vous sûr de vouloir supprimer le(s) association(s) sélectionnée(s) ?', 'Suppression d\'association');
    const isDeleted: boolean = await result;
    if(isDeleted) {
      const platsSelectedList = this.selectedRows.map(id => this.platsList.find(plat => plat.id === id));
      platsSelectedList.forEach(plat => console.log(plat.udp__modeleConditionnementPlat.id, plat.produit.id));
      const subDeletionsList = [];
      platsSelectedList.forEach(plat => {
        subDeletionsList.push(this.implMcpSvc.delete([plat.udp__modeleConditionnementPlat.id], plat.produit.id));
      });
      combineLatest(subDeletionsList)
        .toPromise()
        .then(deletions => {
          this.initUdpMcpProduitsList(this.modelePlatConditionnement.id);
        });
    }
  };

  linkToFicheTechnique = (idProduit: number, dataUdp: UniteDeProduction__ModeleConditionnementPlatDto): void => {
    if (idProduit && dataUdp)
      this.routeMapSvc.goToSecondaryRoute(['gestionproduits', 'produit', true, idProduit, 'fichetechnique-preselection-by-udp', dataUdp.idUniteDeProduction]);
  }

}
