import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  GestionLotMarcheService,
  TAB_NAME_LM
} from '../../../../../core/services/gestion-marche/gestion-lot-marche.service';
import {UtilsService} from '../../../../../core/utils/utils.service';
import {ActivatedRoute} from '@angular/router';
import {LotMarcheDTO} from '../../../../../core/dtos/gerstion-marche/lot-marche-dto';
import {ArticlesLotMarcheSupplier} from './articles-lot-marche-resolver.service';
import {Subscription} from 'rxjs';
import {Auth2Service} from '../../../../../core/services/security/auth2.service';
import {CatalogueAchatDTO} from '../../../../../core/dtos/catalogue-achat-dto';
import {find as _find} from 'lodash'
import {MSG_KEY, MSG_SEVERITY, UI_COLORS} from '../../../../../core/constants';
import {UnitedemesureDTO} from "../../../../../core/dtos/unitedemesure-dto";
import {DxDataGridComponent} from "devextreme-angular";
import {MenuItem} from "primeng/api";
import {ResponseWrapper} from "../../../../../core/suppliers/wrappers/response-wrapper";
import {IsDeletableObject} from "../../../../../core/models/is-deletable-object";
import {DATAGRID_ROW_TYPES} from "../../../../../core/services/technique/devextreme.service";
import {confirm} from "devextreme/ui/dialog";
import {ToastService} from "../../../../../core/services/technique/toast.service";


@Component({
  selector: 'yo-dialog-articles-lot-marche',
  templateUrl: './dialog-articles-lot-marche.component.html',
  styleUrls: ['./dialog-articles-lot-marche.component.scss']
})
export class DialogArticlesLotMarcheComponent implements OnInit, OnDestroy {

  @ViewChild('gridSelectedCatalogueAchat') grid: DxDataGridComponent;

  lotMarche: LotMarcheDTO;

  catalogueAchatList: CatalogueAchatDTO[] = [];
  caOwnedByLotMarche: CatalogueAchatDTO[] = [];
  caToSaved: CatalogueAchatDTO[] = [];
  selectedRowKeysCatalogueAchat: any[] = [];

  subscriptionRoute: Subscription;
  subSelectedCatalogueAchatList: Subscription;

  modeSelectionDxDatagrid: string;

  haveDataAtInit: boolean;
  uniteDeMesureList: UnitedemesureDTO[];
  itemsArticleMenu: MenuItem[] = [];

  constructor(public utils: UtilsService,
              private gestionLotMarcheSvc: GestionLotMarcheService,
              private auth2Svc: Auth2Service,
              private route: ActivatedRoute,
              private toastSvc: ToastService) {
  }

  ngOnInit(): void {
    this.initData();
    this.initItemAjoutArticle()
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subscriptionRoute);
    this.utils.unsubscribe(this.subSelectedCatalogueAchatList);
  }

  initData = () => {
    this.subscriptionRoute = this.route.data.subscribe((data: { contextLotMarcheSupplier: ArticlesLotMarcheSupplier }) => {
      this.lotMarche = data.contextLotMarcheSupplier.lotMarche;
      this.catalogueAchatList = data.contextLotMarcheSupplier.catalogueAchatList;
      this.caOwnedByLotMarche = data.contextLotMarcheSupplier.caOwnedByLotMarche;
      this.uniteDeMesureList = data.contextLotMarcheSupplier.uniteDeMesureList;
      this.haveDataAtInit = this.caOwnedByLotMarche.length > 0;

      //binding datagrid
      this.selectedRowKeysCatalogueAchat = this.setSelectedRowKeysCatalogueAchat(this.caOwnedByLotMarche);
      this.gestionLotMarcheSvc.announceOpenDialogLotMarche(this.lotMarche, TAB_NAME_LM.articles);
    });

    this.subSelectedCatalogueAchatList = this.gestionLotMarcheSvc.selectedCatalogueAchatList$.subscribe((response: CatalogueAchatDTO[]) => {

      //suppression du lien avec un parent si cette connexion existe et id = 0 pour créer une nouvelle instance
      let caListToSaved = response;
      this.gestionLotMarcheSvc.saveArticleLotMarche(this.lotMarche.id, caListToSaved).subscribe((response: ResponseWrapper<CatalogueAchatDTO>) => {
        response.resultList.forEach(ca => {
          this.caOwnedByLotMarche.push(ca);
        });

        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Articles ajoutés au lot marché ${this.lotMarche.libelle} avec succès`);
        this.lotMarche.articlesComplete = true;
        this.gestionLotMarcheSvc.announceLotMarche(this.lotMarche, TAB_NAME_LM.articles);
      })
    });
  };

  initItemAjoutArticle = () => {
    this.itemsArticleMenu = [{
      label: 'Actions :',
      items: [
        {
          label: 'Ajouter un article existant',
          command: event => this.openSearchDialog()
        }
      ]
    }];
  };

  setSelectedRowKeysCatalogueAchat = (caOwnedByLotMarche: CatalogueAchatDTO[]) => {
    let result: number[] = [];
    caOwnedByLotMarche.forEach((ff: CatalogueAchatDTO) => {
      result.push(ff.id);
    });
    return result;
  };

  isDisabledSaveBtn = () => {
    if (this.auth2Svc.isSiteLocal(this.lotMarche.marche.site.id)) {
      if (this.caOwnedByLotMarche.length === 0) {
        return !this.haveDataAtInit;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  saveArticles = () => {
    this.gestionLotMarcheSvc.saveArticleLotMarche(this.lotMarche.id, this.caToSaved).subscribe(response => {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, 'Articles enregistrés avec succès');
      this.lotMarche.articlesComplete = true;
      this.gestionLotMarcheSvc.announceLotMarche(this.lotMarche, TAB_NAME_LM.articles);
    })
  };

  closeDialog = () => {
    this.gestionLotMarcheSvc.announceCloseDialogLotMarche(true);
  };

  isDisabled = () => {
    if (this.auth2Svc.isSiteLocal(this.lotMarche.marche.site.id)) {
      this.modeSelectionDxDatagrid = 'multiple';
      return false;
    } else {
      this.modeSelectionDxDatagrid = 'none';
      return true;
    }
  };

  removeArticle = async (event: any) => {
    let res = await confirm("Confirmez-vous la suppression de cet article ?", "Suppression d'un article");
    if (res) {
      this.gestionLotMarcheSvc.deleteCatalogueAchatLinkToLotMarche(event.id, this.lotMarche.id).subscribe((response: ResponseWrapper<IsDeletableObject>) => {
        this.caOwnedByLotMarche = this.caOwnedByLotMarche.filter(ca => ca.id !== event.id);
        this.grid.instance.refresh();
        this.utils.deleteMessage(response, MSG_KEY.SIDEBAR);
      });
    }
  };

  loadData = () => {
    this.gestionLotMarcheSvc.getSelectedCatalogueAchatList(this.lotMarche.id).subscribe((data: ResponseWrapper<CatalogueAchatDTO>) => {
      this.caOwnedByLotMarche = data.resultList;
    });
  };

  openSearchDialog = () => {
    console.log(this.catalogueAchatList);
    this.gestionLotMarcheSvc.announceCaListSearchArticles(this.catalogueAchatList);
  };

  openCreateDialog = () => {
    this.gestionLotMarcheSvc.announceCreateArticle(this.lotMarche)
  };

  onRowUpdating = event => {
    if (event.newData.hasOwnProperty('uniteDeFacturation')) {
      this.updateUniteDeFacturation(event.key, event.newData.uniteDeFacturation.id)
    } else if (event.newData.hasOwnProperty('uniteDeStockage')) {
      this.updateUniteDeStockage(event.key, event.newData.uniteDeStockage.id)
    } else if (event.newData.hasOwnProperty('uniteDeCommande')) {
      this.updateUniteDeCommande(event.key, event.newData.uniteDeCommande.id)
    }
  };

  updateUniteDeCommande = (idRow: number, idNewUniteDeCommande: number) => {
    this.caOwnedByLotMarche.forEach(ca => {
      if (ca.id === idRow) {
        this.uniteDeMesureList.forEach(udm => {
          if (udm.id === idNewUniteDeCommande) {
            ca.uniteDeCommande = udm;
          }
        });
      }
    });
  };

  updateUniteDeFacturation = (idRow: number, idNewUniteDeFactu: number) => {
    this.caOwnedByLotMarche.forEach(ca => {
      if (ca.id === idRow) {
        this.uniteDeMesureList.forEach(udm => {
          if (udm.id === idNewUniteDeFactu) {
            ca.uniteDeFacturation = udm;
          }
        });
      }
    });
  };

  updateUniteDeStockage = (idRow: number, idNewUniteDeStockage: number) => {
    this.caOwnedByLotMarche.forEach(ca => {
      if (ca.id === idRow) {
        this.uniteDeMesureList.forEach(udm => {
          if (udm.id === idNewUniteDeStockage) {
            ca.uniteDeStockage = udm;
          }
        });
      }
    });
  };

  onCellPrepared = (event: any) => {
    if (!this.isDisabled()) {
      // si cell est un header
      if (event.rowType === DATAGRID_ROW_TYPES.HEADER) {
        // si colonne editable
        if (event.column.allowEditing === true) {
          event.cellElement.style.backgroundColor = UI_COLORS.EDITABLE;
        }
      }
    }
  };
}
