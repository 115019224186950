import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {GenericHandler} from '../../../../../core/services/generics/generic-handler';
import {ObjectDTO} from '../../../../../core/dtos/object-dto';
import {UtilsService} from '../../../../../core/utils/utils.service';
import {GenericDatagridService} from '../../../../../core/services/generics/generic-datagrid.service';
import {FormFieldBaseSupplier} from '../../../../../core/suppliers/form-fieldbase-supplier';
import {
  GenericRequestSupplier,
  Predicat,
  Search
} from '../../../../../core/suppliers/generics/generic-request-supplier';
import {SelectItem} from 'primeng/api';
import {Auth2Service} from '../../../../../core/services/security/auth2.service';
import {cloneDeep as _cloneDeep, find as _find, uniqBy as _uniqBy} from 'lodash'
import {FormGroup} from '@angular/forms';
import {GenericFormService} from '../../../../../core/services/generics/generic-form.service';
import {SiteDTO} from '../../../../../core/dtos/site-dto';
import {ExportRequestDTO} from '../../../../../core/dtos/importexport/export-request-dto';
import {ExportPropertyDTO} from '../../../../../core/dtos/importexport/export-property-dto';
import {saveAs as fs_saveAs} from 'file-saver';
import {ImportRequestDTO} from '../../../../../core/dtos/importexport/import-request-dto';
import {ImportPropertyDTO} from '../../../../../core/dtos/importexport/import-property-dto';
import {Title} from '@angular/platform-browser';
import {
  MESSAGE_CREATION_OBJET_IMPOSSIBLE,
  MSG_KEY,
  MSG_SEVERITY,
  PREDICAT_OPERATOR,
  PREDICAT_TYPE
} from '../../../../../core/constants';
import {DxDataGridComponent} from "devextreme-angular";
import {confirm} from "devextreme/ui/dialog";
import {ToastService} from "../../../../../core/services/technique/toast.service";


@Component({
  selector: 'yo-generic-datagrid-pn',
  templateUrl: './generic-datagrid-pn.component.html',
  styleUrls: ['./generic-datagrid-pn.component.scss']
})
export class GenericDatagridPnComponent implements OnInit, OnDestroy {

  @ViewChild('grid') grid: DxDataGridComponent;
  dialogTitle: string = '';
  displayDialog: boolean = false;
  displayDialogEtatImportXls: boolean = false;

  values: any[];
  totalRecords: number;
  editObject: ObjectDTO;
  form: FormGroup;
  grs: GenericRequestSupplier;

  mapFields: Map<string, FormFieldBaseSupplier<any>> = new Map();
  fields: FormFieldBaseSupplier<any>[];
  cols: any[] = [];
  loadingTable: boolean = false;

  @Input() service: GenericHandler<ObjectDTO>;
  @Input() pathFileHelp: string;

  @ViewChild('opImportXls') opImportXls;

  rapportXlsLignes: any[] = [];

  allMode: string;
  checkBoxesMode: string;
  selectedRows: number[] = [];


  constructor(public utils: UtilsService,
              private gfs: GenericFormService,
              public gds: GenericDatagridService,
              private title: Title,
              private auth2Svc: Auth2Service,
              private toastSvc: ToastService) {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
  }

  ngOnInit() {
    this.fields = this.service.getFields(undefined);
    this.form = this.gfs.toFormGroup(this.fields);
    this.mapFields = this.initMapFields(this.fields);
    this.cols = this.initCols(this.fields);
    this.title.setTitle(this.service.getTitle());
    this.initGrid();
  }

  getRefreshImage = (): number => new Date().getTime();

  canModifyEditObject = (): boolean => this.service.canModify(this.editObject);

  canModify = (): boolean => {
    if (!this.selectedRows || !this.selectedRows.length) {
      return false;
    } else {
      const selectedValues: ObjectDTO[] = this.selectedRows.map(id => this.values.find(v => v.id === id))
        .filter(val => val !== undefined && val !== null);
      let sites: SiteDTO[] = selectedValues.map(val => val.site);
      sites = _uniqBy(sites, 'id');

      for (let site of sites) {
        const elt = _find(this.auth2Svc.utilisateur.siteListLocaux, {'id': site.id});
        if (this.utils.isNullOrEmpty(elt)) {
          return false;
        }
      }
    }
    return true;
  };

  /**
   * Initialiser la map des champs pour ensuite les récupérer dans la grille
   * @param fields
   */
  initMapFields = (fields: FormFieldBaseSupplier<any>[]): Map<string, FormFieldBaseSupplier<any>> => {

    const mapFields: Map<string, FormFieldBaseSupplier<any>> = new Map();

    if (!this.utils.isCollectionNullOrEmpty(fields)) {
      for (let field of fields) {
        mapFields.set(field.key, field);
      }
    }

    return mapFields;
  };

  initCols = (fields: FormFieldBaseSupplier<any>[]): any[] => {
    const cols: any[] = [];
    cols.push({field: 'actions', header: 'Actions'});
    if (!this.utils.isCollectionNullOrEmpty(fields)) {
      const displayableFields = fields.filter(field => field.type !== 'hidden');
      if (!this.utils.isCollectionNullOrEmpty(displayableFields)) {
        for (let field of displayableFields) {
          cols.push({field: field.key, header: field.label});
        }
      }
    }
    return cols;
  };

  ngOnDestroy(): void {}

  initGrid = (): void => {
    const aliasEntityName = this.service.getEntityName().toLowerCase();

    let sort = {dir: 'asc', path: aliasEntityName + '.libelle', luceneSortType: 'sortLibelle'};
    this.grs = new GenericRequestSupplier(this.service.getEntityName());

    const search = new Search();
    // SORTS
    search.sorts = [];
    search.sorts.push(sort);

    // PREDICATS
    search.predicats = [];
    const predicatSitesOfUser = new Predicat();

    const idsSites = this.auth2Svc.utilisateur.sites.map(site => site.id);
    predicatSitesOfUser.ids = idsSites;
    predicatSitesOfUser.operator = PREDICAT_OPERATOR.In;
    predicatSitesOfUser.type = PREDICAT_TYPE.Integer;
    predicatSitesOfUser.path = `${aliasEntityName}.site.id`;
    search.predicats.push(predicatSitesOfUser);

    this.grs.search = search;
    this.gds.search(this.grs).subscribe(response => {
      this.values = response.resultList;
    });

  }

  openDialog = (rowData: ObjectDTO): void => {
    this.displayDialog = true;
    if (rowData) {
      this.dialogTitle = `${this.service.getEditObjectLabel(rowData)} `;
      this.editObject = _cloneDeep(rowData);
    }
    else {
      this.dialogTitle = `${this.service.getCreateNewObjectLabel()}`;
      this.editObject = null;
    }

    this.fields = this.service.getFields(this.editObject);
    this.form = this.gfs.toFormGroup(this.fields);
  };

  closeDialog = (): void => { this.displayDialog = false };

  save = (): void => {
    this.gfs.validateAllFormFields(this.form);
    if (this.form.valid) {
      const entityName = this.service.getEntityName();
      this.gfs.trimAllStringFields(this.fields, this.form);
      this.gfs.saveAndLinkDocument(this.form, entityName).subscribe(response => {
        if (!this.utils.isResponseSupplierError(response)) {
          this.values = this.gds.refreshDatagridPrimengAfterSave(this.values, response, entityName);
          if (response.newObj) {
            this.totalRecords++;
          }
          //this.refreshAllElementsBecauseAnomalyOnUniteDeMesure();
          this.closeDialog();
        }
      });
    }
  };

  /**
   * Correction anomalie redmine Anomalie #1055 : pas de "rafraichissement" des unités de mesure dans plusieurs tables :
   * Après modification d'une unité de mesure il faut les recharger toutes.
   */
  refreshAllElementsBecauseAnomalyOnUniteDeMesure(){
    this.service.getAllFromEnvironnement();
  }

  deleteValues = async (): Promise<void> => {
    if (this.selectedRows && this.selectedRows.length) {
      const idsToDelete: number[] = this.selectedRows;
      const selectedValues = this.selectedRows.map(id => this.values.find(v => v.id === id));

      const result = confirm(`Êtes vous sûr de vouloir supprimer les éléments sélectionnés ?`, 'Confirmation');
      const isConfirmed: boolean = await result;
      if (isConfirmed) {
        this.gfs.deleteList(this.service.getEntityName(), idsToDelete).subscribe(response => {
          const idsNotDeleted = response.additionalProperties['idsNotDeleted'];
          const idsDeleted = response.additionalProperties['idsDeleted'];
          const eltsNotDeleted = this.gds.mapObjectsFromIds(idsNotDeleted, selectedValues);
          if (!this.utils.isCollectionNullOrEmpty(eltsNotDeleted)) {
            const labels = eltsNotDeleted.map(elt => elt.libelle).join(', ');
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Impossible de supprimer les éléments suivants: ${labels}`);
          }

          const eltsDeleted = this.gds.mapObjectsFromIds(idsDeleted, selectedValues);
          if (!this.utils.isCollectionNullOrEmpty(eltsDeleted)) {
            const labels = eltsDeleted.map(elt => elt.libelle).join(', ');
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Les éléments suivants ont été supprimés : ${labels}`);
            this.gds.deleteGridRows(this.values, eltsDeleted);
          }

          this.values = this.values.filter(v => !idsToDelete.includes(v.id));
          this.selectedRows = [];
          this.grid.instance.refresh();
          // Correction anomalie redmine Anomalie #1055 : pas de "rafraichissement" des unités de mesure dans plusieurs tables :
          // Après modification suppression ou de n'importequel objet de mesure il faut les recharger tous.
          this.service.getAllFromEnvironnement();
        });
      }
    }
  };

  getDisplayFieldSelectButton = (value: number, field: FormFieldBaseSupplier<any>, property: string): any => {
    const options: SelectItem[] = field.options;
    for (let option of options) {
      if (option.value === value) {
        if (property === 'label') {
          return option.label;
        } else if (property === 'icon') {
          return option.icon;
        }
      }
    }
    return '';
  };

  exportXslx = () => {
    const exportRequest: ExportRequestDTO = new ExportRequestDTO(this.service.getEntityName());
    exportRequest.page = this.grs.page;
    exportRequest.filename = 'export-' + this.service.getTotalRecordsLabel() + '.xlsx';
    exportRequest.sheetname = this.service.getTotalRecordsLabel();
    exportRequest.search = this.grs.search;
    exportRequest.size = this.grs.size;
    exportRequest.idsToExport = this.selectedRows;

    const exportProperties: ExportPropertyDTO[] = [];
    let fields = this.service.getFields(undefined);
    if (!this.utils.isCollectionNullOrEmpty(fields)) {
      fields = fields.filter(item => item.key != 'id');
      fields.forEach(field => {
        exportProperties.push(new ExportPropertyDTO(field.label ? field.label : field.key, field.key, false));
      });
    }

    exportRequest.exportProperties = exportProperties;
    this.gds.exportXlsx(exportRequest).subscribe(response => {
      // en premier sont toujours renvoyés l'id, le ref_site et le site
      let blob = new Blob([response], {type: 'application/vnd.ms-excel'});
      fs_saveAs(blob, exportRequest.filename);
      this.selectedRows = [];
    });

  };

  isString = (value: any): boolean => typeof value === 'string';

  isNumber = (value: any): boolean => typeof value === 'number';

  isBoolean = (value: any): boolean => typeof value === 'boolean';

  importXslx = ($event: any) => {
    if ($event.target.files && $event.target.files.length) {
      const file = $event.target.files[0];
      const importRequest = new ImportRequestDTO(this.service.getEntityName());

      importRequest.page = this.grs.page;
      importRequest.filename = 'import-' + this.service.getTotalRecordsLabel() + '.xlsx';
      importRequest.sheetname = this.service.getTotalRecordsLabel();
      importRequest.search = this.grs.search;
      importRequest.size = this.grs.size;

      const importProperties: ImportPropertyDTO[] = [];

      // champs opbligatoires id et site, doivent être positionnés en premier

      let fields = this.service.getFields(undefined);

      if (!this.utils.isCollectionNullOrEmpty(fields)) {

        importProperties.push(new ImportPropertyDTO(0, 'ref_site', 'string'));
        importProperties.push(new ImportPropertyDTO(1, 'site', 'string'));

        fields = fields.filter(item => item.key != 'id' && item.key != 'site');

        fields.forEach((field, index) => importProperties.push(new ImportPropertyDTO(index + 2, field.key, field.excelType)));
      }

      importRequest.importProperties = importProperties;

      this.gds.importXlsx(importRequest, file).subscribe(response => {

        this.rapportXlsLignes = [];
        if (!this.utils.isResponseSupplierError(response)) {

          const lignes = response.additionalProperties['lignes'];

          this.values = response.resultList;
          this.totalRecords = response.totalElements;

          this.displayDialogEtatImportXls = true;

          if (lignes) {

            for (const key of Object.keys(lignes)) {

              const ligne = lignes[key];
              this.rapportXlsLignes.push(ligne);

            }
          }
        }
      });

      this.opImportXls.hide();
    }
  };
  canCreate = (): boolean => this.auth2Svc.hasSitesLocaux();

  tooltipBoutonCreer = (): string => {
    if (!this.canCreate()) {
      return MESSAGE_CREATION_OBJET_IMPOSSIBLE;
    }
    return "";
  };

  tooltipBoutonImporter = (): string => {
    if (!this.canCreate()) {
      return "Import impossible : vous ne possèdez aucun site local";
    }
    return "";
  };

    help = () => undefined;
}
