import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {Subscription} from "rxjs";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UtilsService} from "../../../../core/utils/utils.service";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {RoutemapService} from "../../../../core/services/routemap.service";
import {GenericDatagridService} from "../../../../core/services/generics/generic-datagrid.service";
import {MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {DialogMsgSupplier, Paragraphe} from "../../../../core/suppliers/dialog-msg-supplier";
import {SiteDTO} from "../../../../core/dtos/site-dto";
import {FormFieldFileSupplier} from "../../../../core/suppliers/form-field-file-supplier";
import ZoneANettoyerPmsDto from "../../../../core/dtos/pms/zone-a-nettoyer-pms-dto";
import {ZoneANettoyerPmsService} from "../../../../core/services/pms/zone-a-nettoyer-pms.service";
import {EquipementANettoyerPmsService} from "../../../../core/services/pms/equipement-a-nettoyer-pms.service";
import {EquipementANettoyerPmsDto} from "../../../../core/dtos/pms/equipement-a-nettoyer-pms-dto";
import {DxTreeViewComponent} from "devextreme-angular";
import DataSource from "devextreme/data/data_source";
import {ToastService} from "../../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-pms-zone-a-nettoyer-dialog',
  templateUrl: './pms-zone-a-nettoyer-dialog.component.html',
  styleUrls: ['./pms-zone-a-nettoyer-dialog.component.scss']
})
export class PmsZoneANettoyerDialogComponent implements OnInit, OnDestroy {
  hasPms = false;

  displayDialog = false;

  subOpenDialog: Subscription;

  zone: ZoneANettoyerPmsDto;

  /**
   * Liste des équipements à nettoyer déjà existants
   */
  equipements: EquipementANettoyerPmsDto[];

  forUpdate: boolean = false;

  form: FormGroup = new FormGroup({});
  previewFile: File;
  docError = false;
  field: FormFieldFileSupplier;

  dialogTitle = 'Modification d\'une zone à nettoyer';

  @ViewChild(DxTreeViewComponent, { static: false }) treeView;
  treeDataSource: DataSource;
  treeBoxValue: number[];

  sitesList: SiteDTO[];
  siteSelected: SiteDTO;

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private routeMapSvc: RoutemapService,
              public gds: GenericDatagridService,
              private zonePmsSvc: ZoneANettoyerPmsService,
              private equipementsNettoyerPmsSvc: EquipementANettoyerPmsService,
              private toastSvc: ToastService) {
  }

  ngOnInit(): void {
    this.initHasPms();
    this.initForm();
    this.initField();
    this.findAllLocalSites();
    this.openDialogVarianteSubscription();
    if(document.getElementById('previewImage')) {
      document.getElementById('previewImage').setAttribute('src', null);
    }
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subOpenDialog);
  }

  findAllLocalSites = (): void => {
    this.sitesList = this.auth2Svc.utilisateur.siteListLocaux;
  }

  initField = (): void => {
    /**
     * initialisation de la variable a utilisé pour l'affichage de l'image avec yo-img-entity
     */
    this.field = new FormFieldFileSupplier({
      key: 'file',
      label: 'Logo',
      readonly: !this.canModify(),
      value: '',
      entityName: this.zonePmsSvc.getEntityName().toLowerCase(),
      required: false,
      refresh: new Date().getTime(),
      ordre: 1
    });
  };

  openDialogVarianteSubscription = (): void => {
    this.subOpenDialog = this.zonePmsSvc.openDialog$
      .subscribe((z: ZoneANettoyerPmsDto) => {
        this.displayDialog = true;
        this.previewFile = null;

        if (!z) {
          this.forUpdate = false;
          this.zone = new ZoneANettoyerPmsDto();
          this.zone.id = null;
          this.dialogTitle = 'Création d\'une zone à nettoyer';
        } else {
          this.forUpdate = true;
          this.zone = z;
          this.dialogTitle = 'Modification d\'une zone à nettoyer';
          this.siteSelected = z.site;
        }

        this.initEquipementsNettoyerPms();
        this.initForm();
      });
  };

  initHasPms = (): void => {
    this.auth2Svc.hasPms$.subscribe(response => this.hasPms = response);
  };

  /**
   * Fonction pour initialiser tous les éléments nécessaires pour enregistrer et/ou afficher les équipements
   * à nettoyer présents dans la zone à nettoyer
   */
  initEquipementsNettoyerPms = (): void => {
    this.equipementsNettoyerPmsSvc.getAll()
      .subscribe(response => {
        this.equipements = response.resultList;
        this.treeDataSource = new DataSource({
          store: {
            type: 'array',
            key: 'id',
            data: response.resultList
          }
        });
        if(this.zone.equipementPmsList) this.treeBoxValue = this.zone.equipementPmsList.map(e => e.id);
      });
  };

  closeDialog = (): void => {
    this.displayDialog = false;
    this.siteSelected = null;
    this.treeBoxValue = null;
  };

  canModify = (): boolean => this.zone && this.hasPms;

  /**
   * fonction qui sauvegarde la zone à nettoyer pms créé ou modifié
   */
  save = async (): Promise<void> => {
    if (this.form.valid) {

      this.zone.libelle = this.form.controls['libelle'].value;
      this.zone.imageData = this.form.controls['field'].value;
      this.zone.site = this.sitesList.find(site => site.id === this.form.controls['site'].value);
      this.zone.equipementPmsList = [];
      if(this.treeBoxValue)
        this.zone.equipementPmsList = this.equipements.filter(item => this.treeBoxValue.find(t => t === item.id));

      this.zonePmsSvc.save(this.zone, this.zone.imageData)
        .subscribe(response => {
          this.zonePmsSvc.announceSaved(response.one, this.zone.id !== undefined && this.zone.id !== null && this.zone.id !== 0);
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `La sauvegarde de la zone à nettoyer a été réalisée avec succès`);
          this.closeDialog();
          this.initForm();
        });
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez compléter le formulaire`);
    }
  };

  /**
   * Méthode qui permet d'initialiser le formulaire pour la création ou la modification d'une zone à nettoyer pms
   */
  initForm = (): void => {
    this.form = new FormGroup({
      libelle: new FormControl(this.forUpdate ? this.zone.libelle : '', [Validators.required, Validators.minLength(1), Validators.maxLength(45)]),
      field: new FormControl( null),
      list: new FormControl(this.forUpdate ? this.zone.equipementPmsList : []),
      site: new FormControl(this.forUpdate ? this.zone.site : null, Validators.required)
    });
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Zone à nettoyer (Plan de Mesure Sanitaire)`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
    ];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };

  /**
   * Fonction qui permet de prévisualiser l'image téléchargé avant sa sauvegarde
   */
  previewImage = (): void => {
    const reader = new FileReader();
    reader.onload = function (e: any) {
      document.getElementById('previewImage').setAttribute('src', e.target.result);
    }
    reader.readAsDataURL(this.previewFile);
  };

  /**
   * Fonction qui récupère l'image puis qui appelle la fonction previewImage
   * pour prévisualiser celle-ci avant de l'enregistrer
   * @param event
   */
  onFileChange = (event: any) => {
    if (event.target.files && event.target.files.length) {
      this.previewFile = event.target.files[0];
      if (!this.utils.isNullOrEmpty(this.previewFile)) {
        // previsualiser l'image avant de l'enregistrer
        this.previewImage();
        this.form.get("field").setValue(this.previewFile);
      }
    }
  };

  onDropDownBoxValueChanged = () => {
    this.updateSelection(this.treeView && this.treeView.instance);
  };

  onTreeViewReady = e => {
    this.updateSelection(e.component);
  };

  updateSelection = treeView => {
    if (!treeView) return;

    if (!this.treeBoxValue) {
      treeView.unselectAll();
    }

    if (this.treeBoxValue) {
      this.treeBoxValue.forEach(((value) => {
        treeView.selectItem(value);
      }));
    }
  };

  onTreeViewSelectionChanged = e => {
    this.treeBoxValue = e.component.getSelectedNodeKeys();
  };
}
