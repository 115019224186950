import {Component, OnDestroy, OnInit} from '@angular/core';
import ConditionnementDTO from '../../core/dtos/conditionnement/conditionnement-dto';
import {Subscription} from 'rxjs';
import {UtilsService} from '../../core/utils/utils.service';
import {Auth2Service} from '../../core/services/security/auth2.service';
import {RoutemapService} from '../../core/services/routemap.service';
import {GenericDatagridService} from '../../core/services/generics/generic-datagrid.service';
import {ConditionnementsService} from '../../core/services/conditionnements/conditionnements.service';
import {SiteDTO} from '../../core/dtos/site-dto';
import {MSG_KEY, MSG_SEVERITY} from '../../core/constants';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DialogMsgSupplier, Paragraphe} from '../../core/suppliers/dialog-msg-supplier';
import {ToastService} from "../../core/services/technique/toast.service";

@Component({
  selector: 'yo-conditionnement',
  templateUrl: './conditionnement.component.html',
  styleUrls: ['./conditionnement.component.scss']
})
export class ConditionnementComponent implements OnInit, OnDestroy {

  hasIDistri = false;

  displayDialog = false;

  subOpenDialogConditionnement: Subscription;

  conditionnement: ConditionnementDTO;

  idsite: number;

  form: FormGroup = new FormGroup({});

  dialogTitle = 'Modification d\'un conditionnement';

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private routeMapSvc: RoutemapService,
              public gds: GenericDatagridService,
              private cdtSvc: ConditionnementsService,
              private toastSvc: ToastService) {
  }

  ngOnInit(): void {
    this.initForm();
    this.initHasGestionIDistri();
    this.openDialogConditionnementSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subOpenDialogConditionnement);
  }

  findAllLocalSites = (): SiteDTO[] => {
    return this.auth2Svc.utilisateur.siteListLocaux;
  }

  openDialogConditionnementSubscription = (): void => {
    this.subOpenDialogConditionnement = this.cdtSvc.openDialogConditionnement$
      .subscribe((cdt: ConditionnementDTO) => {
        this.displayDialog = true;
        if (!cdt) {
          this.conditionnement = new ConditionnementDTO();
          this.conditionnement.id = 0;
          this.dialogTitle = 'Création d\'un conditionnement';
        } else {
          this.conditionnement = cdt;
        }
        this.initForm();
      });
  };

  initHasGestionIDistri = (): void => {
    this.auth2Svc.hasGestionIDistri$.subscribe(response => this.hasIDistri = response);
  };

  closeDialog = (): void => {
    this.idsite = null;
    this.displayDialog = false;
  };

  canModify = (): boolean => this.conditionnement && this.hasIDistri && this.gds.canModify(this.conditionnement);

  save = (): void => {
    if (this.form.valid) {
      this.conditionnement.site = { id: this.form.controls['site'].value } as SiteDTO;
      this.conditionnement.libelle = this.form.controls['libelle'].value;
      this.conditionnement.code = this.form.controls['code'].value;
      this.conditionnement.abreviation = this.form.controls['abreviation'].value;
      this.conditionnement.poidsEnKg = this.form.controls['poidsEnKg'].value;
      this.cdtSvc.save(this.conditionnement)
        .subscribe(response => {
          const isUpdate = this.conditionnement.id !== undefined && this.conditionnement.id !== null && this.conditionnement.id !== 0;
          this.cdtSvc.announceConditionnementSaved(response.one, isUpdate);
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Sauvegarde du conditionnement réalisé avec succès`);
          this.closeDialog();
        });
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez compléter le formulaire`);
    }
  };

  initForm = (): void => {
    this.idsite = null;
    if (this.conditionnement) {
      const siteFound = this.findAllLocalSites().find(s => s.id === this.conditionnement.site?.id);
      this.idsite = siteFound ? siteFound.id : null;
    }
    this.form = new FormGroup({
      // tslint:disable-next-line:max-line-length
      site: new FormControl(this.idsite, [Validators.required]),
      libelle: new FormControl(this.conditionnement ? this.conditionnement.libelle : '', [Validators.required, Validators.maxLength(100), Validators.minLength(3)]),
      code: new FormControl(this.conditionnement ? this.conditionnement.code : '', [Validators.required, Validators.maxLength(50), Validators.minLength(2)]),
      // tslint:disable-next-line:max-line-length
      abreviation: new FormControl(this.conditionnement ? this.conditionnement.abreviation : '', [Validators.required, Validators.maxLength(8), Validators.minLength(2)]),
      poidsEnKg: new FormControl(this.conditionnement ? this.conditionnement.poidsEnKg : 0.0, [])
    });
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Le conditionnement`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
    ];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };

}
