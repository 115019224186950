import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ProduitDeclinaisonDenreeService} from '../../../../core/services/gestionproduits/produit-declinaison-denree.service';
import {Subscription} from 'rxjs';
import {UtilsService} from '../../../../core/utils/utils.service';
import {ProduitDeclinaisonDTO} from '../../../../core/dtos/produit-declinaison-dto';
import {GenericDatagridService} from '../../../../core/services/generics/generic-datagrid.service';
import CustomStore from 'devextreme/data/custom_store';
import {Sort} from '../../../../core/suppliers/generics/generic-request-supplier';
import {DevextremeService} from '../../../../core/services/technique/devextreme.service';
import {GenericFormService} from '../../../../core/services/generics/generic-form.service';
import {ProduitDeclinaisonService} from '../../../../core/services/entities/produit-declinaison.service';
import {ProduitDTO} from '../../../../core/dtos/produit-dto';
import {MenuItem} from "primeng/api";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {DxDataGridComponent} from "devextreme-angular";
import {ToastService} from "../../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-dialoglistplat',
  templateUrl: './dialog-list-plat.component.html',
  styleUrls: ['./dialog-list-plat.component.scss']
})
export class DialogListPlatComponent implements OnInit, OnDestroy {

  @ViewChild('grid') grid: DxDataGridComponent;

  customStore: CustomStore;

  subOpenDialogListplatUsingDenree: Subscription;
  subOpenDialogListplatrefreshGrid: Subscription;
  produitUtilise: ProduitDeclinaisonDTO;

  platList: ProduitDTO[] = [];

  displayDialog = false;
  isDisabledActionButton: boolean = false;

  selectedPlatList: ProduitDTO[] = [];
  itemsActionMenu: MenuItem[] = [];

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private produitDecliDenreeSvc: ProduitDeclinaisonDenreeService,
              private gds: GenericDatagridService,
              private gfs: GenericFormService,
              private produiDecliSvc: ProduitDeclinaisonService,
              private dxSvc: DevextremeService,
              private toastSvc: ToastService) {
  }

  ngOnInit(): void {
    this.subOpenDialogListplatUsingDenree = this.produitDecliDenreeSvc.openDialogListplatUsinsDenree$.subscribe((response: ProduitDeclinaisonDTO) => {
      this.produitUtilise = response;
      this.platList = [];
      this.displayDialog = true;
      this.customStore = this.initCustomStore();
      this.initItemsActionMenu();
    });

    this.subOpenDialogListplatrefreshGrid = this.produitDecliDenreeSvc.refreshDialogListPlatGrid$.subscribe(response => {
      this.grid.instance.refresh();
    });
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subOpenDialogListplatrefreshGrid)
    this.utils.unsubscribe(this.subOpenDialogListplatUsingDenree);
  }

  initItemsActionMenu = () => {
    this.itemsActionMenu = [{
      label: `Choix des actions sur les plats sélectionnés :`,
      items: [
        {
          label: 'Remplacer la denrée ',
          command: event => this.openDialogReplaceDenree(this.selectedPlatList, this.produitUtilise),
          disabled: this.selectedPlatList.length === 0
        }
      ]
    }];
  };


  initCustomStore = () => {
    if (!this.utils.isNullOrEmpty(this.produitUtilise)) {
      const cs = new CustomStore({
        key: 'id',
        load: (loadOptions: any) => {


          // page
          const pageSize: number = loadOptions.take || this.grid.instance.pageSize();
          const page: number = this.grid.instance.pageIndex();

          // filters
          const filters = this.dxSvc.dxToGrsFilters(loadOptions.filter);

          // sorts
          const sorts: Sort[] = this.dxSvc.dxToGrsSorts(loadOptions.sort);

          return this.produiDecliSvc.findPlatUsingByDenree(this.produitDecliDenreeSvc.filterPlatListByIdDenree(this.produitUtilise.id, page, pageSize, filters, sorts)).toPromise().then(response => {
            this.platList = response.resultList;
            return {
              data: this.platList,
              totalCount: response.totalElements
            }
          });
        },
        update: (key, values) => {
          return null;
        }
      });

      return cs;
    }
  };

  openDialogReplaceDenree = (selectedPlatList: ProduitDTO[], produitUtilise: ProduitDeclinaisonDTO) => {
    this.produitDecliDenreeSvc.announceOpenDialogReplaceDenree(selectedPlatList, produitUtilise);
  };

  onSelectionChanged = (event: any) => {
    this.selectedPlatList = [];
    event.selectedRowsData.forEach(data => {
      if (!this.auth2Svc.isSiteLocal(data.site.id)) {
        event.component.deselectRows([data.id]);
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, `Vous n'avez pas le droit de modifier le plat ${data.libelle}`);
      } else {
        this.selectedPlatList.push(data);
        this.disabledActionButton();
      }
    });
    this.initItemsActionMenu();
  };

  closeDialog = () => {
    this.displayDialog = false;
    this.platList = [];
    this.produitUtilise = undefined;
  };

  disabledActionButton = () => {
    const maxElements = 200;
    if (this.selectedPlatList.length > maxElements) {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, `La sélection ne peut pas dépasser ${maxElements} éléments`);
      this.isDisabledActionButton = true;
    }else{
      this.isDisabledActionButton = false;
    }
  };
}
