import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {Auth2Service} from '../security/auth2.service';
import {GenericHandler} from '../generics/generic-handler';
import {UtilsService} from '../../utils/utils.service';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {GenericDatagridService} from '../generics/generic-datagrid.service';
import {DialogMsgSupplier} from '../../suppliers/dialog-msg-supplier';
import {UnitesDeMesuresService} from './unites-de-mesures.service';
import {ObjectDTO} from '../../dtos/object-dto';
import {BonReceptionLigneDTO} from '../../dtos/bon-reception-ligne-dto';

import {LitigesService} from './litiges.service';
import {MotifsNonConformiteService} from './motifs-non-conformite.service';
import {AppellationsService} from './appellations.service';
import {UniteDeProduction__zoneDeStockageService} from './unite-de-production__zone-de-stockage.service';
import {UniteDeProduction__ZoneDeStockageDTO} from '../../dtos/unite-de-production__zone-stockage-dto';
import {find as _find, sortBy as _sortBy, startCase as _startCase} from 'lodash'

import {FormGroup} from '@angular/forms';
import {GenericFormService} from '../generics/generic-form.service';
import {URL_POST_INIT_BON_RECEPTION_LIGNE} from './bon-reception.service';
import {catchError} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {MSG_KEY, MSG_SEVERITY} from '../../constants';
import {BonReceptionLigneAppellationDTO} from '../../dtos/bon-reception-ligne__appellation-dto';
import {AppellationDTO} from '../../dtos/appellations-dto';
import {MotifNonConformiteDTO} from '../../dtos/motif-non-conformite-dto';
import {BonReceptionLigneMotifNonConfoDTO} from '../../dtos/bon-reception-ligne__motif-non-confo-dto';
import {LitigeDTO} from '../../dtos/litige-dto';
import {BonReceptionLigneLitigeDTO} from '../../dtos/bon-reception-ligne__litige-dto';
import {confirm} from "devextreme/ui/dialog";
import {ToastService} from "../technique/toast.service";

@Injectable({
  providedIn: 'root'
})
export class BonReceptionLigneService extends GenericHandler<BonReceptionLigneDTO> {

  private subjectDeleteOne = new Subject<BonReceptionLigneDTO>();
  deleteOne$ = this.subjectDeleteOne.asObservable();

  static UTILS: UtilsService;

  constructor(utils: UtilsService, auth2Svc: Auth2Service, router: Router, http: HttpClient, title: Title, private gds: GenericDatagridService, private udmSvc: UnitesDeMesuresService,
              private motifsSvc: MotifsNonConformiteService,
              private appellationSvc: AppellationsService,
              private udpZdsSvc: UniteDeProduction__zoneDeStockageService,
              private litigeSvc: LitigesService,
              private mncSvc: MotifsNonConformiteService,
              private gfs: GenericFormService,
              private toastSvc: ToastService) {
    super(utils, auth2Svc, router, http, title);
    BonReceptionLigneService.UTILS = this.utils;
  }


  getTotalRecordsLabel(): string {
    return _startCase(this.getEntityName());
  }

  getHelp(): DialogMsgSupplier {
    return undefined;
  }

  getSort(): string[] {
    return ['libelle,asc'];
  }

  getOas(): boolean {
    return undefined;
  }

  getTitle(): string {
    return 'GESTION DES LIGNES DE BON DE RECEPTION';
  }

  getCreateNewObjectLabel(): string {
    return 'CRÉER UNE LIGNE DE BON DE RECEPTION';
  }

  getEntityName(): string {
    return 'bonreceptionligne';
  }

  /**
   * Les zones de stockages doivent exister dans l'unité de production sur laquelle la commande doit être livrée
   */
  initUdpZdsList(brl: BonReceptionLigneDTO): UniteDeProduction__ZoneDeStockageDTO[] {
    let udpZdsList = [];

    // init de la liste des udp zones de stockage
    if (!this.utils.isCollectionNullOrEmpty(this.udpZdsSvc.udpZdsListEnvironnement)) {
      udpZdsList = this.udpZdsSvc.udpZdsListEnvironnement.filter(item => item.idUniteDeProduction === brl.idUniteDeProduction);
      udpZdsList = _sortBy(udpZdsList, ['libelleZoneDeStockage']);

      const blank = this.udpZdsSvc.createEmptyDTO();
      blank.libelleZoneDeStockage = 'Non renseigné';
      blank.idZoneDeStockage = 0;

      udpZdsList.unshift(blank);

    }

    return udpZdsList;
  }

  getFields(dto: BonReceptionLigneDTO): FormFieldBaseSupplier<any>[] {
    return [];
  }


  static computeTotalFactureHT(form: FormGroup) {
    let prixUnitaireFacture = parseFloat(form.controls['prixUnitaireFacture'].value);
    prixUnitaireFacture = BonReceptionLigneService.UTILS.isNumberGt0(prixUnitaireFacture) ? prixUnitaireFacture : 0;

    let quantiteRecueEnUC = parseFloat(form.controls['quantiteRecue'].value);
    quantiteRecueEnUC = BonReceptionLigneService.UTILS.isNumberGt0(quantiteRecueEnUC) ? quantiteRecueEnUC : 0;

    let ratioUniteDeCommandeUniteBase = parseFloat(form.controls['ratioUniteDeCommandeUniteBase'].value);
    let ratioUniteDeFacturationUniteBase = parseFloat(form.controls['ratioUniteDeFacturationUniteBase'].value);

    const quantiteRecueEnUf = BonReceptionLigneService.UTILS.convertQuantiteUdm1ToQuantiteUdm2(quantiteRecueEnUC, ratioUniteDeCommandeUniteBase, ratioUniteDeFacturationUniteBase);

    const totalFacture = (quantiteRecueEnUf * prixUnitaireFacture).toFixed(4);

    form.controls['totalFacture'].setValue(totalFacture);
  }


  getAllFromEnvironnement(): void {
  }

  createEmptyDTO(): BonReceptionLigneDTO {
    return new BonReceptionLigneDTO();
  }

  getEditObjectLabel(data: ObjectDTO): string {
    return `MODIFIER LA LIGNE DE BON DE RECEPTION '${data.libelle.toUpperCase()}'`;
  }

  /**
   * ( Quantite recue UC convertie en Quantite recue UF ) * prixUnitaireFacture
   * @param bonReceptionLigne
   */
  computeTotalFacture(bonReceptionLigne: BonReceptionLigneDTO) {

    const quantiteRecueEnUf = this.utils.convertQuantiteUdm1ToQuantiteUdm2(bonReceptionLigne.quantiteRecue, bonReceptionLigne.ratioUniteDeCommandeUniteBase, bonReceptionLigne.ratioUniteDeFacturationUniteBase);
    return quantiteRecueEnUf * bonReceptionLigne.prixUnitaireFacture;
  }

  /**
   * Générer un bon reception ligne à vide
   * @param cloneBonReceptionLigne
   */
  initBonReceptionLigne(cloneBonReceptionLigne: BonReceptionLigneDTO) {

    return this.http.post(URL_POST_INIT_BON_RECEPTION_LIGNE, cloneBonReceptionLigne).pipe(
      catchError(error => this.utils.handleError(error)));

  }

  async deleteLotArticle(bonReceptionLigne: BonReceptionLigneDTO) {
    const result = confirm(`Êtes vous sûr de vouloir supprimer le lot ${bonReceptionLigne.numeroLotInterne} ?`, 'Suppression de lot article');
    const isDeleted: boolean = await result;
    if(isDeleted) {
      this.gfs.deleteOne(this.getEntityName(), bonReceptionLigne.id).subscribe(response => {


        if (this.utils.isResponseSupplierError(response)) {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, response.resultMessage);
        } else {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Lot ${bonReceptionLigne.numeroLotInterne} supprimé avec succès`);
          this.announceDeleteOne(bonReceptionLigne);
        }
      })
    }
  }

  announceDeleteOne(bonReceptionLigne: BonReceptionLigneDTO) {
    this.subjectDeleteOne.next(bonReceptionLigne);
  }

  mapToDxAppellationList(ligneBonReceptionList: BonReceptionLigneDTO[], appellationList: AppellationDTO[]) {
    ligneBonReceptionList.forEach((brl: BonReceptionLigneDTO) => {
      brl.dxAppellationList = [];
      if (!this.utils.isNullOrEmpty(brl.bonReceptionLigneAppellationList)) {
        brl.bonReceptionLigneAppellationList.forEach((brlAppellation: BonReceptionLigneAppellationDTO) => {

          const newAppelation: AppellationDTO = _find(appellationList, (app: AppellationDTO) => {
            return app.id === brlAppellation.idAppellation
          });

          brl.dxAppellationList.push(newAppelation);

        });
      }
    });
  }

  mapToBonReceptionLigneAppellationList(lignesBonReception: BonReceptionLigneDTO[]) {

    lignesBonReception.forEach((lbrItem: BonReceptionLigneDTO) => {
      let newBrlAppellationList: BonReceptionLigneAppellationDTO[] = [];
      if (!this.utils.isNullOrEmpty(lbrItem.dxAppellationList)) {
        lbrItem.dxAppellationList.forEach((dxApp: AppellationDTO) => {

          let newBrlAppelation: BonReceptionLigneAppellationDTO = new BonReceptionLigneAppellationDTO();
          newBrlAppelation.idAppellation = dxApp.id;
          newBrlAppelation.libelleAppellation = dxApp.libelle;
          newBrlAppelation.idBonReceptionLigne = lbrItem.id;
          newBrlAppellationList.push(newBrlAppelation);

        });
      }
      lbrItem.bonReceptionLigneAppellationList = newBrlAppellationList;
    });

    return lignesBonReception
  }

  mapToDxMotifNonConfoList(ligneBonReceptionList: BonReceptionLigneDTO[], motifNonConformiteList: MotifNonConformiteDTO[]) {
    ligneBonReceptionList.forEach((brl: BonReceptionLigneDTO) => {
      brl.dxMotifNonConfoList = [];
      if (!this.utils.isNullOrEmpty(brl.bonReceptionLigneMotifNonConfoList)) {
        brl.bonReceptionLigneMotifNonConfoList.forEach((brlMnc: BonReceptionLigneMotifNonConfoDTO) => {

          const newMcn: MotifNonConformiteDTO = _find(motifNonConformiteList, (mnc: MotifNonConformiteDTO) => {
            return mnc.id === brlMnc.idMotifNonConformite
          });

          brl.dxMotifNonConfoList.push(newMcn);

        });
      }
    });
  }

  mapToBonReceptionLigneMotifNonConfoList(lignesBonReception: BonReceptionLigneDTO[]) {

    lignesBonReception.forEach((lbrItem: BonReceptionLigneDTO) => {
      let newBrlMcnList: BonReceptionLigneMotifNonConfoDTO[] = [];
      if (!this.utils.isNullOrEmpty(lbrItem.dxMotifNonConfoList)) {
        lbrItem.dxMotifNonConfoList.forEach((dxMcn: AppellationDTO) => {

          let newBrlMcn: BonReceptionLigneMotifNonConfoDTO = new BonReceptionLigneMotifNonConfoDTO();
          newBrlMcn.idMotifNonConformite = dxMcn.id;
          newBrlMcn.libelleMotifNonConformite = dxMcn.libelle;
          newBrlMcn.idBonReceptionLigne = lbrItem.id;
          newBrlMcnList.push(newBrlMcn);

        });
      }
      lbrItem.bonReceptionLigneMotifNonConfoList = newBrlMcnList;
    });

    return lignesBonReception
  }

  mapToDxLitigeList(ligneBonReceptionList: BonReceptionLigneDTO[], litigeList: LitigeDTO[]) {
    ligneBonReceptionList.forEach((brl: BonReceptionLigneDTO) => {
      brl.dxLitigeList = [];
      if (!this.utils.isNullOrEmpty(brl.bonReceptionLigneLitigeList)) {
        brl.bonReceptionLigneLitigeList.forEach((brlMnc: BonReceptionLigneLitigeDTO) => {

          const newLitige: LitigeDTO = _find(litigeList, (litige: LitigeDTO) => {
            return litige.id === brlMnc.idLitige
          });

          brl.dxLitigeList.push(newLitige);

        });
      }
    });
  }

  mapToBonReceptionLigneLitigeList(lignesBonReception: BonReceptionLigneDTO[]) {

    lignesBonReception.forEach((lbrItem: BonReceptionLigneDTO) => {
      let newBrlMcnList: BonReceptionLigneLitigeDTO[] = [];
      if (!this.utils.isNullOrEmpty(lbrItem.dxLitigeList)) {
        lbrItem.dxLitigeList.forEach((dxLitige: LitigeDTO) => {

          let newBrlLitige: BonReceptionLigneLitigeDTO = new BonReceptionLigneLitigeDTO();
          newBrlLitige.idLitige = dxLitige.id;
          newBrlLitige.libelleLitige = dxLitige.libelle;
          newBrlLitige.idBonReceptionLigne = lbrItem.id;
          newBrlMcnList.push(newBrlLitige);

        });
      }
      lbrItem.bonReceptionLigneLitigeList = newBrlMcnList;
    });

    return lignesBonReception
  }

}
