import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilsService} from '../../core/utils/utils.service';
import {Auth2Service} from '../../core/services/security/auth2.service';
import {RoutemapService} from '../../core/services/routemap.service';
import {DialogMsgSupplier, Paragraphe} from '../../core/suppliers/dialog-msg-supplier';
import {GenericManageDialogService} from '../../core/services/generics/generic-manage-dialog.service';
import {Subscription} from 'rxjs';
import {GenericDatagridService} from "../../core/services/generics/generic-datagrid.service";
import {TacheDTO} from "../../core/dtos/tache-dto";
import {TacheSavedSupplier, TachesService} from "../../core/services/taches/taches.service";
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../core/constants";
import {confirm} from "devextreme/ui/dialog";
import {ToastService} from "../../core/services/technique/toast.service";

@Component({
  selector: 'yo-grille-taches',
  templateUrl: './grille-taches.component.html',
  styleUrls: ['./grille-taches.component.scss']
})
export class GrilleTacheComponent implements OnInit, OnDestroy {

  tachesList: TacheDTO[] = [];

  subTacheSaved: Subscription;

  allMode: string;
  checkBoxesMode: string;
  selectedRows: number[] = [];

  pathFile: string = HELP_FOLDERS.REFERENTIEL + '/taches';

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private routeMapSvc: RoutemapService,
              public gds: GenericDatagridService,
              private genericManageDialogService: GenericManageDialogService,
              private tachesSvc: TachesService,
              private toastSvc: ToastService) {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always'
  }

  ngOnInit(): void {
    this.initTaches();
    this.tacheSavedSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subTacheSaved);
  }

  initTaches = (): void => {
    this.tachesSvc.getAll()
      .subscribe(response => {
        this.tachesList = response.resultList;
      });
  };

  deleteValues = async (): Promise<void> => {
    const result = confirm('Êtes-vous sûr de vouloir supprimer le(s) élément(s) sélectionnés ?', 'Confirmation');
    const isDeleted: boolean = await result;
    if (isDeleted) {
      const idsToDelete: number[] = this.selectedRows
        .map((idSelected: number) => this.tachesList.find(t => t.id === idSelected))
        .map((t: TacheDTO) => t.id);
      this.tachesSvc.deleteByIds(idsToDelete)
        .subscribe(o => {
          if (o.one.errors) {
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, o.one.errors);
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, o.one.errors);
          } else {
            this.tachesList = this.tachesList.filter(t => !idsToDelete.includes(t.id));
          }
        });
    }
  };

  tacheSavedSubscription = (): void => {
    this.subTacheSaved = this.tachesSvc.tacheSaved$
      .subscribe((response: TacheSavedSupplier) => {
        if (!response.isUpdate) {
          this.tachesList.push(response.tache);
        } else {
          const idxTacheToUpdate = this.tachesList.findIndex(t => t.id === response.tache.id);
          this.tachesList[idxTacheToUpdate] = response.tache;
        }
      });
  };

  open = (t: TacheDTO): void => {
    this.tachesSvc.announceOpenDialogTache(t);
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Les tâches`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
    ];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };

}
