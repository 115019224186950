import {BehaviorSubject, Observable, of, Subject} from 'rxjs';

import {catchError, filter} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {RequestWrapper} from '../../suppliers/wrappers/request-wrapper';
import {HttpClient} from '@angular/common/http';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {UtilsService} from '../../utils/utils.service';
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {FormFieldBaseSupplier} from '../../suppliers/form-fieldbase-supplier';
import {ObjectDTO} from '../../dtos/object-dto';
import {find as _find} from 'lodash';
import {MSG_KEY, MSG_SEVERITY} from '../../constants';
import {confirm} from "devextreme/ui/dialog";
import {ToastService} from "../technique/toast.service";

const URL_SAVEONE = 'dolrest/generic/save';
const URL_SAVE_AND_LINK_DOCUMENT = 'dolrest/generic/saveAndLinkDocument';
const URL_SAVEORUPDATEMANY = 'dolrest/generic/saveOrUpdateMany';
const URL_DELETEONE = 'dolrest/generic/delete';
const URL_DELETE_LIST = 'dolrest/generic/deleteList';

@Injectable()
export class GenericFormService {

  static staticUtils: UtilsService;

  private subjectSavedInstanceObject = new BehaviorSubject<ObjectDTO>(undefined);
  savedInstanceObject$ = this.subjectSavedInstanceObject.asObservable().pipe(filter(item => !!item));

  private subjectDeletedId = new Subject<ResponseWrapper<number>>();
  deletedId$ = this.subjectDeletedId.asObservable().pipe(filter(item => !!item));

  constructor(private http: HttpClient,
              private utils: UtilsService,
              private toastSvc: ToastService) {

    GenericFormService.staticUtils = this.utils;

  }

  announceDeletedId = (response: ResponseWrapper<number>) => {
    this.subjectDeletedId.next(response);

  };

  announceSavedInstanceObject = (instanceObj: any) => {
    this.subjectSavedInstanceObject.next(instanceObj);
  };

  /**
   * Effectue un trim() sur chacun des champs du formulaire de type <b>string</b>.
   */
  trimAllStringFields = (fields: FormFieldBaseSupplier<any>[], form: FormGroup): void => {
    // Itération sur les fields
    fields.forEach(field => {

      // Récupération du control
      const control = form.controls[field.key];

      // Si la valeur est de type string : on la trime...
      if (typeof control.value === 'string') {
        control.setValue(control.value.trim());
      }
    });
  };


  /**
   * On ajoute les champs avec leurs validateurs aus form group
   * @param {FormFieldBaseSupplier<any>[]} fields
   * @returns {FormGroup}
   */
  toFormGroup = (fields: FormFieldBaseSupplier<any>[]) => {
    const group: any = {};

    fields.forEach(field => {

      const arrValidators: any[] = [];

      if (field.required) {
        arrValidators.push(Validators.required);
      }
      if (field.maxLength && field.maxLength > 0) {
        // arrValidators.push(Validators.maxLength(field.maxLength));
        arrValidators.push(maxTrimLength(field.maxLength));
      }
      if (field.minLength && field.minLength > 0) {
        // arrValidators.push(Validators.minLength(field.minLength));
        arrValidators.push(minTrimLength(field.minLength));
      }
      if (field.min) {
        arrValidators.push(Validators.min(field.min));
      }
      if (field.max) {
        arrValidators.push(Validators.max(field.max));
      }

      let fc: FormControl;

      let value = field.value;

      if (value === undefined || value === null) {
        value = '';
      }

      // si pas de validateur, on créé un champ sans validateur
      if (this.utils.isCollectionNullOrEmpty(arrValidators)) {
        fc = new FormControl(value);
      } else {
        fc = new FormControl(value, arrValidators);
      }

      // on ajoute le formControl au formGroup
      group[field.key] = fc;
    });


    return new FormGroup(group);
  };

  saveOne = (payLoad: any, entityName: string) => {
    if (payLoad) {
      const reqInstance: RequestWrapper = new RequestWrapper();
      reqInstance.one = payLoad;
      reqInstance.entityName = entityName;
      return this.http.post<ResponseWrapper<any>>(URL_SAVEONE, reqInstance)
    }
    return of(null);
  };

  /**
   * Sauvegarde ou mise à jour d'{@link ObjectDTO}s.
   *
   * @param {ObjectDTO[]} objects tableau des {@link ObjectDTO}s à sauvegarder.
   * @param {string} entityName
   * @returns {Observable<any>}
   */
  saveOrUpdateMany = (objects: ObjectDTO[], entityName: string) => {
    if (objects) {
      const reqInstance: RequestWrapper = new RequestWrapper();
      reqInstance.one = objects;
      reqInstance.entityName = entityName;

      return this.http.post<ResponseWrapper<any>>(URL_SAVEORUPDATEMANY, reqInstance).pipe(
        catchError(error => this.utils.handleError(error, true)))
    }
    return of(null);
  };


  deleteOne = (entityName: string, id: number) => {
    if (!this.utils.isNullOrEmpty(id) && !this.utils.isNullOrEmpty(entityName)) {
      return this.http.delete<ResponseWrapper<any>>(`${URL_DELETEONE}?entityName=${entityName}&id=${id}`).pipe(
        catchError(error => this.utils.handleError(error, true)));
    }
    return of(null);
  };

  deleteList = (entityName: string, ids: number[]) => {

    if (!this.utils.isNullOrEmpty(ids) && !this.utils.isNullOrEmpty(entityName)) {

      const idsStr = ids ? ids.join(',') : '';
      return this.http.delete<ResponseWrapper<any>>(`${URL_DELETE_LIST}?entityName=${entityName}&ids=${idsStr}`).pipe(
        catchError(error => this.utils.handleError(error, true)));
    }
    return of(null);
  };


  /**
   * Lors d'un submit, on declenche les erreurs des champs mals renseignés
   * @param {FormGroup} formGroup
   */
  validateAllFormFields = (formGroup: FormGroup) => {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {

        control.markAsDirty({onlySelf: true});

      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  };


  /**
   * Initialiser une matlist à partir d'une liste de DTO
   * @param {ObjectDTO[]} list
   * @param {string} keySelectedList
   * @param {string} keyList
   * @returns {any[]}
   * @param selectedList
   */
  initMatSelectionList = (selectedList: ObjectDTO[], list: ObjectDTO[], keySelectedList: string, keyList: string) => {

    const resultList = [];
    list.map(item => {
      selectedList.map(selectedItem => {
        if (selectedItem[keySelectedList] === item[keyList]) {
          resultList.push(item);
        }
      })
    });
    return resultList;
  };

  /**
   * Mapper un formulaire en DTO pour le back.
   * Fonction récursive.
   * @param {FormGroup} form
   * @returns {ObjectDTO}
   */
  mapFormToDTO = (form: FormGroup): ObjectDTO => {

    let obj = new ObjectDTO();

    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      if (control instanceof FormControl) {

        // console.log('field',control);

        if (typeof control.value === 'string') {
          control.setValue(control.value.trim());
        }
        obj[field] = control.value;

      } else if (control instanceof FormGroup) {
        obj = this.mapFormToDTO(control);
      }
    });

    return obj;
  };


  saveAndLinkDocument = (form: FormGroup, entityName: string) => {

    const file = form.value.file;

    const fd = new FormData();

    const requestSupplier = new RequestWrapper();
    requestSupplier.entityName = entityName;
    requestSupplier.one = form.value;

    // ne pas doubler le file dans le myJson
    requestSupplier.one.previewFile = undefined;
    delete requestSupplier.one.file;
    fd.set('myJson', JSON.stringify(requestSupplier));
    fd.set('file', file);


    return this.http.post(URL_SAVE_AND_LINK_DOCUMENT, fd)
     .pipe(catchError(error => this.utils.handleError(error)));
  };

  getFieldsByKey = (fieldKeys: string[], fields: FormFieldBaseSupplier<any>[]) => {
    const fieldsToReturn = [];

    if (!this.utils.isCollectionNullOrEmpty(fields)
      && !this.utils.isCollectionNullOrEmpty(fieldKeys)
    ) {

      for (const fieldKey of fieldKeys) {
        const elt = _find(fields, {'key': fieldKey});
        if (elt) {
          fieldsToReturn.push(elt);
        }
      }

    }

    return fieldsToReturn;
  };

  /**
   * Supprimer un élement avec un dialog de confirmation
   * @param label
   * @param entityName
   * @param dto
   */
  openDeleteDialog = async (label: string, entityName: string, dto: ObjectDTO) => {
    const result = confirm(`Êtes vous sûr de vouloir supprimer ${label} ?`, 'Confirmation');
    const isDeleted: boolean = await result;
    if (isDeleted) {
      this.deleteOne(entityName, dto.id).subscribe(response => {
        if (this.utils.isResponseSupplierError(response)) {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, response.resultMessage);
        } else {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `${label} supprimé avec succès`);
          this.announceDeletedId(response);
        }
      })
    }

  };
}

/**
 * Validateur identique au validateur Validators.maxLength avec en plus un trim effectué sur la valeur
 * du champ de manière à ne pas avoir de champs précédés ou suivis du caractère espace (' ').
 * @param {number} maxLength
 * @returns {ValidatorFn}
 */
export function maxTrimLength(maxLength: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    let response = null;


    // Remarque : 'maxlength' est défini dans dynamic-form-field.component.html
    if (control.value == null) {
      response = {'maxlength': {value: control.value}};
    } else if (control.value == undefined) {
      response = {'maxlength': {value: control.value}};
    } else {


      try {
        const trimmed = control.value.trim();
        if (trimmed.length > maxLength) {
          response = {'maxlength': {value: control.value}}
        }
      } catch (error) {
        console.log('error maxTrimLength', control, error);
      }

    }
    return response;
  };
}

/**
 * Validateur identique au validateur Validators.minLength avec en plus un trim effectué sur la valeur
 * du champ de manière à ne pas avoir de champs précédés ou suivis du caractère espace (' ').
 * @param {number} minLength
 * @returns {ValidatorFn}
 */
export function minTrimLength(minLength: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    let response = null;

    // Remarque : 'minlength' est défini dans dynamic-form-field.component.html
    if (control.value == null) {
      response = {'minlength': {value: control.value}};
    } else if (control.value == undefined) {
      response = {'minlength': {value: control.value}};
    } else {
      const trimmed = control.value.trim();
      if (trimmed.length < minLength) {
        response = {'minlength': {value: control.value}}
      }
    }
    return response;
  };


}
