import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {UtilsService} from '../../utils/utils.service';
import {SearchSupplierWrapper} from '../../suppliers/wrappers/search-supplier-wrapper';
import {BonCfDTO} from '../../dtos/boncfs-dto';
import {ResponseWrapper} from '../../suppliers/wrappers/response-wrapper';
import {BonCfDetailDTO} from '../../dtos/boncf-detail-dto';
import {Observable, Subject} from 'rxjs';
import {CatalogueAchatDTO} from '../../dtos/catalogue-achat-dto';
import * as moment from 'moment';
import {ValueDTO} from '../../dtos/value-dto';
import {forEach as _forEach} from 'lodash';
import {MSG_KEY, MSG_SEVERITY, PREDICAT_DIR, PREDICAT_OPERATOR, PREDICAT_TYPE} from '../../constants';
import {GenericRequestSupplier, Predicat, Sort} from '../../suppliers/generics/generic-request-supplier';
import {MailHistoService} from '../entities/mail-histo.service';
import {GenericDatagridService} from "../generics/generic-datagrid.service";
import {MailHistoDTO} from "../../dtos/mail-histo-dto";
import {HttpService} from "../technique/http.service";
import {SaveCommandeDenreeModel} from "../../models/gestion-commande-fournisseur/save-commande-denree-model";
import {ReglePrefereeCommandeFournisseurEnum} from "../../enums/regle-preferee-commande-fournisseur-enum";
import {ToastService} from "../technique/toast.service";

export const URL_POST_SEARCH_COMMANDES = `dolrest/gestion-commandes-fournisseurs/searchCommandes`;
export const URL_GET_LIGNES_COMMANDE_FOURNISSEUR = `dolrest/gestion-commandes-fournisseurs/lignesCommandeFournisseur`;
export const URL_POST_UPDATE_STATUT_COMMANDE = `dolrest/gestion-commandes-fournisseurs/updateCommandesStatut`;
export const URL_POST_PRINT_PDF_COMMANDES = `dolrest/gestion-commandes-fournisseurs/printPDFCommandes`;
export const URL_POST_SEND_MAIL_COMMANDES = `dolrest/gestion-commandes-fournisseurs/sendMail`;
export const URL_POST_DELETE_COMMANDES = `dolrest/gestion-commandes-fournisseurs/deleteCommandes`;
export const URL_POST_SAVE_BONCF = `dolrest/gestion-commandes-fournisseurs/saveBonCf`;
export const URL_POST_SAVE_BONCF_MODE_DENREE = `dolrest/gestion-commandes-fournisseurs/save-bon-commande-mode-denree`;
export const URL_POST_AVAILABLE_ARTICLES_FOURNISSEUR = `dolrest/gestion-commandes-fournisseurs/bonCf/available/articles-fournisseurs`;
export const URL_POST_SAVE_NEW_PROPOSITION_COMMANDE = `dolrest/gestion-commandes-fournisseurs/saveNewPropositionCommande`;
export const URL_GET_FILTER_ITEMS_LISTEBESOIN = `dolrest/gestion-commandes-fournisseurs/getFilterItemsListeBesoin`;
export const URL_GET_FILTER_ITEMS_UDP = `dolrest/gestion-commandes-fournisseurs/getFilterItemsUdp`;
export const URL_GET_FILTER_ITEMS_FFS = `dolrest/gestion-commandes-fournisseurs/getFilterItemsFfs`;
export const URL_POST_FILTER_ITEMS_TABLEAU_DE_BORD = `dolrest/gestion-commandes-fournisseurs/getFilterItemsTableauDeBord`;
export const URL_GET_PROPOSITION_COMMANDE_FOR_CREATION_MODE_DENREE = `dolrest/gestion-commandes-fournisseurs/get-proposition-commande-for-creation-mode-denree`;
export const URL_GET_CALCULATE_DATE_LIVRAISON_POSSIBLE = `dolrest/gestion-commandes-fournisseurs/calculate-date-livraison-possible`;


@Injectable({
  providedIn: 'root'
})
export class CommandesService {

  private subjectMailsBonCfEnvoyes = new Subject<BonCfDTO[]>();
  mailsBonCfEnvoyes$ = this.subjectMailsBonCfEnvoyes.asObservable();

  private subjectRefreshGridCommandeProposition = new Subject<BonCfDTO[]>();
  refreshGridCommandeProposition$ = this.subjectRefreshGridCommandeProposition.asObservable();

  constructor(private http: HttpClient,
              private httpSvc: HttpService,
              private utils: UtilsService,
              private mailHistoSvc: MailHistoService,
              private gds: GenericDatagridService,
              private toastSvc: ToastService) {
  }

  /**
   * Récupérer les lignes d'une commande fournisseur
   */
  getLignesCommandeFournisseur = (idBonCf: number): Observable<ResponseWrapper<BonCfDetailDTO>> => {

    return this.http.get(URL_GET_LIGNES_COMMANDE_FOURNISSEUR, {
      params: new HttpParams().set('idBonCf', idBonCf + '')
    }).pipe(
      catchError(err => this.utils.handleError(err))
    );
  };

  searchCommandes = (ssw: SearchSupplierWrapper, urlParams: string) => {
    return this.http.post(URL_POST_SEARCH_COMMANDES + urlParams, ssw).pipe(
      catchError(err => this.utils.handleError(err, true))
    );
  };

  getCfTitle = (bcf: BonCfDTO): string => {

    let cfTitle = 'Détails';

    if (!this.utils.isNullOrEmpty(bcf)) {
      cfTitle = bcf.bonCfStatut.libelle.toUpperCase() + ' "' + bcf.numeroBcf.toUpperCase()
        + '" du ' + this.utils.getFrenchDate_dddd_Do_MMMM_YYYY(bcf.dateCommande);
    }

    return cfTitle;
  };


  updateCommandesStatut = (selectedBonCfsIds: number[], statutCommande: string): Observable<ResponseWrapper<any>> => {
    const fd = new FormData();
    fd.set('statutCommande', statutCommande);
    fd.set('idsBonCfs', selectedBonCfsIds ? selectedBonCfsIds.join(',') : '');

    return this.http.post(URL_POST_UPDATE_STATUT_COMMANDE, fd).pipe(
      catchError(err => this.utils.handleError(err, true))
    );
  };

  printPDFCommandes = (selectedBonCfs: BonCfDTO[]) => {
    const fd = new FormData();
    fd.set('idsBonCfs', selectedBonCfs ? selectedBonCfs.map(bonCf => bonCf.id).join(',') : '');

    return this.http.post(URL_POST_PRINT_PDF_COMMANDES, fd, {
      headers: new HttpHeaders().set('Accept', 'application/pdf'),
      responseType: 'blob'
    }).pipe(
      catchError(err => this.utils.handleError(err, true))
    );
  };

  delete = (selectedBonCfs: BonCfDTO[]) => {
    const fd = new FormData();
    fd.set('idsBonCfs', selectedBonCfs ? selectedBonCfs.map(bonCf => bonCf.id).join(',') : '');

    return this.http.post(URL_POST_DELETE_COMMANDES, fd).pipe(
      catchError(err => this.utils.handleError(err, true))
    );
  };

  /**
   * Enregistrer un bon de commande fournisseur
   * @param bonCf
   */
  saveBonCf = (bonCf: BonCfDTO, details: BonCfDetailDTO[]): Observable<ResponseWrapper<BonCfDTO>> => {
    return this.http.post(URL_POST_SAVE_BONCF, {bonCf, details}).pipe(
      catchError(err => this.utils.handleError(err, true))
    );
  };

  saveBonCfModeDenree = (idUniteDeProduction: number, saveCommande: SaveCommandeDenreeModel[]) => {
    const params: HttpParams = new HttpParams().set("idUniteDeProduction", idUniteDeProduction);
    return this.httpSvc.post(URL_POST_SAVE_BONCF_MODE_DENREE, saveCommande, params);
  };

  /**
   * Récupérer tous les articles d'un fournisseur qui peuvent correspondre à ce bon de commande : (meme secteur fournisseur)
   * @return {Observable<any>}
   * @param ssw
   * @param urlPaginationParams
   */
  getArticlesFournisseur = (filters: any): Observable<ResponseWrapper<CatalogueAchatDTO>> => {
    const args = Object.keys(filters).map(key => key + '=' + filters[key]).join('&');
    return this.http.get(`${URL_POST_AVAILABLE_ARTICLES_FOURNISSEUR}?${args}`).pipe(
      catchError(err => this.utils.handleError(err, true))
    );
  };

  /**
   * Enregistrer une nouvelle proposition de commande
   * @param idUniteDeProduction
   * @param idSecteurFournisseur
   * @param dateLivraison
   */
  saveNewPropositionCommande = (idUniteDeProduction: number, idSecteurFournisseur: number, dateLivraison: Date): Observable<ResponseWrapper<BonCfDTO>> => {
    const fd = new FormData();
    fd.set('idUniteDeProduction', idUniteDeProduction + '');
    fd.set('idSecteurFournisseur', idSecteurFournisseur + '');
    fd.set('dateLivraison', this.utils.getYYYYMMDD(moment(dateLivraison)));

    return this.http.post(URL_POST_SAVE_NEW_PROPOSITION_COMMANDE, fd).pipe(
      catchError(err => this.utils.handleError(err, true))
    );
  };

  /**
   * Récupérer les éléments du filtre liste de besoins
   */
  getFilterItemsListeBesoin = (codeStatut: string): Observable<ResponseWrapper<ValueDTO>> => {
    return this.http.get(URL_GET_FILTER_ITEMS_LISTEBESOIN, {
      params: new HttpParams().set('codeStatut', codeStatut)
    }).pipe(
      catchError(err => this.utils.handleError(err, true))
    );
  };

  getFilterItemsUdp = (codeStatut: string) => {
    return this.http.get(URL_GET_FILTER_ITEMS_UDP, {
      params: new HttpParams().set('codeStatut', codeStatut)
    }).pipe(
      catchError(err => this.utils.handleError(err, true))
    );
  };

  getFilterItemsFfs = (codeStatut: string) => {
    return this.http.get(URL_GET_FILTER_ITEMS_FFS, {
      params: new HttpParams().set('codeStatut', codeStatut)
    }).pipe(
      catchError(err => this.utils.handleError(err, true))
    );
  };

  sendMail = (selectedBonCfs: BonCfDTO[]) => {
    const fd = new FormData();
    fd.set('idsBonCfs', selectedBonCfs ? selectedBonCfs.map(bonCf => bonCf.id).join(',') : '');
    return this.http.post(URL_POST_SEND_MAIL_COMMANDES, fd).pipe(
      catchError(err => this.utils.handleError(err, true))
    );
  };

  getPropositionCommandeForCreationModeDenree = (idProduitDeclinaisonList: number[], idUniteDeProduction: number, dateLivraisonSouhaite: Date, scenarioArticle: ReglePrefereeCommandeFournisseurEnum) => {

    let params: HttpParams = new HttpParams()
      .set('idUniteDeProduction', idUniteDeProduction)
      .set('idProduitDeclinaisonList', idProduitDeclinaisonList ? idProduitDeclinaisonList.map(id => id).join(',') : '')
      .set('dateLivraisonSouhaite', dateLivraisonSouhaite.toDateString())
      .set('scenarioArticle', scenarioArticle);

    return this.httpSvc.get(URL_GET_PROPOSITION_COMMANDE_FOR_CREATION_MODE_DENREE, params);
  };

  calculateDateLivraisonPossible = (idUniteDeProduction: number, dateLivraisonSouhaite: Date, idFournisseur: number) => {

    let params: HttpParams = new HttpParams()
      .set('idUniteDeProduction', idUniteDeProduction)
      .set('idFournisseur', idFournisseur)
      .set('dateLivraisonSouhaite', dateLivraisonSouhaite.toDateString())

    return this.httpSvc.get(URL_GET_CALCULATE_DATE_LIVRAISON_POSSIBLE, params);
  };

  getDataTableauDeBord = (ssw: SearchSupplierWrapper, urlParams: string) => {
    return this.http.post(URL_POST_FILTER_ITEMS_TABLEAU_DE_BORD + urlParams, ssw).pipe(
      catchError(err => this.utils.handleError(err, true))
    );
  };

  filterStatusMails = (bonsCf: BonCfDTO[]): GenericRequestSupplier => {
    const grs = new GenericRequestSupplier(this.mailHistoSvc.getEntityName());

    if (bonsCf) {
      const numerosBcf = bonsCf.map(bonCf => bonCf.numeroBcf);

      const predicat = new Predicat();
      predicat.path = `${this.mailHistoSvc.getEntityName().toLowerCase()}.extraInfos`;
      predicat.operator = PREDICAT_OPERATOR.In;
      predicat.type = PREDICAT_TYPE.String;
      predicat.values = numerosBcf;
      grs.search.predicats.push(predicat);

      const sort = new Sort();
      sort.dir = PREDICAT_DIR.Descendant;
      sort.path = `${this.mailHistoSvc.getEntityName().toLowerCase()}.sentDate`;
      grs.search.sorts.push(sort);
    }

    return grs;
  };

  refreshStatusMailsPeriodically = (mailsEnvoyes: MailHistoDTO[], bonsCf: BonCfDTO[]): Observable<ResponseWrapper<MailHistoDTO>> => {
    // On enlève les duplicats de bons de commandes :
    const commandesEnvoyeesParMail = new Set<BonCfDTO>();
    mailsEnvoyes.forEach((mail) => {
      const commandeFound = bonsCf.find(cmd => mail.extraInfos === cmd.numeroBcf);
      if (commandeFound) {
        commandesEnvoyeesParMail.add(commandeFound);
      }
    });

    const mailsFiltresList$ = this.gds.search(this.filterStatusMails(Array.from(commandesEnvoyeesParMail)));
    return this.utils.retryInterval(4, 30000, mailsFiltresList$);
  };

  updateStatusBonsCommandesFromRefresh = (mailsRefreshed: MailHistoDTO[], bonCfs: BonCfDTO[], createMailHistoDto: boolean) => {
    if (mailsRefreshed && bonCfs) {
      mailsRefreshed.forEach((mail: MailHistoDTO) => {
        const idxCommande = bonCfs.findIndex(cmd => cmd.numeroBcf === mail.extraInfos);
        if (idxCommande) {
          if (bonCfs[idxCommande] && !bonCfs[idxCommande].mailHisto && createMailHistoDto) {
            bonCfs[idxCommande].mailHisto = new MailHistoDTO();
          }
          bonCfs[idxCommande].mailHisto.statut = mail.statut;
        }
      });
    }
  };

  announceMailsToFournisseursEnvoyes = (bonCfsEnvoyes: BonCfDTO[]) => {
    this.subjectMailsBonCfEnvoyes.next(bonCfsEnvoyes);
  };

  announceRefreshPropositionCommandeGrid = () => {
    this.subjectRefreshGridCommandeProposition.next();
  };

  sendMailToFournisseurs = (selectedBonCfs: BonCfDTO[]) => {

    this.sendMail(selectedBonCfs).subscribe(response => {

      if (this.utils.isCollectionNullOrEmpty(response.additionalProperties.errors)) {
        this.announceMailsToFournisseursEnvoyes(selectedBonCfs);
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Commandes envoyées par mails aux fournisseurs avec succès`);
      } else if (response.additionalProperties.errors) {
        let finalError = '';
        response.additionalProperties.errors.forEach((err, idx) => {
          finalError += err;
          if (idx < response.additionalProperties.errors.length - 1) finalError += ', ';
        });
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, finalError);
      }
    });
  };

}
