import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  CatalogueAchatDialogSupplier,
  CataloguesAchatsService
} from '../../../../../core/services/entities/catalogues-achats.service';
import {UtilsService} from '../../../../../core/utils/utils.service';
import {GenericDatagridService} from '../../../../../core/services/generics/generic-datagrid.service';
import {
  AllergenesService,
  CODE_INFO_INCOMPLETE
} from '../../../../../core/services/entities/allergenes-service.service';
import {catchError, filter, switchMap, tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {CatalogueAchatDTO} from '../../../../../core/dtos/catalogue-achat-dto';
import {cloneDeep as _cloneDeep, find as _find} from 'lodash';
import {AllergeneDTO} from '../../../../../core/dtos/allergene-dto';
import {DATAGRID_ROW_TYPES} from '../../../../../core/services/technique/devextreme.service';
import {ProduitArticleAllergeneDTO} from '../../../../../core/dtos/produit-article-allergene-dto';
import {DxDataGridComponent} from 'devextreme-angular';
import {MSG_KEY, MSG_SEVERITY} from '../../../../../core/constants';
import {UniteDeProductionDTO} from '../../../../../core/dtos/unite-de-production-dto';
import {Auth2Service} from '../../../../../core/services/security/auth2.service';
import {ToastService} from "../../../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-allergenes',
  templateUrl: './article-allergenes.component.html',
  styleUrls: ['./article-allergenes.component.scss']
})
export class ArticleAllergenesComponent implements OnInit, OnDestroy {

  subArticleAllergenes: Subscription;
  catalogueAchat: CatalogueAchatDTO;
  allergeneList: AllergeneDTO[];
  produitArticleAllergeneList: ProduitArticleAllergeneDTO[];
  cads: CatalogueAchatDialogSupplier;
  uniteDeProduction: UniteDeProductionDTO;

  @ViewChild('grid') grid: DxDataGridComponent;

  constructor(public utils: UtilsService,
              public auth2Svc: Auth2Service,
              public catalogueAchatSvc: CataloguesAchatsService,
              private gds: GenericDatagridService,
              public allergeneSvc: AllergenesService,
              private toastSvc: ToastService) {

  }

  ngOnInit(): void {


    this.initArticleALlergenesSubscription();

  }

  initArticleALlergenesSubscription = () => {

    this.subArticleAllergenes = this.catalogueAchatSvc.articleAllergenes$.pipe(
      filter(data => data),
      tap(data => this.cads = data),
      tap(data => this.uniteDeProduction = _cloneDeep(data.uniteDeProduction)),
      tap(data => this.catalogueAchat = _cloneDeep(data.catalogueAchat)),
      switchMap(data => this.gds.getAll(this.allergeneSvc.getEntityName(), this.allergeneSvc.getSort(), true)),
      switchMap(data => {
        this.allergeneList = this.putInfoIncompleteInFirst(data.resultList);
        return this.catalogueAchatSvc.getProduitArticleAllergenes(this.catalogueAchat.produitArticle.id);
      }),
      catchError(err => this.utils.handleError(err))
    )
      .subscribe(data => {
        this.produitArticleAllergeneList = data.resultList;
        this.grid.selectedRowKeys = this.preselectRows(this.allergeneList, this.produitArticleAllergeneList);
      });

  };

  /**
   * On récupére la liste des allergenes et on positionne l'info incomplete en premier
   * @param allergeneList
   * @return AllergeneDTO[]
   */
  putInfoIncompleteInFirst = (allergeneList: AllergeneDTO[]): AllergeneDTO[] => {
    let arr = [];

    if (!this.utils.isCollectionNullOrEmpty(allergeneList)) {

      arr = allergeneList.filter(item => item.code != CODE_INFO_INCOMPLETE);

      const allergeneInfoIncomplete = _find(allergeneList, {'code': CODE_INFO_INCOMPLETE});
      if (allergeneInfoIncomplete) {
        arr.unshift(allergeneInfoIncomplete);
      }
    }

    return arr;
  };

  preselectRows = (allergeneList: AllergeneDTO[], produitAllergeneAppellationList: ProduitArticleAllergeneDTO[]) => {

    const idSelectedRows = [];

    if (!this.utils.isCollectionNullOrEmpty(produitAllergeneAppellationList) && !this.utils.isCollectionNullOrEmpty(allergeneList)) {
      for (const paa of produitAllergeneAppellationList) {
        const allergene = _find(allergeneList, {'id': paa.allergeneId});
        if (allergene) {
          idSelectedRows.push(allergene.id);
        }
      }
    }

    return idSelectedRows;
  };


  saveAllergenes = () => {

    const selectedAllergeneList = this.grid.instance.getSelectedRowsData();

    this.catalogueAchatSvc.saveProduitArticleAllergenes(this.catalogueAchat.produitArticle.id, selectedAllergeneList).subscribe(response => {
      if (!this.utils.isResponseSupplierError(response))
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Les allergènes ont été enregistrés avec succès`);
    });
  };


  closeDialog = () => {
    this.catalogueAchatSvc.announceCloseDialogArticle(undefined);
  };

  ngOnDestroy = (): void => {
    this.utils.unsubscribe(this.subArticleAllergenes);
  };


  onRowPrepared = (event: any) => {

    if (event.rowType === DATAGRID_ROW_TYPES.DATA) {
      event.rowElement.style.height = '64px';

    }
  };

}
