import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UtilsService} from '../../../../core/utils/utils.service';
import {Auth2Service} from '../../../../core/services/security/auth2.service';
import {DuplicationMenuService} from '../../../../core/services/gestionmenus/duplication-menu.service';
import {Subscription} from 'rxjs';
import {catchError, debounceTime, switchMap} from 'rxjs/operators';
import {ContratMenuConvive__ContrainteAlimDTO} from '../../../../core/dtos/contratmenuconvive__contrainte-alim-dto';
import {cloneDeep as _cloneDeep} from 'lodash'
import * as moment from 'moment';
import {MenuItem, SelectItem} from 'primeng/api';
import {DATEPICKER_FR, DUPLICATION_ECRASER_MENUS, MSG_KEY, MSG_SEVERITY} from '../../../../core/constants';
import {MenusToolbarService} from '../../../../core/services/gestionmenus/menus-toolbar.service';
import {MenusPlanning2Service} from '../../../../core/services/gestionmenus/menus-planning2.service';
import {MRapportDuplication} from '../../../../core/models/gestion-menus/rapport-duplication';
import {PlanningMenusDTO} from '../../../../core/dtos/gestionmenus/planning/planning-menus-dto';
import {Table} from 'primeng/table';
import {ContratMenuConviveDTO} from '../../../../core/dtos/contratmenuconvive-dto';
import {ToastService} from "../../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-duplication',
  templateUrl: './duplication.component.html',
  styleUrls: ['./duplication.component.scss'],

})
export class DuplicationMenusComponent implements OnInit, OnDestroy {

  subDialogDuplicationMenus: Subscription;
  readonly localeFr = DATEPICKER_FR;
  STEP_DUPLICATION=STEP_DUPLICATION;
  activeStep: STEP_DUPLICATION = this.STEP_DUPLICATION.SELECTION_PRESTATION;
  errorStepPrestationList: string[] = [];
  errorStepPeriodeList: string[] = [];
  displayDialog = false;

  @ViewChild('dtSource', {static: false}) dtSource: Table;

  steps: MenuItem[] = [
    {label: 'Sélection des prestations'},
    {label: 'Sélection de la période'},
    {label: 'Options de recopie'},
    {label: `Résultats`},
  ];

  //subscriptions
  subMonthChange: Subscription;

  sourceDatesSaisieEffectifs: Date[];
  sourceDatesCreationMenus: Date[];
  sourcesDateMin: Date[];
  sourceDisabledDays: number[];


  sourceCmcCaList: ContratMenuConvive__ContrainteAlimDTO[] = [];
  selectedSourceCmcCa: ContratMenuConvive__ContrainteAlimDTO;

  targetCmcCaList: ContratMenuConvive__ContrainteAlimDTO[] = [];
  selectedTargetCmcCaList: ContratMenuConvive__ContrainteAlimDTO[] = [];

  selectedRangeSourceDates: Date[] = [new Date(), new Date()];
  selectedDateDebutTarget: Date;
  disabledDateDebutDays: number[] = [];

  rapportErreursDuplicationMenu: MRapportDuplication[] = [];
  nbPlatsCopies: number;
  nbPlatsSources: number;


  duplicationEnCours = false;

  DME = DUPLICATION_ECRASER_MENUS;
  optionsEcraserMenu: SelectItem[] = [
    {label: 'E-', value: this.DME.AUCUN},
    {label: 'E', value: this.DME.ECRASER_SI_PLAT_EXISTANT},
    {label: 'E+', value: this.DME.ECRASER_TOUT},
  ];

  optionEcraserMenu = this.DME.AUCUN;
  optionRegimeSource = false;
  optionDeclinaisonSource = false;

  CELL_WIDTH_REGIME = 100;
  CELL_WIDTH_PRESTATION = 200;
  CELL_WIDTH_SITE = 150;
  CELL_WIDTH_OFFRE_ALIM = 150;

  cols: any[] = [

    {field: 'cmSiteLibelle', header: 'Site'},
    {field: 'cmLibelle', header: 'Offre Alimentaire'},
    {field: 'cmcLibelle', header: 'Prestation'},
    {field: 'regimeLibelle', header: 'Régime'}

  ];

  constructor(
    public utils: UtilsService,
    public auth2Svc: Auth2Service,
    public duplicationMenuService: DuplicationMenuService,
    private menusToolbarSvc: MenusToolbarService,
    private mp2Svc: MenusPlanning2Service,
    private toastSvc: ToastService) {}

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subDialogDuplicationMenus);
    this.utils.unsubscribe(this.subMonthChange);
  }

  ngOnInit(): void {

    // chargement du dialog de duplication menus
    this.duplicationMenuService.displayDialogDuplicationMenus$
      .pipe(
        switchMap(response => {

          // default parameters
          this.activeStep = this.STEP_DUPLICATION.SELECTION_PRESTATION;
          this.errorStepPrestationList = [];
          this.selectedSourceCmcCa = null;
          this.selectedTargetCmcCaList = [];
          this.errorStepPeriodeList = [];
          this.rapportErreursDuplicationMenu = [];
          const defaultStartDate = this.utils.convertNumberDateToDate(this.mp2Svc.planningMenus.dateList[0].date);
          const defaultStopDate = this.utils.convertNumberDateToDate(this.mp2Svc.planningMenus.dateList[this.mp2Svc.planningMenus.dateList.length - 1].date);
          this.selectedRangeSourceDates = [defaultStartDate, defaultStopDate];
          this.selectedDateDebutTarget = _cloneDeep(this.selectedRangeSourceDates[0]);
          this.disabledDateDebutDays = this.duplicationMenuService.getDisabledDateDebutDays(this.selectedRangeSourceDates[0]);
          // afficher le dialog
          this.displayDialog = response as boolean;
          // recuperer les infos nécessaires du back pour afficher le dialog
          return this.duplicationMenuService.prepareDialogDuplicationMenus();
        })
      )
      .subscribe(response => {
        if (!this.utils.isResponseSupplierError(response)) {
          this.sourceCmcCaList = response.additionalProperties['cmcCaList'];
          this.selectedSourceCmcCa = this.preselectSourceCmcCa(this.mp2Svc.planningMenus, this.sourceCmcCaList);
          this.targetCmcCaList = this.duplicationMenuService.getTargetCmcCaList(this.sourceCmcCaList);
        }
      });

    this.subMonthChange = this.duplicationMenuService.monthChange$
      .pipe(
        debounceTime(100),
        switchMap(event => {
          const sourceCmc = new ContratMenuConviveDTO();
          sourceCmc.id = this.selectedSourceCmcCa.cmcId;
          return this.mp2Svc.changeMonth(sourceCmc, moment(this.selectedRangeSourceDates[0]).toDate(), event)
        }),
        catchError(err => this.utils.handleError(err))
      )
      .subscribe(response => {
        this.sourceDisabledDays = response.disabledDays;
        this.sourceDatesSaisieEffectifs = response.datesSaisieEffectifs;
        this.sourceDatesCreationMenus = response.datesCreationMenus;
        this.sourcesDateMin = response.dateMin;
      });
  }

  /**
   * Préselectionner la source avec la prestation sélectionnée dans le menu et la faire apparaite en premier
   */
  preselectSourceCmcCa = (planningMenus: PlanningMenusDTO, sourceCmcCaList: ContratMenuConvive__ContrainteAlimDTO[]) => {
    let selectedSourceCmcCa = null;

    if (!this.utils.isCollectionNullOrEmpty(sourceCmcCaList) && !this.utils.isCollectionNullOrEmpty(planningMenus.contratMenuConviveList)) {
      const sourceCmc = planningMenus.contratMenuConviveList[0];
      const sourceCmcCa = planningMenus.contrainteAlimentaire;

      for (const cmcCa of sourceCmcCaList) {
        if (cmcCa.cmcId === sourceCmc.id && cmcCa.id === sourceCmcCa.id) {
          selectedSourceCmcCa = cmcCa;
          break;
        }
      }

      if (!this.utils.isNullOrEmpty(selectedSourceCmcCa)) {
        sourceCmcCaList.forEach((item, i) => {
          if (item.id === selectedSourceCmcCa.id) {
            sourceCmcCaList.splice(i, 1);
            sourceCmcCaList.unshift(item);
          }
        });

      }
    }


    return selectedSourceCmcCa;
  };

  closeDialog = () => {
    this.displayDialog = false;
  };

  validateStepPrestation = (goToStep: number) => {

    this.errorStepPrestationList = [];
    this.errorStepPeriodeList = [];


    if (this.utils.isNullOrEmpty(this.selectedSourceCmcCa)) {
      this.errorStepPrestationList.push('Veuillez sélectionner une prestation à dupliquer !');
    }

    if (this.utils.isCollectionNullOrEmpty(this.selectedTargetCmcCaList)) {
      this.errorStepPrestationList.push('Veuillez sélectionner des prestations cibles !');
    }

    // si aucune erreur, on passe à l'étape suivante.
    if (this.utils.isCollectionNullOrEmpty(this.errorStepPrestationList)) {
      this.activeStep = goToStep;
    }

  };

  validateStepPeriode = (goToStep: number) => {

    this.errorStepPeriodeList = [];


    if (this.utils.isCollectionNullOrEmpty(this.selectedRangeSourceDates)
      || this.utils.isNullOrEmpty(this.selectedRangeSourceDates[0])
      || this.utils.isNullOrEmpty(this.selectedRangeSourceDates[1])) {

      // si la date fin est vide mais que la date de debut est renseignee, on prend la date de debut comme date de fin
      if (!this.utils.isNullOrEmpty(this.selectedRangeSourceDates[0]) && this.utils.isNullOrEmpty(this.selectedRangeSourceDates[1])) {
        this.selectedRangeSourceDates[1] = _cloneDeep(this.selectedRangeSourceDates[0]);
      } else {
        this.errorStepPeriodeList.push(`Veuillez sélectionner une période pour la prestation à dupliquer ! `);
      }
    }

    // si la date debut source === date debut cible et que prestation source == prestation cible, on ne doit pas autoriser la duplication
    if (!this.canDuplicateOnSamePrestation(this.selectedSourceCmcCa, this.selectedTargetCmcCaList, this.selectedRangeSourceDates[0], this.selectedDateDebutTarget)) {
      this.errorStepPeriodeList.push(`Vous ne pouvez pas dupliquer la prestation source sur elle même ! Veuillez dé-selectionner la prestation source dans votre liste de prestations cibles.`);
    }

    // si aucune erreur, on passe à l'étape suivante.
    if (this.utils.isCollectionNullOrEmpty(this.errorStepPeriodeList)) {
      this.activeStep = goToStep;
    }

  };

  /**
   *   si la date debut source === date debut cible et que prestation source == prestation cible, on ne doit pas autoriser la duplication
   * @param sourceCmcCa
   * @param targetCmcCa
   * @param sourceDateDebut
   * @param targetDateDebut
   */
  canDuplicateOnSamePrestation = (sourceCmcCa: ContratMenuConvive__ContrainteAlimDTO, targetCmcCaList: ContratMenuConvive__ContrainteAlimDTO[], sourceDateDebut: Date, targetDateDebut: Date) => {

    for (const targetCmcCa of targetCmcCaList) {

      if (sourceCmcCa.id === targetCmcCa.id) {

        if (this.utils.isDateEqualsYYYYMMDD(moment(sourceDateDebut), moment(targetDateDebut))) {
          return false;
        }
      }

    }

    return true;
  };

  getDateFromString = (ddMMYYY: string) => this.utils.getFrenchDate_dddd_Do_MMMM_YYYY(moment(ddMMYYY, 'DDMMYYYY'));

  changeSelectedRangeSourceDates = $event => {

    this.disabledDateDebutDays = this.duplicationMenuService.getDisabledDateDebutDays(this.selectedRangeSourceDates[0]);
    this.selectedDateDebutTarget = this.selectedRangeSourceDates[0];

  };

  launchDuplicationMenus = () => {

    this.duplicationEnCours = true;

    this.duplicationMenuService.launchDuplicationMenus(this.selectedRangeSourceDates, this.selectedDateDebutTarget, this.selectedSourceCmcCa, this.selectedTargetCmcCaList,
      this.optionEcraserMenu, this.optionRegimeSource, this.optionDeclinaisonSource).subscribe(response => {

      if (!this.utils.isResponseSupplierError(response)) {
        // this.closeDialog();

        // recharger le planning des menus
        this.menusToolbarSvc.announcePrestations(this.mp2Svc.planningMenus.contratMenuConviveList);

        // notifier l'utilisateur que le traitement est terminé
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `La duplication des menus a été réalisée avec succès`);

        // rapport d'erreurs de la duplication des menus
        this.rapportErreursDuplicationMenu = response.additionalProperties['rapportErreursDuplicationMenu'];
        this.nbPlatsCopies = response.additionalProperties['nbPlatsCopies'];
        this.nbPlatsSources = response.additionalProperties['nbPlatsSources'];

      }

      this.activeStep = this.STEP_DUPLICATION.RAPPORT;
      this.duplicationEnCours = false;

    });
  };


  getMonthMenusCompoDates = ($event: any) => {
    this.duplicationMenuService.announceMonthChange($event);
  };
}

export enum STEP_DUPLICATION {
  SELECTION_PRESTATION = 0,
  SELECTION_PERIODE = 1,
  RECAPITULATIF = 2,
  RAPPORT = 3
}



