import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {DialogMsgSupplier, Paragraphe} from "../../../../../core/suppliers/dialog-msg-supplier";
import {UtilsService} from "../../../../../core/utils/utils.service";
import {combineLatest, Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {PointDeLivraisonDTO} from "../../../../../core/dtos/point-de-livraison-d-t-o";
import {Auth2Service} from "../../../../../core/services/security/auth2.service";
import {RoutemapService} from "../../../../../core/services/routemap.service";
import {ClientsBusinessService} from "../../../../../core/services/gestion-clients/clients.service";
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY, UI_COLORS} from "../../../../../core/constants";
import {GenericDatagridService} from "../../../../../core/services/generics/generic-datagrid.service";
import {PointDeLivraisonService} from "../../../../../core/services/entities/point-de-livraison.service";
import {DATAGRID_ROW_TYPES} from "../../../../../core/services/technique/devextreme.service";
import {ConditionnementRegroupementService} from "../../../../../core/services/conditionnements/regroupement/conditionnement-regroupement.service";
import {RegleRegimeModel} from "../../../../../core/models/gestion-conditionnements/regroupement/regle-regime-model";
import {DxDataGridComponent} from "devextreme-angular";
import {RegimeAlimentaireDTO} from "../../../../../core/dtos/regime-alimentaire-dto";
import {ConviveDTO} from "../../../../../core/dtos/convive-dto";
import {confirm} from "devextreme/ui/dialog";
import {ToastService} from "../../../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-regles-regroupement',
  templateUrl: './regles-regroupement.component.html',
  styleUrls: ['./regles-regroupement.component.scss']
})
export class ReglesRegroupementComponent implements OnInit, OnDestroy {

  allMode: string = 'allPages';
  checkBoxesMode: string = 'always';
  selectedRows: number[] = [];

  subscriptionRoute: Subscription;
  subscriptionRights: Subscription;
  subscriptionAssociationSaved: Subscription;

  rules: RegleRegimeModel[] = [];
  cptIdRules: number = 0;

  prestations: ConviveDTO[];
  regimes: RegimeAlimentaireDTO[];

  prestationsLibelles: string[];

  hasGestionClients = false;
  hasGestionIDistri = false;

  plc: PointDeLivraisonDTO;

  /**
   * Pour éviter l'effet suivant :
   * 0 1 => 1 0 alors que l'on veut 0 1 => 0 0 ou 0 1 => 1 1
   * Conclusion : Ne pas utiliser actif = !actif
   */
  toogleByRules: Map<number, boolean> = new Map();

  pathFile: string = HELP_FOLDERS.CONDITIONNEMENT + '/conditionnement-regles-regroupement';

  @ViewChild('grid') dataGrid: DxDataGridComponent;

  constructor(public utils: UtilsService,
              public auth2Svc:Auth2Service,
              private route: ActivatedRoute,
              private routeMapSvc: RoutemapService,
              private clientsSvc: ClientsBusinessService,
              private gds: GenericDatagridService,
              private pdlSvc: PointDeLivraisonService,
              private cdtRegroupementSvc: ConditionnementRegroupementService,
              private toastSvc: ToastService){}

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subscriptionRoute);
    this.utils.unsubscribe(this.subscriptionRights);
  }

  ngOnInit(): void {
    this.initRouteSubscription();
    this.initRightsSubscription();
  }

  initRouteSubscription = (): void => {
    this.subscriptionRoute = this.route.parent.parent.data
      .subscribe((data) => {
        this.plc = data.plcSupplier.pointDeLivraison;

        this.initPrestationsRegimesSubscription();
        this.initRulesSubscription();
      });
  };

  initRightsSubscription = (): void => {
    const all$ = combineLatest([this.auth2Svc.hasGestionClients$, this.auth2Svc.hasGestionIDistri$]);
    this.subscriptionRights = all$.subscribe(response => {
      this.hasGestionClients = response[0];
      this.hasGestionIDistri = response[1];
    });
  };

  /**
   * unsubscribe inutile ici
   */
  initPrestationsRegimesSubscription = (): void => {
    this.cdtRegroupementSvc.getAllPrestationsRegimesByIdPlc(this.plc.id)
      .subscribe(response => {
        this.prestations = response.resultList;
        this.prestationsLibelles = this.prestations.map(p => p.libelle);
      });
  };

  /**
   * unsubscribe inutile ici
   */
  initRulesSubscription = (): void => {
    this.cdtRegroupementSvc.getAllRulesByIdPlc(this.plc.id)
      .subscribe(response => {
        this.rules = response.resultList;
        if (this.rules.length) {
          const numRules: number[] = this.rules.map(r => r.id);
          this.cptIdRules = Math.max(...numRules); // Stockage du dernier n° de règle
          this.rules.forEach(r => this.toogleByRules.set(r.id, false));
        }
      });
  };

  /**
   * Au lieu de passer par une boîte de dialogue, on crée directement une nouvelle
   * ligne au niveau du tableau où l'utilisateur pourra directement cocher ou décocher
   * les régimes par prestation qu'il souhaite regrouper pour le conditionnement.
   */
  createRule = (): void => {
    ++this.cptIdRules;
    const numRule = this.cptIdRules; // copie pour éviter la référence
    const rule: RegleRegimeModel = new RegleRegimeModel();
    rule.id = numRule;
    rule.plcId = this.plc.id;
    rule.prestations = this.prestations.map(p => ({ id: p.id, libelle: p.libelle, regimes: p.regimes.map(r => ({ id: r.id, libelle: r.libelle, actif: true }) ) }));
    this.rules.push(rule);
    this.toogleByRules.set(rule.id, true);
  };

  getToolTipPrestation = (event: any) => {
    const currentPrestation = event.data.prestations.find(p => p.libelle === event.column.name.libelle); // il ne peut en avoir qu'un
    const currentRegimeLabel = event.column.caption;

    const currentRegime = currentPrestation.regimes.find(r => `Régime ${r.libelle}` === currentRegimeLabel);
    return currentRegime.actif ?
      'Cliquez ici pour désactiver le regroupement' : 'Cliquez ici pour activer le regroupement';
  };

  onCellClick = (event: any) => {
   if (event.columnIndex > 1) {
     const currentPrestation = event.data.prestations.find(p => p.libelle === event.column.name.libelle); // il ne peut en avoir qu'un
     const currentRegimeLabel = event.column.caption;
     const currentRegime = currentPrestation.regimes.find(r => `Régime ${r.libelle}` === currentRegimeLabel);
     currentRegime.actif = !currentRegime.actif;
      this.cellActiveBackground(event);
   }
  };

  onRowPrepared = (event: any) => {
    if (event.rowType === DATAGRID_ROW_TYPES.DATA) {
      event.rowElement.style.lineHeight = '40px';
    }
  };

  onCellPrepared = (event: any) => {
    this.cellActiveBackground(event);
  };

  cellActiveBackground = (event: any) => {
    if (event.columnIndex > 1 && event.rowType === DATAGRID_ROW_TYPES.DATA) {
      const currentPrestation = event.data.prestations.find(p => p.libelle === event.column.name.libelle); // il ne peut en avoir qu'un
      const currentRegimeLabel = event.column.caption;
      const currentRegime = currentPrestation.regimes.find(r => `Régime ${r.libelle}` === currentRegimeLabel);
      if (currentRegime) {
        event.cellElement.style.cursor = 'pointer';
        event.cellElement.style.backgroundColor = UI_COLORS.CHECKED;
        if (currentRegime.actif) {
          event.cellElement.style.backgroundColor = UI_COLORS.CHECKED;
        } else {
          event.cellElement.style.backgroundColor = UI_COLORS.NOT_CHECKED;
        }
      }
    }
  };

  save = (): void => {
    this.cdtRegroupementSvc.saveRules(this.rules)
      .subscribe(result => {
        this.initRulesSubscription();
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Règle(s) créée(s) avec succès`);
      });
  };

  toggleRow = (idRule: number): void => {
    const rule: RegleRegimeModel = this.rules.find(r => r.id === idRule);
    if (rule) {
      const currentToggleValue: boolean = this.toogleByRules.get(rule.id);
      rule.prestations.forEach(p => {
        p.regimes.forEach(r => r.actif = !currentToggleValue);
      });
      this.toogleByRules.set(rule.id, !currentToggleValue);
      this.dataGrid.instance.refresh(); // Rafraîchissement de la couleur des cellules
    }
  };

  canEdit = (): boolean => this.hasGestionClients && this.hasGestionIDistri;

  deleteValues = async (): Promise<void> => {
    const result = confirm(`Êtes-vous sûr de vouloir supprimer le(s) règle(s) sélectionnée(s) ?`, 'Suppression de règle');
    const isDeleted: boolean = await result;
    if (isDeleted) {
      this.cdtRegroupementSvc.deleteRules(this.selectedRows)
        .subscribe(r => {
          const result = r.one;
          if (result.deletable) {
            this.rules = this.rules.filter(r => !this.selectedRows.includes(r.id));
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Règle(s) supprimée(s) avec succès`);
          } else {
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, result.messageList && result.messageList.length ? result.messageList.join(',') : '');
          }
        });
    }
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Les règles de regroupement`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
    ];

    dms.content = {
      intro: ``,
      paragraphes: []
    };
    return dms;
  };

}
