import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SiteDTO} from '../../../core/dtos/site-dto';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {IYoForm} from '../../../shared/IYoForm';
import {SiteResolverService, SiteSupplier} from '../../site-resolver.service';
import {UtilsService} from '../../../core/utils/utils.service';
import {TreeNode} from 'primeng/api';
import {SiteService} from '../../../core/site.service';
import {MSG_KEY, MSG_SEVERITY} from '../../../core/constants';
import {ResponseWrapper} from '../../../core/suppliers/wrappers/response-wrapper';
import {ContratmenuDTO} from '../../../core/dtos/contratmenu-dto';
import {ContratMenuConviveDTO} from '../../../core/dtos/contratmenuconvive-dto';
import {TreeNodeData} from '../../../core/tree-node-data';
import {ContratMenuService} from '../../../core/services/gestioncontrats/contrat-menu.service';
import {sortBy as _sortBy} from 'lodash'
import {DialogMsgSupplier, Paragraphe} from '../../../core/suppliers/dialog-msg-supplier';
import {ToastService} from "../../../core/services/technique/toast.service";


@Component({
  selector: 'yo-ficheidentite-site',
  templateUrl: './ficheidentite-site.component.html',
  styleUrls: ['./ficheidentite-site.component.scss'],
})
export class FicheidentiteSiteComponent implements OnInit, IYoForm, OnDestroy {

  // Subscriptions
  subFicheIdentite: Subscription;
  subDeleteSite: Subscription;

  // La form englobante
  formGroupCtrl: FormGroup;

  isLoading: boolean = false;

  // Sauvegarde du message
  messageLoadingService: string;

  // Champs Site
  libelleCtrl: FormControl;
  referenceCtrl: FormControl;
  actifCtrl: FormControl;

  // Champs Adresse
  adresse1Ctrl: FormControl;
  adresse2Ctrl: FormControl;
  adresse3Ctrl: FormControl;
  telephoneCtrl: FormControl;
  faxCtrl: FormControl;
  codePostalCtrl: FormControl;
  villeCtrl: FormControl;
  emailCtrl: FormControl;

  // Donnnées liées aux contrats
  listeAllContratsMenusActifs: ContratmenuDTO[];
  mapContratsMenus: Map<number, ContratmenuDTO[]>;

  /**
   * Liste de TOUS les Site actifs et ayant au moins un ContratMenu actif.
   */
  listeAllSitesActifsWithContratMenu: SiteDTO[];

  selectedContratsMenus: TreeNode[] = [];
  selectedContratsMenusReferent: TreeNode[] = [];

  rootContratsMenus: TreeNode;
  rootContratsMenusRererent: TreeNode = null;

  siteReferentCtrl: FormControl;


  isDisplayPanelSiteModele: boolean = false;

  /**
   * le Site qu'on est en train d'éditer.
   */
  @Input() yoSite: any;
  siteReferent: SiteDTO = new SiteDTO();


  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private utils: UtilsService,
              private siteResolverService: SiteResolverService,
              private contratMenuService: ContratMenuService,
              private siteService: SiteService,
              private toastSvc: ToastService) {
  }

  static buildMapContratsMenus(listeAllSitesActifs: SiteDTO[], listeAllContratsMenusActifs: ContratmenuDTO[]): Map<number, ContratmenuDTO[]> {

    let map = new Map<number, ContratmenuDTO[]>();
    listeAllSitesActifs.forEach(siteDTO => {
      let contratmenuDTOArray: ContratmenuDTO[] = FicheidentiteSiteComponent.findContratMenuBySiteId(siteDTO.id, listeAllContratsMenusActifs);
      map.set(siteDTO.id, contratmenuDTOArray);
    });

    return map;
  }

  /**
   * Renvoie un tableau contenant les {@link ContratmenuDTO} issus du tableau arrayContratmenuDTO passé en paramètre
   * et dont le champ siteId est égal au paramètre siteId.
   * @param {number} siteId
   * @param {ContratmenuDTO[]} arrayContratmenuDTO
   * @returns {ContratmenuDTO[]}
   */
  static findContratMenuBySiteId(siteId: number, arrayContratmenuDTO: ContratmenuDTO[]): ContratmenuDTO[] {
    let contratArray = [];
    arrayContratmenuDTO.forEach(contratmenuDTO => {
      if (contratmenuDTO.site.id == siteId) {
        contratArray.push(contratmenuDTO);
      }

    })
    return contratArray;
  }


  static createTreeSite(siteDTO: SiteDTO, selectedContratsMenus: TreeNode[], listeAllContratsMenusActifs: ContratmenuDTO[], selectable: boolean): TreeNode {

    // La racine de l'arbre qui sera renvoyée par la fonction.
    let root: TreeNode = {
      children: []
    };

    // this.selectedContratsMenus = [];
    while (selectedContratsMenus.length > 0) {
      selectedContratsMenus.shift();
    }

    // Récupération de tous les contrats menus actifs.
    let arrayContratsMenus: ContratmenuDTO[] = listeAllContratsMenusActifs;


    // Iteration sur les contrats menus
    arrayContratsMenus.forEach(contratmenuDTO => {

      let childrenTreeNodeContratMenuConvives: TreeNode[] = [];

      // Itération sur les contrats menus convives du contrat menu courant
      contratmenuDTO.contratMenuConvives.forEach(contratMenuConviveDTO => {
        if (siteDTO != null && contratmenuDTO.site.id == siteDTO.id) {

          let treeNodeData: TreeNodeData;

          treeNodeData = new TreeNodeData(contratMenuConviveDTO, "ContratMenuConviveDTO");
          let contratMenuConvive: TreeNode;
          if (contratMenuConviveDTO.modeleNutritionnel != null) {
            contratMenuConvive = FicheidentiteSiteComponent.createTreeNode("(" + contratMenuConviveDTO.id + ")" +
              contratMenuConviveDTO.convive.libelle +
              " - Modèle nutri : " + contratMenuConviveDTO.modeleNutritionnel.libelle +
              " " + contratMenuConviveDTO.site.libelle,
              treeNodeData,
              selectable,
              null);
          } else {
            contratMenuConvive = FicheidentiteSiteComponent.createTreeNode("(" + contratMenuConviveDTO.id + ")" +
              contratMenuConviveDTO.convive.libelle,
              treeNodeData,
              selectable,
              null);
          }

          childrenTreeNodeContratMenuConvives.push(contratMenuConvive);

          if (siteDTO != null && contratmenuDTO.site.id == siteDTO.id) {

            if (selectable) {
              contratMenuConvive.selectable = true;
            }
          } else {
            if (selectable) {
              contratMenuConvive.selectable = false;
            }

          }
        }
      });

      if (siteDTO != null && contratmenuDTO.site.id == siteDTO.id) {
        let treeNodeData: TreeNodeData;
        treeNodeData = new TreeNodeData(contratmenuDTO, "ContratmenuDTO");
        let treeNodeContratMenu: TreeNode = FicheidentiteSiteComponent.createTreeNode("(" + contratmenuDTO.id + ")" + contratmenuDTO.libelle + " - " + contratmenuDTO.code + "(" + contratmenuDTO.site.libelle + ")"
          , treeNodeData, selectable, childrenTreeNodeContratMenuConvives);

        root.children.push(treeNodeContratMenu);
        if (siteDTO != null && contratmenuDTO.site.id == siteDTO.id) {
          if (selectable) {
            treeNodeContratMenu.selectable = true;
          }
        } else {
          if (selectable) {
            treeNodeContratMenu.selectable = false;
          }
        }
      }
    });

    return root;
  }

  help(): DialogMsgSupplier {
    let dms = new DialogMsgSupplier();
    dms.title = `Fiche d’un site`;
    dms.logo = 'fa fa-question-circle  yoni-color';

    let p1: Paragraphe = new Paragraphe();
    p1.title = `Identité d’un <b>site</b>`;
    p1.lines = [
      `Permet de renseigner l’identité d’un <b>site</b> avec 2 notions :`,
      `<ul> <li>un libellé</li> <li>une référence</li> </ul>`,
      `Pour être utilisable, vous devez activer un <b>site</b> `,
    ];

    dms.content = {
      intro: `Un <b>site</b> est un paramètre référentiel que l’on peut assimiler à un sac dans lequel on stockera les données créées par un site à des fins de partage ou pas dans un <b>environnement</b>.`,
      paragraphes: [p1]
    };

    return dms;
  }

  static createTreeNode(label: string, data: TreeNodeData, selectable: boolean, children: TreeNode[]): TreeNode {
    let treeNode: TreeNode = {
      label: label,
      data: data,
      children: children,
      selectable: selectable,
      expanded: true,
      styleClass: "large"
    };
    return treeNode;
  }

  createSubscriptionFicheIdentite(): Subscription {
    let subscription: Subscription = this.subFicheIdentite = this.route.data.subscribe((data: { siteSupplier: SiteSupplier }) => {
      if (undefined == data.siteSupplier.siteDTO) {

        this.utils.setTitle("Fiche d'identité", "");
        this.yoSite = new SiteDTO();
        this.siteReferent = new SiteDTO();
        this.updateData();

      } else {
        this.utils.setTitle("Fiche d'identité", data.siteSupplier.siteDTO.libelle);

        this.yoSite = data.siteSupplier.siteDTO;
        data.siteSupplier.siteDTO = undefined;
      }


      this.listeAllContratsMenusActifs = data.siteSupplier.listeAllContratsMenusActifs;
      this.listeAllContratsMenusActifs = _sortBy(this.listeAllContratsMenusActifs, "libelle");

      // Cette liste de Site devant servir à choisir le Site référent de this.yoSite, this.yoSite ne doit
      // pas faire partie de cette liste.
      this.listeAllSitesActifsWithContratMenu = data.siteSupplier.listeAllSitesActifsWithContratMenu.filter(site =>
        site.id != this.yoSite.id
      );
      this.mapContratsMenus = FicheidentiteSiteComponent.buildMapContratsMenus(this.listeAllSitesActifsWithContratMenu, this.listeAllContratsMenusActifs);
      this.siteReferent = new SiteDTO();
      this.isDisplayPanelSiteModele = false;

      this.updateData();

    });

    return subscription;


  }

  ngOnInit() {
    this.siteReferent = new SiteDTO();
    this.subFicheIdentite = this.createSubscriptionFicheIdentite();
    this.initialisationFormControls();
    this.siteService.siteSubjectToCreate$.subscribe(() => {
      this.yoSite = new SiteDTO();
      this.siteReferent = new SiteDTO();

      this.updateData();
    });
  }

  /**
   * Initialisation des contrôles de la form
   */
  initialisationFormControls(): void {
    // Initialisation des champs Site
    this.libelleCtrl = this.fb.control('', [Validators.required, Validators.minLength(3), Validators.maxLength(45)]);
    this.referenceCtrl = this.fb.control('', [Validators.required, Validators.minLength(3), Validators.maxLength(10)]);
    this.actifCtrl = this.fb.control('');

    // Initialisation des champs Adresse
    this.adresse1Ctrl = this.fb.control('', [Validators.maxLength(100)]);
    this.adresse2Ctrl = this.fb.control('', [Validators.maxLength(100)]);
    this.adresse3Ctrl = this.fb.control('', [Validators.maxLength(100)]);

    // Le téléphone n'est pas un champ requis.
    this.telephoneCtrl = this.fb.control('', [Validators.maxLength(20)]);
    this.faxCtrl = this.fb.control('', [Validators.maxLength(20)]);

    this.codePostalCtrl = this.fb.control('', [Validators.maxLength(5)]);
    this.villeCtrl = this.fb.control('', [Validators.maxLength(50)]);
    this.emailCtrl = this.fb.control('', [Validators.maxLength(255)]);

    // Initialisation de la form
    this.formGroupCtrl = this.fb.group({
      siteGroup: this.fb.group({
        libelle: this.libelleCtrl,
        reference: this.referenceCtrl,
        actif: this.actifCtrl
      }),
      adressGroup: this.fb.group({
        adresse1: this.adresse1Ctrl,
        adresse2: this.adresse2Ctrl,
        adresse3: this.adresse3Ctrl,

        telephone: this.telephoneCtrl,
        fax: this.faxCtrl,

        codePostal: this.codePostalCtrl,
        ville: this.villeCtrl,
        email: this.emailCtrl
      }),
      siteReferent: this.siteReferentCtrl
    });
  }

  save(): boolean {
    this.siteService.save(this.yoSite)
      .subscribe(data => {
          let result: ResponseWrapper<boolean> = data;
          if (result.inError) {
            this.yoSite = result.one;
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Sauvegarde impossible : ${result.resultMessage}`);
          } else {
            this.yoSite = result.one;
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Le site ${this.yoSite.libelle} a été enregistré avec succès`);
            this.formGroupCtrl.markAsPristine();
            this.siteResolverService.siteAnnounceSource.next(this.yoSite);
            this.utils.sidenav = false;
          }
        },
        err => this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Impossible d'enregistrer le site ${this.yoSite.libelle}`));
    return true;
  }

  confirmDelete() {
  }

  /**
   * Suppression du site passé en paramètre.
   * @param {SiteDTO} siteDTO
   */
  delete(siteDTO: SiteDTO) {
    this.subDeleteSite = this.siteService.deleteSite(siteDTO);
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subFicheIdentite);
    this.utils.unsubscribe(this.subDeleteSite);
  }

  /**
   *
   */
  updateData() {
    if (this.mapContratsMenus != undefined) {
      let arrayContratsMenus: ContratmenuDTO[] = this.mapContratsMenus.get(this.yoSite.id);
      this.selectedContratsMenus = [];
      this.rootContratsMenus = FicheidentiteSiteComponent.createTreeSite(this.yoSite, this.selectedContratsMenus, this.listeAllContratsMenusActifs, true);
      this.rootContratsMenusRererent = FicheidentiteSiteComponent.createTreeSite(this.siteReferent, this.selectedContratsMenusReferent, this.listeAllContratsMenusActifs, true);
    }
  }

  /**
   * Renvoie la différence entre les 2 listes (i.e. le tableau des éléments appartenant au preier tableau mais pas au second.
   * @param {ContratMenuConviveDTO[]} oldArray
   * @param {ContratMenuConviveDTO[]} newArray
   * @returns {ContratMenuConviveDTO[]}
   */
  diff(oldArray: ContratMenuConviveDTO[], newArray: ContratMenuConviveDTO[]): ContratMenuConviveDTO[] {
    let array: ContratMenuConviveDTO[] = [];
    if (oldArray != null) {
      oldArray.forEach(contratMenuConviveDTO => {
        let contratMenuConvive: ContratMenuConviveDTO = this.findContratsMenusConvivesDTOByIdFromArrayContratMenuConviveDTO(contratMenuConviveDTO.id, newArray);
        if (contratMenuConvive == null) {
          array.push(contratMenuConviveDTO);
        }
      })
    }
    return array;
  }

  findContratsMenusConvivesDTOByIdFromArrayContratMenuConviveDTO(id: number, arrayContratMenuConviveDTO: ContratMenuConviveDTO[]): ContratMenuConviveDTO {

    for (let contratMenuConviveDTO of arrayContratMenuConviveDTO) {
      if (contratMenuConviveDTO.id == id) {
        return contratMenuConviveDTO;
      }
    }
    return null;
  }
}
