import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {UtilisateurDTO} from '../../../core/dtos/utilisateur-dto';
import {MenuItem} from 'primeng/api';
import {UtilsService} from '../../../core/utils/utils.service';
import {FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {UtilisateurResolverService, UtilisateurSupplier} from '../../utilisateur-resolver.service';
import {UtilisateurService} from '../../../core/utilisateur.service';
import {ResponseWrapper} from '../../../core/suppliers/wrappers/response-wrapper';
import {MSG_KEY, MSG_SEVERITY} from '../../../core/constants';
import {EnvironnementDTO} from '../../../core/dtos/environnement-dto';
import {BaseComponent} from '../../../base-component';
import {cloneDeep as _cloneDeep} from 'lodash'
import {ProfilDTO} from '../../../core/dtos/profil-dto';
import {EnvironnementUtilisateurDTO} from '../../../core/dtos/environnement-utilisateur-dto';
import {RoleDTO} from "../../../core/dtos/role-dto";
import {Environnement_PlcDTO} from "../../../core/dtos/administration/environnement-plc-dto";
import {ToastService} from "../../../core/services/technique/toast.service";

export const LONGUEUR_MIN_NOM: number = 3;
export const LONGUEUR_MAX_NOM: number = 100;
export const LONGUEUR_MIN_PRENOM: number = 3;
export const LONGUEUR_MAX_PRENOM: number = 100;
export const LONGUEUR_MIN_CODE: number = 3;
export const LONGUEUR_MAX_CODE: number = 10;
export const LONGUEUR_MIN_LOGIN: number = 3;
export const LONGUEUR_MAX_LOGIN: number = 15;

export enum TAB_FICHE_UTILISATEUR {
  profil ,
  environnement ,
  envPlc,
}

@Component({
  selector: 'yo-ficheidentite-utilisateur',
  templateUrl: './ficheidentite-utilisateur.component.html',
  styleUrls: ['./ficheidentite-utilisateur.component.scss']
})
export class FicheidentiteUtilisateurComponent extends BaseComponent implements OnInit, AfterViewInit {

  TAB_FICHE_UTILISATEUR =TAB_FICHE_UTILISATEUR;

  envPointDeLivraison: Environnement_PlcDTO[]= [];
  envPointDeLivraisonSelected: Environnement_PlcDTO;

  // Subscriptions
  subFicheIdentite: Subscription;
  subDeleteUtilisateur: Subscription;
  yoUtilisateur: UtilisateurDTO;


  environnementList: EnvironnementDTO[] = [];
  environmentsSelected: EnvironnementDTO[] = [];

  arrayAllProfilDTO: ProfilDTO[] = [];

  // Profil sélectionné
  selectedProfil: ProfilDTO;

  // La form englobante
  formGroupCtrl: FormGroup;

  // Champs Utilisateur
  nomCtrl: FormControl;
  prenomCtrl: FormControl;
  emailCtrl: FormControl;
  loginCtrl: FormControl;
  motDePasseCtrl: FormControl;
  motDePasseConfirmationCtrl: FormControl;
  actifCtrl: FormControl;
  codeCtrl: FormControl;


  motDePasse: string = '';
  motDePasseConfirmation: string = '';

  // Sauvegarde du message
  messageLoadingService: string;

  tabMenuUser: MenuItem[] = [];
  tabVisibility: number;

  constructor(public utils: UtilsService,
              private fb: FormBuilder,
              private route: ActivatedRoute,
              private utilisateurService: UtilisateurService,
              private utilisateurResolverService: UtilisateurResolverService,
              private cdr: ChangeDetectorRef,
              private toastSvc: ToastService) {
    super();
  }

  ngOnInit() {
    this.tabVisibility = TAB_FICHE_UTILISATEUR.profil;
    this.initFicheIdentite();
    // Abonnement
    this.utilisateurService.utilisateurSubjectToCreate$.subscribe(() => {
      this.yoUtilisateur = new UtilisateurDTO();
      this.yoUtilisateur.nom = "";
      this.selectedProfil = undefined;
      this.environmentsSelected = [];
      this.motDePasse = '';
      this.motDePasseConfirmation = '';
      this.initialisationFormControls();

    });
  }

  initTabMenuUser = () => {
    this.tabMenuUser = [
      {
        label: 'Profils', command: (event) => {
          this.tabVisibility = TAB_FICHE_UTILISATEUR.profil;
        }
      },
      {
        label: 'Environnements', command: (event) => {
          this.tabVisibility = TAB_FICHE_UTILISATEUR.environnement;
        }
      }];

    if(this.checkHasUserPortailRole(this.selectedProfil)){
      this.tabMenuUser.push({
          label: 'Env. point de livraison', command: (event) => {
            this.tabVisibility = TAB_FICHE_UTILISATEUR.envPlc;
          }
        });
    }else{
        this.envPointDeLivraisonSelected = undefined;
    }
  };

  checkHasUserPortailRole = (selectedProfil : ProfilDTO) => {
    let ctrl : boolean = false;
    if(!this.utils.isNullOrEmpty(selectedProfil) && selectedProfil.roleList.length > 0){
      selectedProfil.roleList.forEach( (role:RoleDTO) =>{
        if(role.code === "ROLE_PORTAIL_USER"){
          ctrl = true;
        }
      });
    }
    return ctrl;
  };

  save = () => {

    // profil
    this.yoUtilisateur.profil = _cloneDeep(this.selectedProfil);
    this.yoUtilisateur.environnementUtilisateurDTOS = _cloneDeep(this.getEnvironnementUtilisateurDTOS());

    this.yoUtilisateur.environnementUtilisateurDTOS = this.getEnvironnementUtilisateurDTOS();
    this.yoUtilisateur.profil = this.selectedProfil;
    this.yoUtilisateur.environnementPlc = this.envPointDeLivraisonSelected;
    this.yoUtilisateur.password = this.motDePasse;
    this.yoUtilisateur.confirmationPassword = this.motDePasseConfirmation;
    this.yoUtilisateur.applyPassword = true;

    // Le changement de mot de passe ne doit pas s'opérer pour une mise à jour dans laquelle le champ mot de passe n'a pas
    // été touché :
    if (this.yoUtilisateur.id && !this.yoUtilisateur.password && !this.yoUtilisateur.confirmationPassword) {
      this.yoUtilisateur.applyPassword = false;
    }

    this.utilisateurService.save(this.yoUtilisateur).subscribe(data => {
        let result: ResponseWrapper<UtilisateurDTO> = data;

        if (result.inError) {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Sauvegarde impossible : ${result.resultMessage}`);
        } else {
          this.yoUtilisateur = result.one;
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `La sauvegarde a été réalisée avec succès`);
          this.formGroupCtrl.markAsPristine();
          this.utilisateurResolverService.utilisateurAnnounceSource.next(this.yoUtilisateur);
          this.utils.sidenav = false;
          this.utils.setTitle('Gestion des sites');
        }
      },
      err => this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Impossible d'enregistrer l utilisateur '${this.yoUtilisateur.login}'`));
  };

  getEnvironnementUtilisateurDTOS = () => {
    let resultList: EnvironnementUtilisateurDTO[] = [];
    if (this.environmentsSelected && this.environmentsSelected.length) {
      resultList = this.environmentsSelected.map((env: EnvironnementDTO) => {
        const lightEnv: EnvironnementDTO = new EnvironnementDTO();
        lightEnv.id = env.id; // On n'a pas besoin des autres infos du DTO complètement toxiques, lourds pour le réseau et inutile pour la sauvegarde
        return ({ environnementDTO: lightEnv, idUtilisateur: this.yoUtilisateur.id }) as EnvironnementUtilisateurDTO;
      });
    }
    return resultList;
  };

  /**
   * Suppression de l'UtilisateurDTO passé en paramètre.
   * @param {UtilisateurDTO} utilisateurDTO
   */
  delete = (utilisateurDTO: UtilisateurDTO) => {
    this.subDeleteUtilisateur = this.utilisateurService.deleteUtilisateur(utilisateurDTO);
  };

  /**
   * Création ou mise à jour d'un FormControl
   * @param {FormControl} formControl
   * @param formState
   * @param {ValidatorFn | ValidatorFn[]} validatorOrOpts
   * @returns {FormControl}
   */
  private createOrUpdateFormControl = (formControl: FormControl, formState: any, validatorOrOpts?: ValidatorFn | ValidatorFn[]): FormControl => {

    if (formControl == null) {
      formControl = new FormControl(formState, validatorOrOpts);
    } else {
      formControl.setValue(formState);
    }
    return formControl;
  };

  /**
   * Initialisation des contrôles de la form
   */
  private initialisationFormControls = (): void => {
    this.nomCtrl = this.createOrUpdateFormControl(this.nomCtrl, this.yoUtilisateur.nom,
      [Validators.required, Validators.minLength(3), Validators.maxLength(100)]);
    this.prenomCtrl = this.createOrUpdateFormControl(this.prenomCtrl, this.yoUtilisateur.prenom,
      [Validators.required, Validators.minLength(3), Validators.maxLength(100)]);
    this.emailCtrl = this.createOrUpdateFormControl(this.emailCtrl, this.yoUtilisateur.email,
      [Validators.required, Validators.minLength(3), Validators.maxLength(100)]);
    this.loginCtrl = this.createOrUpdateFormControl(this.loginCtrl, this.yoUtilisateur.login,
      [Validators.required, Validators.minLength(3), Validators.maxLength(100)]);
    this.motDePasseCtrl = this.createOrUpdateFormControl(this.motDePasseCtrl, '');
    this.motDePasseConfirmationCtrl = this.createOrUpdateFormControl(this.motDePasseConfirmationCtrl, '');
    this.actifCtrl = this.createOrUpdateFormControl(this.actifCtrl, this.yoUtilisateur.actif,
      [Validators.required]);
    this.codeCtrl = this.createOrUpdateFormControl(this.codeCtrl, this.yoUtilisateur.code,
      [Validators.required, Validators.minLength(3), Validators.maxLength(100)]);

    // Initialisation de la form
    if (this.formGroupCtrl == null) {
      this.formGroupCtrl = new FormGroup({
        utilisateurGroup: new FormGroup({
          nom: this.nomCtrl,
          prenom: this.prenomCtrl,
          email: this.emailCtrl,
          login: this.loginCtrl,
          motDePasse: this.motDePasseCtrl,
          motDePasseConfirmation: this.motDePasseConfirmationCtrl,
          actif: this.actifCtrl,
          code: this.codeCtrl
        }),
        environnementsGroup: new FormGroup({})
      });
    }
  };

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subFicheIdentite);
    this.utils.unsubscribe(this.subDeleteUtilisateur);
  }

  initYoUtilisateur = (utilisateurDTO: UtilisateurDTO): void => {
    this.yoUtilisateur = utilisateurDTO;
  };

  initFicheIdentite = () => {
    this.subFicheIdentite = this.route.data.subscribe((data: { utilisateurSupplier: UtilisateurSupplier }) => {

      this.environnementList = data.utilisateurSupplier.arrayAllEnvironnementDTO;
      this.envPointDeLivraison = data.utilisateurSupplier.environnementPlcList;
      this.motDePasse = '';
      this.motDePasseConfirmation = '';

      if (!data.utilisateurSupplier.utilisateurDTO) {
        this.utils.setTitle("Fiche d'identité", "");
        this.yoUtilisateur = this.utilisateurService.createEmptyDTO();
      } else {
        this.utils.setTitle("Fiche d'identité", data.utilisateurSupplier.utilisateurDTO.login);

        this.initYoUtilisateur(data.utilisateurSupplier.utilisateurDTO);
        this.initialisationFormControls();

        const envUserList: EnvironnementUtilisateurDTO[] = this.yoUtilisateur.environnementUtilisateurDTOS;
        envUserList.forEach(envUser => {
          const env: EnvironnementDTO = envUser.environnementDTO;
          const envFromList = this.environnementList.find(e => e.id === env.id);
          if (envFromList) {
            this.environmentsSelected.push(envFromList);
          }
        });

        const envPlc: Environnement_PlcDTO = this.yoUtilisateur.environnementPlc;
        const envPlcFromList = this.envPointDeLivraison.find(e => e.id === envPlc.id);
        if (envPlcFromList) {
          this.envPointDeLivraisonSelected = envPlcFromList;
        }



      }
      if (this.utils.isCollectionNullOrEmpty(this.arrayAllProfilDTO)) {
        this.arrayAllProfilDTO = data.utilisateurSupplier.arrayAllProfilDTO;
        this.selectedProfil = this.utils.preSelectSingleList(this.arrayAllProfilDTO,  this.yoUtilisateur.profil);
      }

      this.initialisationFormControls();
      this.initTabMenuUser();
    });
  };

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }


  changeProfil = ($event: any) => {
    this.initTabMenuUser();
  };
}
