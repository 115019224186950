import {Component, OnDestroy, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {UtilsService} from "../../../../core/utils/utils.service";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {RoutemapService} from "../../../../core/services/routemap.service";
import {GenericManageDialogService} from "../../../../core/services/generics/generic-manage-dialog.service";
import {GenericDatagridService} from "../../../../core/services/generics/generic-datagrid.service";
import {DialogMsgSupplier, Paragraphe} from "../../../../core/suppliers/dialog-msg-supplier";
import {SeuilPmsDto} from "../../../../core/dtos/pms/seuil-pms-dto";
import {SeuilPmsSavedSupplier, SeuilPmsService} from "../../../../core/services/pms/seuil-pms.service";
import {InternationalizationService} from "../../../../core/services/i8n/i8n.service";
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {Title} from "@angular/platform-browser";
import {confirm} from "devextreme/ui/dialog";
import {ToastService} from "../../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-pms-seuil-grille',
  templateUrl: './pms-seuil-grille.component.html',
  styleUrls: ['./pms-seuil-grille.component.scss']
})
export class PmsSeuilGrilleComponent implements OnInit, OnDestroy {
  seuilsList: SeuilPmsDto[] = [];

  hasPms = false;

  subSeuilSaved: Subscription;

  allMode: string;
  checkBoxesMode: string;
  selectedRows: number[] = [];

  pathFile: string = HELP_FOLDERS.PMS + '/pms-seuil';

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private routeMapSvc: RoutemapService,
              private genericManageDialogService: GenericManageDialogService,
              public gds: GenericDatagridService,
              private i8nSvc: InternationalizationService,
              private seuilPmsSvc: SeuilPmsService,
              private title: Title,
              private toastSvc: ToastService) {}

  ngOnInit(): void {
    this.initHasPms();
    this.initSeuilsPms();
    this.seuilSavedSubscription();
    this.initProperty();
  }

  initProperty = (): void => {
    this.allMode = 'allPages';
    this.checkBoxesMode = 'always';
    this.title.setTitle(this.getTitle());
  };

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subSeuilSaved);
  }

  getTitle = (): string => "GESTION DES SEUILS";

  canEdit = (): boolean => this.hasPms && this.auth2Svc.hasSitesLocaux();

  initSeuilsPms = (): void => {
    this.seuilPmsSvc.getAll()
      .subscribe(response => {
        this.seuilsList = response.resultList;
      });
  };

  initHasPms = (): void => {
    this.auth2Svc.hasPms$.subscribe(response => this.hasPms = response);
  };

  seuilSavedSubscription = (): void => {
    this.subSeuilSaved = this.seuilPmsSvc.seuilSaved$
      .subscribe((response: SeuilPmsSavedSupplier) => {
        if (!response.isUpdate) {
          this.seuilsList.push(response.seuil);
        }
      });
  };

  openSeuil = (u: SeuilPmsDto): void => {
    this.seuilPmsSvc.announceOpenDialog(u);
  };

  deleteValues = async (): Promise<void> => {
    const result = confirm('Êtes-vous sûr de vouloir supprimer le(s) élément(s) sélectionnés ?', 'Confirmation');
    const isDeleted: boolean = await result;
    if (isDeleted) {
      const idsToDelete: number[] = this.selectedRows
        .map((idSelected: number) => this.seuilsList.find(s => s.id === idSelected))
        .map((seuil: SeuilPmsDto) => seuil.id);
      this.seuilPmsSvc.deleteByIds(idsToDelete)
        .subscribe(o => {
          this.seuilsList = this.utils.handleIsDeletableResultWithNum(o.one, idsToDelete, this.seuilsList);
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `La suppression a été réalisée avec succès`);
        });
    }
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Les seuils (Plan de Mesure Sanitaire)`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
    ];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };
}
