<p-toolbar styleClass="p-mb-4">
  <ng-template pTemplate="left">
    <div class="d-flex flex-rowa align-items-center">
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <div class="mg-l-5 mg-r-5">
        <button
          pButton
          class="mg-r-10 cursor"
          icon="fa fa-plus"
          label="CRÉER"
          (click)="createRule()"
          [disabled]="!canEdit()"
          pTooltip="Créer une règle de regroupement">
        </button>
        <span *ngIf="prestations && prestations.length === 0">
          <i class="fas fa-exclamation-triangle danger-color space"
             pTooltip="Veuillez configurer le point de livraison client actuel à un menu (Offre alimentaires / Prestation / Points de livraison) afin de pouvoir récupérer les prestations et les régimes nécessaires pour la création de règles de regroupement">
          </i>
        </span>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="right">
    <button
      pButton
      type="button"
      icon="fas fa-trash"
      (click)="deleteValues()"
      [disabled]="!canEdit() || !selectedRows.length"
      pTooltip="Supprimer le(s) règle(s) sélectionnée(s)">
    </button>
  </ng-template>
</p-toolbar>

<dx-data-grid
  [dataSource]="rules"
  [allowColumnResizing]="true"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(282)"
  width="100%"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [(selectedRowKeys)]="selectedRows"
  (onCellClick)="onCellClick($event)"
  (onCellPrepared)="onCellPrepared($event)"
  (onRowPrepared)="onRowPrepared($event)"
  #grid>
  <dxo-selection
    [selectAllMode]="allMode"
    [showCheckBoxesMode]="checkBoxesMode"
    mode="multiple"
  ></dxo-selection>
  <dxi-column caption="Actions" cellTemplate="actionsCellTemplate" [width]="120" [fixed]="true" fixedPosition="left"></dxi-column>
  <dxi-column *ngFor="let prestation of prestations"
              [width]="300"
              dataField="libelle"
              [caption]="'Prestation ' + prestation.libelle">
    <dxi-column *ngFor="let regime of prestation.regimes"
                dataField="libelle"
                cellTemplate="regimeCellTemplate"
                [name]="prestation"
                [caption]="'Régime ' + regime.libelle">
    </dxi-column>
  </dxi-column>

  <div *dxTemplate="let cell of 'actionsCellTemplate'" >
    <div class="text-center">
      <button
        pButton
        type="button"
        icon="fas fa-toggle-on"
        class="mg-r-10 cursor"
        (click)="toggleRow(cell.data.id)"
        [disabled]="!canEdit()"
        pTooltip="Activer/Désactiver tous les régimes">
      </button>
    </div>
  </div>
  <div
       [title]="getToolTipPrestation(cell)"
       *dxTemplate="let cell of 'regimeCellTemplate'">
    <span [title]="getToolTipPrestation(cell)">&nbsp;</span>
  </div>

</dx-data-grid>

<div class="d-flex mg-t-10">

  <div class="mr-auto ">
    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
    <span class="mg-r-5 font-12">N'oubliez pas d'enregistrer votre travail</span>
  </div>

  <div id="ctas">
    <yo-button-save
      [yoDisabled]="!canEdit"
      (onClick)="save()"
      yoType="submit">
    </yo-button-save>
  </div>
</div>
