import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {GestionProcessusRoutingModule} from './gestion-processus-routing.module';
import {SharedModule} from '../shared/shared.module';
import {GpRootComponent} from './gp-root/gp-root.component';
import {InitProcessusComponent} from './init-processus/init-processus.component';
import {SelectionMenusComponent} from './selection-menus/selection-menus.component';
import {ProcessusComponent} from './processus/processus.component';
import {ButtonNextStepsComponent} from './button-next-steps/button-next-steps.component';
import {SelectionPlcsComponent} from './selection-plcs/selection-plcs.component';
import {ButtonCurrentStepComponent} from './button-current-step/button-current-step.component';
import {CoreModule} from '../core/core.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';


import {TooltipModule} from 'primeng/tooltip';
import {CardModule} from 'primeng/card';
import {TableModule} from 'primeng/table';
import {CheckboxModule} from 'primeng/checkbox';
import {PanelModule} from 'primeng/panel';
import {ListboxModule} from 'primeng/listbox';
import {TabMenuModule} from 'primeng/tabmenu';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {MenuModule} from 'primeng/menu';
import {DropdownModule} from 'primeng/dropdown';
import {RadioButtonModule} from 'primeng/radiobutton';
import {SelectButtonModule} from 'primeng/selectbutton';
import {MultiSelectModule} from 'primeng/multiselect';
import {SlideMenuModule} from 'primeng/slidemenu';
import {StepsModule} from 'primeng/steps';


@NgModule({
  imports: [
    CommonModule,
    GestionProcessusRoutingModule,
    SharedModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,

    TooltipModule,
    CardModule,
    TableModule,
    CheckboxModule,
    PanelModule,
    ListboxModule,
    TabMenuModule,
    DialogModule,
    ButtonModule,
    CalendarModule,
    MenuModule,
    DropdownModule,
    RadioButtonModule,
    SelectButtonModule,
    MultiSelectModule,
    SlideMenuModule,
    StepsModule,
    ListboxModule,
  ],
  declarations: [
    GpRootComponent,
    InitProcessusComponent,
    SelectionMenusComponent,
    ProcessusComponent,
    ButtonNextStepsComponent,
    SelectionPlcsComponent,
    ButtonCurrentStepComponent
  ],
  exports: [
    InitProcessusComponent,
    ButtonNextStepsComponent,
    ButtonCurrentStepComponent
  ]
})
export class GestionProcessusModule { }
