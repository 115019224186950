import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {GestionPlcRoutingModule} from './gestion-plc-routing.module';
import {SharedModule} from '../shared/shared.module';
import {GplcRootComponent} from './gplc-root/gplc-root.component';
import {MenuPlcComponent} from './menu-plc/menu-plc.component';
import {SaisieEffectifsComponent} from './menu-plc/saisie-effectifs/saisie-effectifs.component';
import {PrestationsSemaineComponent} from './menu-plc/prestations-semaine/prestations-semaine.component';
import {RepasComponent} from './menu-plc/repas/repas.component';
import {GestionmenusModule} from '../gestion-menus/gestionmenus.module';
import {PrintEffectifComponent} from './menu-plc/print-effectif/print-effectif.component';
import {PrintEffectifService} from '../core/services/gestion-plc/print-effectif.service';
import {CoreModule} from '../core/core.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PrintCoutRevientPlatComponent} from './menu-plc/print-cout-revient-plat/print-cout-revient-plat.component';
import {PrintCoutRevientPlatService} from '../core/services/gestion-plc/print-cout-revient-plat.service';
import {TooltipModule} from 'primeng/tooltip';
import {TableModule} from 'primeng/table';
import {CheckboxModule} from 'primeng/checkbox';
import {CardModule} from 'primeng/card';
import {PanelModule} from 'primeng/panel';
import {ListboxModule} from 'primeng/listbox';
import {TabMenuModule} from 'primeng/tabmenu';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {MenuModule} from 'primeng/menu';
import {DropdownModule} from 'primeng/dropdown';
import {RadioButtonModule} from 'primeng/radiobutton';
import {StepsModule} from 'primeng/steps';
import {SelectButtonModule} from 'primeng/selectbutton';
import {MultiSelectModule} from 'primeng/multiselect';
import {ToolbarModule} from "primeng/toolbar";
import {RepasPlcComponent} from './menu-plc/prestations-semaine/repas-plc/repas-plc.component';
import {AccordionModule} from "primeng/accordion";
import {PortailPlcComponent} from "./portail/portail-plc.component";
import {GenericPrestationsSemaineComponent} from "./menu-plc/generic-prestations-semaine/generic-prestations-semaine.component";
import {GenericRepasPlcComponent} from "./menu-plc/generic-prestations-semaine/generic-repas-plc/generic-repas-plc.component";
import {GenericRepasComponent} from "./menu-plc/generic-prestations-semaine/generic-repas/generic-repas.component";
import {DxPopupModule} from "devextreme-angular";

@NgModule({
    imports: [
        CommonModule,
        GestionPlcRoutingModule,
        GestionmenusModule,
        SharedModule,
        CoreModule,
        FormsModule,
        ReactiveFormsModule,

        TooltipModule,
        CardModule,
        TableModule,
        CheckboxModule,
        PanelModule,
        ListboxModule,
        TabMenuModule,
        DialogModule,
        ButtonModule,
        CalendarModule,
        MenuModule,
        DropdownModule,
        RadioButtonModule,
        StepsModule,
        SelectButtonModule,
        MultiSelectModule,
        ToolbarModule,
        AccordionModule,
        DxPopupModule

    ],
  declarations: [
    GplcRootComponent,
    MenuPlcComponent,
    SaisieEffectifsComponent,
    PrestationsSemaineComponent,
    RepasComponent,
    PrintEffectifComponent,
    PrintCoutRevientPlatComponent,
    RepasPlcComponent,
    PortailPlcComponent,
    GenericPrestationsSemaineComponent,
    GenericRepasPlcComponent,
    GenericRepasComponent
  ],
  providers: [
    PrintEffectifService,
    PrintCoutRevientPlatService
  ]
})
export class GestionPlcModule { }
