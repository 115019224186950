import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {GestionStocksRoutingModule} from './gestion-stocks-routing.module';

import {GestionStocksRootComponent} from './gestion-stocks-root.component';
import {StocksComponent} from './stocks/stocks.component';
import {InventaireComponent} from './stocks/inventaire/inventaire.component';
import {SharedModule} from '../shared/shared.module';
import {SearchPanelComponent} from './stocks/search-panel/search-panel.component';
import {MatieresPremieresComponent} from './stocks/matieres-premieres/matieres-premieres.component';
import {LotsComponent} from './stocks/matieres-premieres/lots/lots.component';
import {DialogPerimesComponent} from './stocks/matieres-premieres/dialog-perimes/dialog-perimes.component';
import {MouvementsComponent} from './mouvements/mouvements.component';
import {DialogSortieComponent} from './stocks/matieres-premieres/dialog-sortie/dialog-sortie.component';
import {DialogModificationMouvementComponent} from './mouvements/dialog-modification-mouvement/dialog-modification-mouvement.component';
import {SearchPanelMouvementsComponent} from './mouvements/search-panel-mouvements/search-panel-mouvements.component';
import {GrilleMouvementsComponent} from './mouvements/grille-mouvements/grille-mouvements.component';
import {DialogPrintInventaireComponent} from './stocks/inventaire/dialog-print-inventaire/dialog-print-inventaire.component';
import {PrintInventaireService} from '../core/services/gestion-stock/print-inventaire.service';
import {DialogConfirmSaveComponent} from './stocks/inventaire/dialog-confirm-save/dialog-confirm-save.component';
import {DialogModificationLotsComponent} from './stocks/matieres-premieres/lots/dialog-modification-lots/dialog-modification-lots.component';
import {CoreModule} from '../core/core.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TooltipModule} from 'primeng/tooltip';
import {CardModule} from 'primeng/card';
import {TableModule} from 'primeng/table';
import {CheckboxModule} from 'primeng/checkbox';
import {PanelModule} from 'primeng/panel';
import {ListboxModule} from 'primeng/listbox';
import {TabMenuModule} from 'primeng/tabmenu';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {MenuModule} from 'primeng/menu';
import {DropdownModule} from 'primeng/dropdown';
import {SelectButtonModule} from 'primeng/selectbutton';
import {MultiSelectModule} from 'primeng/multiselect';
import {SlideMenuModule} from 'primeng/slidemenu';
import {StepsModule} from 'primeng/steps';
import {RadioButtonModule} from 'primeng/radiobutton';
import {InputSwitchModule} from 'primeng/inputswitch';
import {ToolbarModule} from 'primeng/toolbar';
import {DialogLotAjoutComponent} from "./stocks/matieres-premieres/dialog-lot-ajout/dialog-lot-ajout.component";
import {DxDataGridModule, DxRadioGroupModule} from "devextreme-angular";
import {DxoPagerModule} from "devextreme-angular/ui/nested";

@NgModule({
  declarations: [
    GestionStocksRootComponent,
    StocksComponent,
    InventaireComponent,
    SearchPanelComponent,
    MatieresPremieresComponent,
    LotsComponent,
    DialogPerimesComponent,
    MouvementsComponent,
    DialogSortieComponent,
    DialogModificationMouvementComponent,
    SearchPanelMouvementsComponent,
    GrilleMouvementsComponent,
    DialogPrintInventaireComponent,
    DialogConfirmSaveComponent,
    DialogModificationLotsComponent,
    DialogLotAjoutComponent
  ],
    imports: [
        CommonModule,
        GestionStocksRoutingModule,
        SharedModule,
        CoreModule,
        FormsModule,
        ReactiveFormsModule,

        TooltipModule,
        CardModule,
        TableModule,
        CheckboxModule,
        PanelModule,
        ListboxModule,
        TabMenuModule,
        DialogModule,
        ButtonModule,
        CalendarModule,
        MenuModule,
        DropdownModule,
        SelectButtonModule,
        MultiSelectModule,
        SlideMenuModule,
        StepsModule,
        RadioButtonModule,
        InputSwitchModule,
        ToolbarModule,
        DxDataGridModule,
        DxoPagerModule,
        DxRadioGroupModule,
    ],
  providers: [
    PrintInventaireService
  ]
})
export class GestionStocksModule { }
