import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {combineLatest, Observable} from 'rxjs';
import {GestionLotMarcheService} from '../../../../../core/services/gestion-marche/gestion-lot-marche.service';
import {LotMarcheDTO} from '../../../../../core/dtos/gerstion-marche/lot-marche-dto';
import {CatalogueAchatDTO} from '../../../../../core/dtos/catalogue-achat-dto';
import {UnitedemesureDTO} from "../../../../../core/dtos/unitedemesure-dto";
import {GenericDatagridService} from "../../../../../core/services/generics/generic-datagrid.service";
import {UnitesDeMesuresService} from "../../../../../core/services/entities/unites-de-mesures.service";
import {CataloguesAchatsService} from "../../../../../core/services/entities/catalogues-achats.service";

@Injectable({
  providedIn: 'root'
})
export class ArticlesLotMarcheResolverService implements Resolve<ArticlesLotMarcheSupplier> {

  constructor(private gestionLotMarcheSvc: GestionLotMarcheService,
              private catalogueAchatSvc: CataloguesAchatsService,
              private gds: GenericDatagridService,
              private unitesDeMesuresSvc: UnitesDeMesuresService) {
  }

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ArticlesLotMarcheSupplier> | Promise<ArticlesLotMarcheSupplier> | ArticlesLotMarcheSupplier => {

    let articleLmSupplier: ArticlesLotMarcheSupplier = new ArticlesLotMarcheSupplier();
    let idLotMarche: number = +route.paramMap.get('idLotMarche');

    if (idLotMarche > 0) {
      const lotMarche$ = this.gestionLotMarcheSvc.getById(idLotMarche);
      const catalogueAchat$ = this.catalogueAchatSvc.getCatalogueAchatByFilters({ idLotMarche, searchArticlesToProposeForLotMarket: true });
      const caOwnedByLotMarche$ = this.catalogueAchatSvc.getCatalogueAchatByFilters({ idLotMarche });
      const uniteDeMesureList$ = this.gds.getAll(this.unitesDeMesuresSvc.getEntityName());
      const selectedDenree$ = this.gestionLotMarcheSvc.getProdDecliByLmId(idLotMarche);

      const all$ = combineLatest([lotMarche$, catalogueAchat$, caOwnedByLotMarche$, uniteDeMesureList$, selectedDenree$]);
      return all$.toPromise().then(data => {
        articleLmSupplier.lotMarche = data[0].one;
        articleLmSupplier.catalogueAchatList  = data[1].resultList;
        articleLmSupplier.caOwnedByLotMarche = data[2].resultList;
        articleLmSupplier.uniteDeMesureList = data[3].resultList;

        return articleLmSupplier;
      });
    }
  };
}

export class ArticlesLotMarcheSupplier {
  lotMarche: LotMarcheDTO;
  catalogueAchatList : CatalogueAchatDTO[] = [];
  caOwnedByLotMarche : CatalogueAchatDTO[] = [];
  uniteDeMesureList : UnitedemesureDTO[] = [];
}

