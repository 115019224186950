import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {Subscription} from "rxjs";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UtilsService} from "../../../../core/utils/utils.service";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {RoutemapService} from "../../../../core/services/routemap.service";
import {GenericDatagridService} from "../../../../core/services/generics/generic-datagrid.service";
import {MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {DialogMsgSupplier, Paragraphe} from "../../../../core/suppliers/dialog-msg-supplier";
import {SiteDTO} from "../../../../core/dtos/site-dto";
import {FormFieldFileSupplier} from "../../../../core/suppliers/form-field-file-supplier";
import {SignatureUtilisateurPmsDto} from "../../../../core/dtos/pms/signature-utilisateur-pms-dto";
import {SignatureUtilisateurPmsService} from "../../../../core/services/pms/signature-utilisateur-pms.service";
import {TypeUtilisateurPmsService} from "../../../../core/services/pms/type-utilisateur-pms.service";
import {TypeUtilisateurPmsDto} from "../../../../core/dtos/pms/type-utilisateur-pms-dto";
import {DxTreeViewComponent} from "devextreme-angular";
import DataSource from "devextreme/data/data_source";
import {ToastService} from "../../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-pms-signature-utilisateur-dialog',
  templateUrl: './pms-signature-utilisateur-dialog.component.html',
  styleUrls: ['./pms-signature-utilisateur-dialog.component.scss']
})
export class PmsSignatureUtilisateurDialogComponent implements OnInit, OnDestroy {
  hasPms = false;

  displayDialog = false;

  subOpenDialog: Subscription;

  signatureUtilisateur: SignatureUtilisateurPmsDto;

  /**
   * Liste des types utilisateur déjà existants
   */
  typesUtilisateur: TypeUtilisateurPmsDto[];
  /**
   * Liste des types utilisateur déjà sélectionnés en cas de modification
   */
  typesUtilisateurSelected: TypeUtilisateurPmsDto[] = [];

  forUpdate: boolean = false;

  form: FormGroup = new FormGroup({});
  previewFile: File;
  docError = false;
  field: FormFieldFileSupplier;

  sitesList: SiteDTO[];
  siteSelected: SiteDTO;

  @ViewChild(DxTreeViewComponent, { static: false }) treeView;

  treeDataSource: any;

  treeBoxValue: number[];

  dialogTitle = 'Modification d\'une signature utilisateur';

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private routeMapSvc: RoutemapService,
              public gds: GenericDatagridService,
              private signatureUtilisateurPmsService: SignatureUtilisateurPmsService,
              private typeUtilisateurPmsService: TypeUtilisateurPmsService,
              private toastSvc: ToastService) {
  }

  ngOnInit(): void {
    this.initField();
    this.initForm();
    this.initHasPms();
    this.findAllLocalSites();
    this.openDialogVarianteSubscription();
    if(document.getElementById('previewImage')) {
      document.getElementById('previewImage').setAttribute('src', null);
    }
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subOpenDialog);
  }

  findAllLocalSites = (): void => {
    this.sitesList = this.auth2Svc.utilisateur.siteListLocaux;
  }

  openDialogVarianteSubscription = (): void => {
    this.subOpenDialog = this.signatureUtilisateurPmsService.openDialog$
      .subscribe((s: SignatureUtilisateurPmsDto) => {
        this.displayDialog = true;
        this.previewFile = null;
        if (!s) {
          this.forUpdate = false;
          this.signatureUtilisateur = new SignatureUtilisateurPmsDto();
          this.signatureUtilisateur.id = null;
          this.dialogTitle = 'Création d\'une signature utilisateur';
        } else {
          this.forUpdate = true;
          this.signatureUtilisateur = s;
          this.siteSelected = s.site;
          this.typesUtilisateurSelected = s.typeUtilisateurPmsList;
          this.dialogTitle = 'Modification d\'une signature utilisateur';
        }
        this.initTypesUtilisateurPms();
        this.initForm();
      });
  };

  initHasPms = (): void => {
    this.auth2Svc.hasPms$.subscribe(response => this.hasPms = response);
  };

  /**
   * Fonction pour initialiser tous les éléments nécessaires pour enregistrer et/ou afficher les types
   * d'utilisateur appartenants à l'utilisateur
   */
  initTypesUtilisateurPms = (): void => {
    this.typeUtilisateurPmsService.getAll()
      .subscribe(response => {
        this.typesUtilisateur = response.resultList;
        this.treeDataSource = new DataSource({
          store: {
            type: 'array',
            key: 'id',
            data: response.resultList
          }
        });
        if(this.typesUtilisateurSelected) this.treeBoxValue = this.typesUtilisateurSelected.map(t => t.id);
      });
  };

  initField = (): void => {
    /**
     * initialisation de la variable a utilisé pour l'affichage de l'image avec yo-img-entity
     */
    this.field = new FormFieldFileSupplier({
      key: 'file',
      label: 'Logo',
      readonly: !this.canModify(),
      value: '',
      entityName: this.signatureUtilisateurPmsService.getEntityName().toLowerCase(),
      required: false,
      refresh: new Date().getTime(),
      ordre: 1
    });
  };

  closeDialog = (): void => {
    this.displayDialog = false;
    this.siteSelected = null;
    this.treeBoxValue = null;
    this.typesUtilisateurSelected = null;
  };

  canModify = (): boolean => this.signatureUtilisateur && this.hasPms;

  /**
   * fonction qui sauvegarde la signature utilisateur pms créé ou modifié
   */
  save = async (): Promise<void> => {
    if (this.form.valid) {
      this.signatureUtilisateur.nom = this.form.controls['nom'].value;
      this.signatureUtilisateur.prenom = this.form.controls['prenom'].value;
      this.signatureUtilisateur.image = this.form.controls['field'].value;

      this.signatureUtilisateur.typeUtilisateurPmsList = [];
      if (this.treeBoxValue)
        this.signatureUtilisateur.typeUtilisateurPmsList = this.typesUtilisateur.filter(item => this.treeBoxValue.find(t => t === item.id));

      this.signatureUtilisateur.site = this.sitesList.find(site => site.id === this.form.controls['site'].value);

      this.signatureUtilisateurPmsService.save(this.signatureUtilisateur, this.signatureUtilisateur.image)
        .subscribe(response => {
          this.signatureUtilisateurPmsService.announceSaved(response.one, this.signatureUtilisateur.id !== undefined && this.signatureUtilisateur.id !== null && this.signatureUtilisateur.id !== 0);
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `La signature utilisateur a été enregistrée avec succès`);
          this.closeDialog();
          this.initForm();
        });
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez compléter le formulaire`);
    }
  };

  /**
   * Méthode qui permet d'initialiser le formulaire pour la création ou la modification d'une signature utilisateur pms
   */
  initForm = (): void => {
    this.form = new FormGroup({
      nom: new FormControl(this.forUpdate ? this.signatureUtilisateur.nom : '', [Validators.required, Validators.minLength(1), Validators.maxLength(25)]),
      prenom: new FormControl(this.forUpdate ? this.signatureUtilisateur.prenom : '', [Validators.required, Validators.minLength(1), Validators.maxLength(25)]),
      field: new FormControl(this.signatureUtilisateur ? this.signatureUtilisateur.image : ''),
      list: new FormControl(this.forUpdate ? this.signatureUtilisateur.typeUtilisateurPmsList : []),
      site: new FormControl(this.forUpdate ? this.signatureUtilisateur.site : null, [Validators.required])
    });
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Signature Utilisateur (Plan de Mesure Sanitaire)`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
    ];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };

  /**
   * Fonction qui permet de prévisualiser l'image téléchargé avant sa sauvegarde
   */
  previewImage = (): void => {
    const reader = new FileReader();
    reader.onload = function (e: any) {
      document.getElementById('previewImage').setAttribute('src', e.target.result);
    }
    reader.readAsDataURL(this.previewFile);
  }

  /**
   * Fonction qui récupère l'image puis qui appelle la fonction previewImage
   * pour prévisualiser celle-ci avant de l'enregistrer
   * @param event
   */
  onFileChange = (event: any): void => {
    if (event.target.files && event.target.files.length) {
      this.previewFile = event.target.files[0];
      if (!this.utils.isNullOrEmpty(this.previewFile)) {
        // previsualiser l'image avant de l'enregistrer
        this.previewImage();
        this.form.get("field").setValue(this.previewFile);
      }
    }
  }

  onDropDownBoxValueChanged = () => {
    this.updateSelection(this.treeView && this.treeView.instance);
  };

  onTreeViewReady = e => {
    this.updateSelection(e.component);
  };

  updateSelection = treeView => {
    if (!treeView) return;

    if (!this.treeBoxValue) {
      treeView.unselectAll();
    }

    if (this.treeBoxValue) {
      this.treeBoxValue.forEach(((value) => {
        treeView.selectItem(value);
      }));
    }
  };

  onTreeViewSelectionChanged = e => {
    this.treeBoxValue = e.component.getSelectedNodeKeys();
  };
}
