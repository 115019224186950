import {Component, OnDestroy, OnInit} from '@angular/core';
import {CatalogueAchatDTO} from '../../../../../../core/dtos/catalogue-achat-dto';
import {UtilsService} from '../../../../../../core/utils/utils.service';
import {find as _find, remove as _remove} from 'lodash'
import {GestionLotMarcheService} from '../../../../../../core/services/gestion-marche/gestion-lot-marche.service';
import {Subscription} from 'rxjs';
import {MSG_KEY, MSG_SEVERITY} from '../../../../../../core/constants';
import {ToastService} from "../../../../../../core/services/technique/toast.service";


@Component({
  selector: 'yo-search-articles',
  templateUrl: './search-articles.component.html',
  styleUrls: ['./search-articles.component.scss']
})
export class SearchArticlesComponent implements OnInit , OnDestroy{

  displayDialog: boolean = false;

  catalogueAchatList: CatalogueAchatDTO[] = [];
  selectedCatalogueAchat: CatalogueAchatDTO[] = [];
  selectedRowKeysCatalogueAchat: any[] = [];
  subCaListSearchArticles :Subscription;

  constructor(public utils: UtilsService,
              private gestionLotmarcheSvc :GestionLotMarcheService,
              private toastSvc: ToastService){}

  ngOnInit(): void {
   this.iniSearchData();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe( this.subCaListSearchArticles);
  }

  iniSearchData = () => {
    this.subCaListSearchArticles = this.gestionLotmarcheSvc.caListSearchArticles$.subscribe( response => {
      this.displayDialog = true;
      this.catalogueAchatList = response;
      if(!this.catalogueAchatList.length)
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, 'Le contexte défini ne permet pas de voir des articles');
    });
  };

  closeDialog = () => {
    this.displayDialog = false;
    this.clearData();
  };

  clearData = () => {
    this.catalogueAchatList = [];
    this.selectedCatalogueAchat = [];
    this.selectedRowKeysCatalogueAchat = [];
  };

  onRemoveChipCatalogueAchat = (event: any) => {
    _remove(this.selectedCatalogueAchat, (item: CatalogueAchatDTO) => {
      return item.id === event.value.id;
    });
    _remove(this.selectedRowKeysCatalogueAchat, (item) => {
      return item === event.value.id;
    });
  };

  onSelectionChangedCatalogueAchat = (event: any) => {
    this.selectedCatalogueAchat = event.selectedRowsData;
  };

  sendArticlesBasket = () => {
    console.log('this.selectedCatalogueAchat', this.selectedCatalogueAchat);
    this.gestionLotmarcheSvc.announceSelectedCatalogueAchatList(this.selectedCatalogueAchat);
    this.closeDialog();
  };
}
