
<div class="d-flex flex-row">

  <!--  FILTRE-->
  <p-card [style]="{'margin-right': '30px'}">
    <div class="size-card-filter">

      <h3 class="first">Code API</h3>
      <input type="text"
             pInputText
             pTooltip="Référence article interne à l'application" showDelay="500"
             class="width-250"
             (input)="dt.filter($event.target.value,'codeApi', 'contains')"/>

      <h3 class="first">Articles</h3>
      <input type="text"
             pInputText
             class="width-250"
             (input)="dt.filter($event.target.value,'articles', 'contains')"/>


      <h3 class="first">Réference art.</h3>
      <input type="text"
             pInputText
             pTooltip="Référence article du fournisseur" showDelay="500"
             class="width-250"
             (input)="dt.filter($event.target.value,'referenceArticle', 'contains')"/>


      <h3 class="first">Fournisseurs</h3>
<!--      <p-multiSelect [options]="fournisseurs"-->
<!--                     [ngModel]="selectedFournisseurs"-->
<!--                     optionLabel="libelle"-->
<!--                     defaultLabel="Fournisseurs..."-->
<!--                     selectedItemsLabel="{0} fournisseurs"-->
<!--                     (ngModelChange)="dt.filter($event,'fournisseurs','in')"-->
<!--                     [panelStyle]="{width:'250px'}">-->
<!--      </p-multiSelect>-->

      <h3 class="first">Filiales</h3>
      <p-multiSelect [options]="filiales"
                     [ngModel]="selectedFiliales"
                     optionLabel="libelle"
                     defaultLabel="Filiales..."
                     selectedItemsLabel="{0} filiales"
                     (ngModelChange)="dt.filter($event,'filiales','in')"
                     [panelStyle]="{'min-width':'250px', 'max-width':'250px'}">
        <ng-template let-item pTemplate="item">
          {{item.libelle}} ({{item.code}})
        </ng-template>
      </p-multiSelect>

      <h3 class="first">Secteurs</h3>
      <p-multiSelect [options]="secteurs"
                     [ngModel]="selectedSecteurs"
                     optionLabel="libelle"
                     defaultLabel="Secteurs..."
                     selectedItemsLabel="{0} secteurs"
                     (ngModelChange)="dt.filter($event,'secteurs','in')"
                     [panelStyle]="{'min-width':'250px', 'max-width':'250px'}">
      </p-multiSelect>

      <h3 class="first mg-t-10">Prix d'achat entre {{rangePrixAchat[0]  | currency:'EUR':'symbol':'1.0-0'}}
        et {{rangePrixAchat[1]  | currency:'EUR':'symbol':'1.0-0'}}</h3>
      <p-slider [animate]="true"
                [(ngModel)]="rangePrixAchat"
                (onChange)="dt.filter($event,'prix','in')"
                [min]="minPrixAchat"
                [max]="maxPrixAchat" [step]="0.1" [range]="true">
      </p-slider>
    </div>
  </p-card>

  <!--  TABLE  -->
  <div>
    <div class="d-flex justify-content-between mg-b-10">
      <div>
        <button
          pButton
          icon="fas fa-plus"
          class="mg-r-5"
          label="Ajouter"
          tooltipPosition="top"
          pTooltip="Ajouter des articles au panier"
          (click)="addArticle()"
          [disabled]="selectedArticles.length === 0">
        </button>

        <button
          pButton
          icon="fas fa-shopping-basket"
          class="mg-r-5"
          label="Panier"
          tooltipPosition="top"
          pTooltip="Voir la liste des articles présents dans le panier"
          (click)="displayBasket()"
          [disabled]="articlesInBasket.length === 0">
          <yo-badge-notification [itemList]="articlesInBasket"></yo-badge-notification>
          <!--          <span *ngIf="articlesInBasket.length !== 0" class="topbar-badge">{{articlesInBasket.length}}</span>-->
        </button>
      </div>
      <div></div>

    </div>

    <p-table [columns]="cols"
             [value]="articles"
             [(selection)]="selectedArticles"
             [scrollable]="trueBoolean"
             [filterDelay]="1000"
             [scrollHeight]="utils.scrollHeightContent(485)"
             styleClass="p-datatable-sm"
             (onLazyLoad)="loadData($event)"
             [lazy]="trueBoolean"
             [totalRecords]="totalRecords"
             [loading]="loading"
             dataKey="id"
             #dt>

      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <ng-container *ngFor="let col of columns" [ngSwitch]="col.field">
            <col *ngSwitchCase="'selection'" [style.width]="'30px'" [style.text-align]="'left'">
            <col *ngSwitchCase="'codeApi'" [style.width]="'80px'" [style.text-align]="'left'">
            <col *ngSwitchCase="'libelle'" [style.width]="'130px'" [style.text-align]="'left'">
            <col *ngSwitchCase="'referenceArticle'" [style.width]="'80px'" [style.text-align]="'left'">
            <col *ngSwitchCase="'fournisseur'" [style.width]="'100px'" [style.text-align]="'left'">
            <col *ngSwitchCase="'filialeSecteur'" [style.width]="'110px'" [style.text-align]="'left'">
            <col *ngSwitchCase="'prix'" [style.width]="'50px'" [style.text-align]="'right'">
            <col *ngSwitchCase="'uniteFacturation'" [style.width]="'80px'" [style.text-align]="'right'">
            <col *ngSwitchCase="'uniteCommande'" [style.width]="'80px'" [style.text-align]="'right'">
            <col *ngSwitchCase="'uniteDeStockage'" [style.width]="'80px'" [style.text-align]="'right'">
          </ng-container>
        </colgroup>
      </ng-template>

      <!--      HEADERS-->
      <ng-template pTemplate="header" let-columns>
        <tr>
          <ng-container *ngFor="let col of columns">
            <ng-container [ngSwitch]="col.field">

              <ng-container *ngSwitchCase="'selection'">
                <th pTooltip="Sélectionner des commandes">
                </th>
              </ng-container>

              <ng-container *ngSwitchCase="'codeApi'">
                <th [pSortableColumn]="col.field">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
              </ng-container>

              <ng-container *ngSwitchCase="'libelle'">
                <th [pSortableColumn]="col.field">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
              </ng-container>

              <ng-container *ngSwitchCase="'referenceArticle'">
                <th [pSortableColumn]="col.field">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
              </ng-container>

              <ng-container *ngSwitchCase="'fournisseur'">
                <th [pSortableColumn]="col.field">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
              </ng-container>


              <ng-container *ngSwitchCase="'filialeSecteur'">
                <th [pSortableColumn]="col.field">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
              </ng-container>

              <ng-container *ngSwitchCase="'prix'">
                <th [pSortableColumn]="col.field">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
              </ng-container>

              <ng-container *ngSwitchCase="'uniteCommande'">
                <th [pSortableColumn]="col.field">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
              </ng-container>

              <ng-container *ngSwitchCase="'uniteDeStockage'">
                <th [pSortableColumn]="col.field">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
              </ng-container>

              <ng-container *ngSwitchCase="'uniteFacturation'">
                <th [pSortableColumn]="col.field">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
              </ng-container>


            </ng-container>
          </ng-container>
        </tr>
      </ng-template>

      <!--BODY-->
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr style="height:34px">

          <ng-container *ngFor="let col of columns">

            <ng-container [ngSwitch]="col.field">

              <ng-container *ngSwitchCase="'selection'">
                <td style="text-align: center;">
                  <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                </td>
              </ng-container>

              <ng-container *ngSwitchCase="'codeApi'">
                <td style="text-align: left;">
                  {{rowData.codeApi}}
                </td>
              </ng-container>

              <ng-container *ngSwitchCase="'libelle'">
                <td style="text-align: left;">
                  {{rowData.libelle}}
                </td>
              </ng-container>

              <ng-container *ngSwitchCase="'referenceArticle'">
                <td style="text-align: left;">
                  {{rowData.referenceArticle}}
                </td>
              </ng-container>

              <ng-container *ngSwitchCase="'fournisseur'">
                <td style="text-align: left;">
                  {{rowData.fournisseur.libelle}}
                </td>
              </ng-container>

              <ng-container *ngSwitchCase="'filialeSecteur'">
                <td style="text-align: left;">
                  {{rowData.filialeLibelle | uppercase}}
                </td>
              </ng-container>

              <ng-container *ngSwitchCase="'prix'">
                <td style="text-align: left;">
                  {{rowData.prix}}
                </td>
              </ng-container>

              <ng-container *ngSwitchCase="'uniteFacturation'">
                <td style="text-align: left;">
                  1 {{rowData.uniteDeFacturation.abreviation}} = {{rowData.ratioUniteFacturationUniteBase}} KG
                </td>
              </ng-container>

              <ng-container *ngSwitchCase="'uniteCommande'">
                <td style="text-align: left;">
                  1 {{rowData.uniteDeCommande.abreviation}} = {{rowData.ratioUniteCommandeUniteBase}} KG
                </td>
              </ng-container>

              <ng-container *ngSwitchCase="'uniteDeStockage'">
                <td style="text-align: left;">
                  1 {{rowData.uniteDeStockage.abreviation}} = {{rowData.ratioUniteStockageUniteBase}} KG
                </td>
              </ng-container>

            </ng-container>

          </ng-container>

        </tr>
      </ng-template>

      <ng-template pTemplate="summary" let-rowData let-columns="columns">
        <tr style="height:34px">
          <td style="text-align: left;">
            {{utils.getTotalRecordsLabel('article', totalRecords)}}
          </td>
        </tr>
      </ng-template>


    </p-table>
  </div>
</div>


<yo-dialog-panier-articles
  [displayDialog]="displayBasketDialog"
  [articles]="articlesInBasket"
  (affectSucessCallBack)="affectSucessCallBack()"
  (onClose)="onCloseDialog($event)">
</yo-dialog-panier-articles>
