import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ZoneDeStockageDTO} from '../../../core/dtos/zone-de-stockage-dto';
import {UniteDeProductionDTO} from '../../../core/dtos/unite-de-production-dto';
import {Subscription} from 'rxjs';
import {UniteDeProductionSupplier} from '../unite-production-resolver.service';
import {ActivatedRoute} from '@angular/router';
import {GenericDatagridService} from '../../../core/services/generics/generic-datagrid.service';
import {UtilsService} from '../../../core/utils/utils.service';
import {RoutemapService} from '../../../core/services/routemap.service';
import {UnitesDeProductionService} from '../../../core/services/entities/unites-de-production.service';
import {ZonesDeStockageService} from '../../../core/services/entities/zones-de-stockage.service';
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from '../../../core/constants';
import {DialogMsgSupplier, Paragraphe} from '../../../core/suppliers/dialog-msg-supplier';
import {ToastService} from "../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-up-zones-de-stockage',
  templateUrl: './up-zones-de-stockage.component.html',
  styleUrls: ['./up-zones-de-stockage.component.scss']
})
export class UpZonesDeStockageComponent implements OnInit, AfterViewInit {

  subscriptionRoute: Subscription;
  subSidenav: Subscription;

  zoneDeStockageList: ZoneDeStockageDTO[] = [];
  zoneDeStockageSelectedList: ZoneDeStockageDTO[] = [];
  uniteDeProduction: UniteDeProductionDTO;

  pathFile: string = HELP_FOLDERS.UNITE_DE_PRODUCTION + '/unites-production-zones-de-stockage';

  constructor(private route: ActivatedRoute,
              public gds: GenericDatagridService,
              private cd: ChangeDetectorRef,
              public utils: UtilsService,
              private zdsSvc:ZonesDeStockageService,
              private routeMapSvc: RoutemapService,
              public udpSvc: UnitesDeProductionService,
              private toastSvc: ToastService) {}

  ngOnInit() {
    //abonnement à l'annonce de l'ouverture du sidenav, cas où le router n'est pas reloadé, on veut quand meme reinit
    this.subSidenav = this.routeMapSvc.sidenav$.subscribe(sidenav => {
    });

    this.subscriptionRoute = this.route.parent.data
      .subscribe((data: { uniteDeProductionSupplier: UniteDeProductionSupplier }) => {
        this.uniteDeProduction = data.uniteDeProductionSupplier.uniteDeProduction;
        this.zoneDeStockageSelectedList = data.uniteDeProductionSupplier.zonesStockageOfUDP;
        this.zoneDeStockageList = data.uniteDeProductionSupplier.zonesStockageList;
      });
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subscriptionRoute);
    this.utils.unsubscribe(this.subSidenav);
  }


  getHelp(): DialogMsgSupplier {
    let dms = new DialogMsgSupplier();
    dms.title = `Zones de stockage`;

    let p1: Paragraphe = new Paragraphe();
    let p2: Paragraphe = new Paragraphe();
    p1.title = `Les zones de stockage sont les lieux où entreposer les denrées. Il faut donc sélectionner les zones utilisées au sein de votre unité de production.`;
    p2.title = `N'oubliez pas d'enregistrer&nbsp;<i class="fa fa-smile-o yoni-color"></i>.`;

    dms.content = {
      intro: ``,
      paragraphes: [p1,p2]

    };

    return dms;
  }

  /**
   * Sauvegarde des zones de stockage liées à l'unité de production {@link uniteDeProduction}.
   */
  saveZonesDeStockage() {
    this.udpSvc.saveZonesDeStockage(this.uniteDeProduction, this.zoneDeStockageSelectedList).subscribe(response => {
      this.zoneDeStockageSelectedList = response.resultList;
      let messageNotRemoved: string = response.additionalProperties["messageNotRemoved"];
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, `Sauvegarde réussie avec succès`);
      if (messageNotRemoved)
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, messageNotRemoved);
    });
  }

  onChangeZoneDeStockage(event) {
    this.zoneDeStockageSelectedList = event.value?event.value:[];
  }


  tooltipZoneDeStockage(zoneDeStockage: ZoneDeStockageDTO): string {
    let response = "";
    if (!zoneDeStockage.actif) {
      response = "Attention : cette zone de stockage est inactive.";
    }
    return response;
  }
}
