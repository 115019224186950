
<p-toolbar styleClass="p-mb-4">
  <ng-template pTemplate="left">
    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
    <div
      class="mg-r-15"
      [pTooltip]="tooltipBoutonCreer()"
      style="display: inline-block;">
      <button
        pButton
        icon="fa fa-plus"
        label="CRÉER"
        (click)="openCreateDialog()"
        [disabled]="!canCreate()"
        [class.disable]="!canCreate()">
      </button>
    </div>
  </ng-template>

</p-toolbar>

<dx-data-grid
  [dataSource]="viewModelContratList"
  keyExpr="id"
  [height]="utils.scrollHeightContent(237)"
  width="100%"
  [hoverStateEnabled]="true"
  [allowColumnResizing]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  #grid>
    <dxi-column dataField="id" caption="Actions" [width]="100"
                [allowFiltering]="false"
                [allowGrouping]="false"
                cellTemplate="actionsCellTemplate">
    </dxi-column>
    <dxi-column dataField="siteLibelle" caption="Sites"
                cellTemplate="sitesCellTemplate"
                [filterOperations]="['contains']"
                [allowSearch]="true"
                [allowSorting]="true"
                [allowFiltering]="true">
    </dxi-column>
    <dxi-column dataField="contrat" caption="Offres alimentaires"
                [allowSearch]="true"
                [filterOperations]="['contains']"
                [allowSorting]="true"
                [allowFiltering]="true">
    </dxi-column>
  <dxi-column dataField="convive" caption="Prestations" cellTemplate="conviveCellTemplate" [allowSearch]="false" [allowFiltering]="false" [allowSorting]="false"></dxi-column>
  <dxi-column dataField="code" [allowSearch]="true" caption="Codes"
              [filterOperations]="['contains']"
              [allowSorting]="true"
              [allowFiltering]="true">
  </dxi-column>
  <dxi-column dataField="actif" caption="Actif"
              [filterOperations]="['contains']"
              [allowSearch]="true"
              [allowSorting]="true"
              [allowFiltering]="true">
  </dxi-column>

  <div *dxTemplate="let cell of 'actionsCellTemplate'">

    <ng-container *ngIf="auth2Svc.isSiteLocal(cell.row.data.siteId)">

      <yo-cell-button (yoNavigation)="openEditDialog(cell.row.data)"
                      [yoTooltipShowDelay]="1500"
                      [yoMaxWidth]="true"
                      [yoWidthPercent]="30"
                      [yoTextAlign]="'center'"
                      yoIconClass="fa fa-edit"
                      pTooltip="Modifier le contrat"
                      tooltipPosition="right"
                      showDelay="500"
      ></yo-cell-button>

      <yo-cell-button (yoNavigation)="openDeleteDialog(cell.row.data)"
                      [yoTooltipShowDelay]="1500"
                      [yoMaxWidth]="true"
                      [yoWidthPercent]="30"
                      [yoTextAlign]="'center'"
                      [yoIconClass]="'fa fa-trash'"
                      pTooltip="Supprimer le contrat"
                      tooltipPosition="right"
                      showDelay="500"
      ></yo-cell-button>

    </ng-container>
    <ng-container *ngIf="!auth2Svc.isSiteLocal(cell.row.data.siteId)">
      <yo-cell-button (yoNavigation)="openEditDialog(cell.row.data)"
                      [yoTooltipShowDelay]="1500"
                      [yoMaxWidth]="true"
                      [yoWidthPercent]="30"
                      [yoTextAlign]="'center'"
                      [yoIconClass]="'fa fa-eye'"
                      pTooltip="Voir le contrat"
                      tooltipPosition="right"
                      showDelay="500"
      ></yo-cell-button>
    </ng-container>
  </div>

  <div *dxTemplate="let cell of 'sitesCellTemplate'">
    <yo-site [siteId]="cell.row.data.siteId"
             [yoDelayMsTooltip]="1500"></yo-site>
    {{cell.row.data.siteLibelle}}
  </div>

  <div *dxTemplate="let cell of 'conviveCellTemplate'">
    <td style="vertical-align: top;padding:4px; width:40px !important;">
      <yo-cell-button (yoNavigation)="openContratMenuConvive(undefined, cell.row.data)"
                      [yoTooltipShowDelay]="1500"
                      [yoMaxWidth]="true"
                      [yoWidthPercent]="45"
                      yoTextAlign="center"
                      yoIconClass="fa fa-plus"
                      pTooltip="Ajouter une prestation à l'offre alimentaire"
                      tooltipPosition="left"
                      [yoDisabled]="!canCreateContratMenuConvive(cell.row.data)"
                      [class.disable]="!canCreateContratMenuConvive(cell.row.data)"
      ></yo-cell-button>
      <yo-cell-button (yoNavigation)="openPanelDuplication($event, cell.row.data)"
                      [yoTooltipShowDelay]="1500"
                      [yoMaxWidth]="true"
                      [yoWidthPercent]="30"
                      [yoTextAlign]="'center'"
                      [yoIconClass]="'fa fa-copy'"
                      pTooltip="Dupliquer une prestation"
                      tooltipPosition="left"
                      showDelay="500"
      ></yo-cell-button>
    </td>
    <td style="vertical-align: top;padding:4px;">
      <ng-container
        *ngFor="let contratMenuConvive of cell.row.data.contratMenuConvives;trackBy : utils.trackByFn">

        <div class="mg-r-10">
          <label class="badge badge-secondary zoom cursor"
                 (click)="openContratMenuConvive(contratMenuConvive, cell.row.data)"
                 pTooltip="Fiche de la prestation {{contratMenuConvive.libelle}}"
                 tooltipPosition="left"
                 showDelay="800">
            {{contratMenuConvive.libelle | uppercase}}
          </label>
        </div>
      </ng-container>
    </td>
  </div>

  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-sorting mode="multiple"></dxo-sorting>

  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} offre(s) alimentaire(s)">
  </dxo-pager>

</dx-data-grid>

<!--PANEL PRESTATION DUPLICATION-->
<p-overlayPanel #opDuplication>

  <div class="d-flex flex-column form-group ">

    <label
      [class.requiredControl]="true"
      class="genlabel  font-16"
      pTooltip=""
      showDelay="500">
      Prestation à dupliquer
    </label>

    <p-dropdown [options]="prestations"
                [(ngModel)]="prestationSelected"
                optionLabel="libelle"
                filter="true"
                placeholder="sélection..."
                [autoDisplayFirst]="false"
                [style]="{'width':'360px','margin-bottom':'10px'}">
      <ng-template let-prestations pTemplate="selectedItem">
        <span style="vertical-align:middle; margin-left: .5em">{{prestations.libelle | uppercase  }}</span>
      </ng-template>
      <ng-template let-prestations pTemplate="item">
        <div style="position: relative;height: 25px;">
          <div style="font-size:14px;margin-top:4px">
            {{prestations.libelle | uppercase  }}
          </div>
        </div>
      </ng-template>
    </p-dropdown>


    <label
      [class.requiredControl]="true"
      class="genlabel  font-16"
      pTooltip=""
      showDelay="500">
      Offre Alimentaire sur laquelle dupliquer la prestation
    </label>
    <p-dropdown [options]="contratsMenus"
                [(ngModel)]="offreAlimentaireSelected"
                optionLabel="libelle"
                filter="true"
                placeholder="sélection..."
                [autoDisplayFirst]="false"
                [style]="{'width':'360px'}">
      <ng-template let-contratsMenus pTemplate="selectedItem">
              <span
                style="vertical-align:middle; margin-left: .5em">{{contratsMenus.libelle | uppercase  }}</span>
      </ng-template>
      <ng-template let-contratsMenus pTemplate="item">
        <div style="position: relative;height: 25px;">
          <div style="font-size:14px;margin-top:4px">
            {{contratsMenus.libelle | uppercase  }}
          </div>
        </div>
      </ng-template>
    </p-dropdown>
  </div>


  <div class="d-flex flex-row-reverse">
    <button pButton
            label="Dupliquer"
            icon="fas fa-copy"
            (click)="duplicatePrestation()"
            [disabled]="offreAlimentaireSelected === undefined ? true : false || prestationSelected === undefined ? true : false">
    </button>
  </div>
</p-overlayPanel>


<!--DIALOG FORM CONTRAT-->
<yo-dialog-contrat-form
  [displayDialog]="displayDialogContratForm"
  (onClose)="onCloseDialogContrat($event)">
</yo-dialog-contrat-form>
