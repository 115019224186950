import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {of, Subscription} from 'rxjs';
import {UtilsService} from '../../../core/utils/utils.service';
import {SortiesProductionSupplier} from './sorties-production-resolver.service';
import {
  ERREURS,
  MIME_TYPE,
  MSG_KEY,
  MSG_SEVERITY,
  STATUT_SORTIE_POUR_PRODUCTION,
  UI_COLORS,
  VUE_SORTIES_POUR_PRODUCTION,
  WORKFLOW_REVISION
} from '../../../core/constants';
import {MenuItem, SelectItem} from 'primeng/api';
import {
  SORTIE_PROD_PRINT_MODE,
  SortiePourProductionService
} from '../../../core/services/entities/sortie-pour-production.service';
import {WorkflowInstanceService} from '../../../core/services/entities/workflow-instance.service';
import {GenericFormService} from '../../../core/services/generics/generic-form.service';
import {catchError, switchMap} from 'rxjs/operators';
import {WorkflowsService} from '../../../core/services/entities/workflows.service';
import {RunStepDTO} from '../../../core/dtos/run-step-dto';
import {RoutemapService} from '../../../core/services/routemap.service';
import {cloneDeep as _cloneDeep} from 'lodash';
import {DATAGRID_ROW_TYPES, DevextremeService} from '../../../core/services/technique/devextreme.service';
import {DxDataGridComponent} from 'devextreme-angular';
import {SortiePourProductionDTO} from '../../../core/dtos/sortie-pour-production-dto';
import {saveAs as fs_saveAs} from 'file-saver';
import {ErreurFonctionnelleDto} from '../../../core/dtos/erreur-fonctionnelle-dto';
import {confirm} from "devextreme/ui/dialog";
import {ToastService} from "../../../core/services/technique/toast.service";


@Component({
  selector: 'yo-sorties-production',
  templateUrl: './sorties-production.component.html',
  styleUrls: ['./sorties-production.component.scss']
})
export class SortiesProductionComponent implements OnInit, OnDestroy {

  subRoute: Subscription;
  sps: SortiesProductionSupplier;
  VUE_SORTIES_POUR_PRODUCTION = VUE_SORTIES_POUR_PRODUCTION;
  STATUT_SORTIE_POUR_PRODUCTION = STATUT_SORTIE_POUR_PRODUCTION;
  ERREURS_CODES = ERREURS;


  popupPosition = {of: window, at: 'top', my: 'top', offset: {y: 10}};

  @ViewChild('grid') grid: DxDataGridComponent;

  chooseView: SelectItem[] = [
    {
      label: 'DENRÉES',
      value: VUE_SORTIES_POUR_PRODUCTION.DENREE
    },
    {
      label: 'STOCKS',
      value: VUE_SORTIES_POUR_PRODUCTION.STOCK
    },
  ];
  selectedVue: VUE_SORTIES_POUR_PRODUCTION;

  itemsReceptions: MenuItem[];


  constructor(private route: ActivatedRoute,
              public utils: UtilsService,
              public sppSvc: SortiePourProductionService,
              public gfs: GenericFormService,
              public workflowSvc: WorkflowsService,
              public dxSvc: DevextremeService,
              public routeMapSvc: RoutemapService,
              public workflowInstanceSvc: WorkflowInstanceService,
              private toastSvc: ToastService) {
  }

  ngOnInit(): void {
    this.routeSubscription();
    this.initPrintItemButton();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subRoute);
  }

  routeSubscription = () => {
    this.subRoute = this.route.data.subscribe((data: { sortiesProductionSupplier: SortiesProductionSupplier }) => {
      this.sps = data.sortiesProductionSupplier;
      this.selectedVue = _cloneDeep(this.sps.workflowInstance.vueSortiesPourProduction);
    });
  };

  /**
   * Changer la vue en mode stock ou denree.
   * En mode stock, si des lignes sont validées ou en rupture... on ne pourra pas passer en vue denrée
   */
  changeView = ($event: any) => {

    const runStep: RunStepDTO = new RunStepDTO();
    runStep.idWorkflowInstance = this.sps.workflowInstance.id;
    runStep.revTypeValue = WORKFLOW_REVISION.MODIFIE;

    this.sppSvc.canChangeViewSortiePourProduction(this.sps.workflowInstance.id).subscribe(async response => {

      if (!this.utils.isResponseSupplierError(response)) {
        if (!response.one && this.selectedVue === VUE_SORTIES_POUR_PRODUCTION.DENREE) {
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, `Vous ne pouvez pas changer la vue en mode 'DENRÉE' : des sorties pour production ont été validées et des mouvements de stock sont en cours`);
          this.selectedVue = this.sps.workflowInstance.vueSortiesPourProduction;
        } else {
          const result = confirm(`Êtes vous sûr de vouloir changer de vue? Si oui, toutes les informations saisies seront perdues.`, 'Confirmation');
          const isConfirmed: boolean = await result;
          if (isConfirmed) {
            this.sps.workflowInstance.vueSortiesPourProduction = this.selectedVue;

            // on enregistre le mode de vue sur le workflow instance
            this.gfs.saveOne(this.sps.workflowInstance, this.workflowInstanceSvc.getEntityName()).pipe(
              // on relance le calcul de la sortie pour production
              switchMap(data => this.workflowSvc.runStep(runStep)),
              catchError(err => this.utils.handleError(err))
            ).subscribe(response => {
              this.routeMapSvc.goToSecondaryRoute(['gestion-processus', runStep.idWorkflowInstance, 'sorties-production']);
            });
          } else {
            this.selectedVue = this.sps.workflowInstance.vueSortiesPourProduction;
          }
        }
      }

    });

  };

  onCellPrepared = (event: any) => {
    // si cell est un header
    if (event.rowType === DATAGRID_ROW_TYPES.HEADER) {

      // si colonne editable
      if (event.column.allowEditing) {
        event.cellElement.style.backgroundColor = UI_COLORS.EDITABLE;
      }
    }
  };


  validerSorties = async () => {

    if (this.utils.isCollectionNullOrEmpty(this.grid.instance.getSelectedRowsData())) {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, `Veuillez sélectionnez des sorties pour production avant de valider les sorties`);
    } else {

      let message = `Êtes vous sûr de vouloir valider les sorties pour production? Vous ne pourrez plus annuler les sorties par la suite !<br><br>`;

      let enErreur = false;
      for (const item of this.grid.instance.getSelectedRowsData()) {
        if (item.enErreur) {
          enErreur = true;
          break;
        }
      }

      if (enErreur) {
        message += `Les denrées suivantes sont en erreur :<br><br>`;
        this.grid.instance.getSelectedRowsData().forEach((item: SortiePourProductionDTO) => {
          if (item.enErreur) {
            message += `${item.produitDeclinaisonLibelle.toUpperCase()} : ${item.erreurList.map((err: ErreurFonctionnelleDto) => err.message).join(', ')}<br><br>`;
          }
        });
      }

      const result = confirm(message, 'Confirmation');
      const isConfirmed: boolean = await result;
      if (isConfirmed) {
        this.sppSvc.validerSorties(this.sps.workflowInstance.id, this.grid.instance.getSelectedRowsData().map(item => item.id)).subscribe(response => {
          if (!this.utils.isResponseSupplierError(response) && this.sps.sortiesPourProductionList) {
            this.sps.sortiesPourProductionList = response.resultList;
          }
        });
      }
    }
  };

  isValiderSortiesDisabled = () => {

    if (!this.utils.isCollectionNullOrEmpty(this.sps.sortiesPourProductionList)) {
      for (const spp of this.sps.sortiesPourProductionList) {
        if (spp.statutSortiePourProductionCode === STATUT_SORTIE_POUR_PRODUCTION.PROPOSITION_DE_SORTIE) {
          return false;
        }
      }
    }
    return true;
  };

  onEditorPreparing = (event: any) => {
    if (event.row && event.row.rowType === DATAGRID_ROW_TYPES.DATA) {
      if (event.dataField === 'quantiteReelleUs') {
        // ne pas pouvoir modifier la quantite reelle si elle est en sortie validee
        if (event.row.data.statutSortiePourProductionCode === STATUT_SORTIE_POUR_PRODUCTION.SORTIE_VALIDEE) {
          event.editorOptions.disabled = true;
        }
      }
    }
  };

  onRowUpdated = (event: any) => {
    this.saveSortiePourProduction(event.data)
  };

  saveSortiePourProduction = (spp: SortiePourProductionDTO) => {
    this.sppSvc.saveSortiePourProduction(spp).subscribe();
  };

  getResteASortir = (data: SortiePourProductionDTO) => {

    let result = data.quantitePrevueUs - data.quantiteReelleUs;
    if (result < 0) {
      result = 0;
    }
    return result;
  };

  isDisabledPrintBtn = () => this.sps.sortiesPourProductionList.length === 0;

  initPrintItemButton = () => {
    this.itemsReceptions = [
      {
        label: 'Vue par Atelier',
        icon: 'far fa-file-pdf',
        command: (event) => this.printVueAtelier()
      },
      {
        label: 'Vue par zone de stockage',
        icon: 'far fa-file-pdf',
        command: (event) => this.printVueZoneStockage()
      }];
  };

  printVueAtelier = () => {
    this.sppSvc.printPdfSortieProd(this.sps.workflowInstance.id, SORTIE_PROD_PRINT_MODE.vueArticle).subscribe((response) => {
      // naming file
      let reportName = 'sortie_prod_vue_atelier ' + this.sps.planProduction.libelle+'.pdf';

      let blob = new Blob([response], {
        type: MIME_TYPE.PDF // must match the Accept type
      });

      // save file
      fs_saveAs(blob, reportName);

      return of(blob);
    })
  };

  printVueZoneStockage = () => {
    this.sppSvc.printPdfSortieProd(this.sps.workflowInstance.id, SORTIE_PROD_PRINT_MODE.vueZoneDeStockage).subscribe((response) => {
      // naming file
      let reportName = 'sortie_prod_vue_stockage' + this.sps.planProduction.libelle+'.pdf';

      let blob = new Blob([response], {
        type: MIME_TYPE.PDF // must match the Accept type
      });

      // save file
      fs_saveAs(blob, reportName);

      return of(blob);
    })
  };
}
