import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {MSG_KEY, MSG_SEVERITY, STATUTS_MENUS} from "app/core/constants";
import {UtilsService} from "../../../../core/utils/utils.service";
import {EffectifMode} from "../../constant/menu-plc.constant";
import {PlcRepas, PlcRepasWeek} from "../../menu-plc-resolver.service";
import {ContratMenuConvive__ContrainteAlimDTO} from "../../../../core/dtos/contratmenuconvive__contrainte-alim-dto";
import {find as _find} from "lodash";
import {ContratMenuConviveRepasPlcDateDTO} from "../../../../core/dtos/cmcr-plc-date-dto";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {PointDeLivraisonService} from "../../../../core/services/entities/point-de-livraison.service";
import {CmcrPlcDateService} from "../../../../core/services/entities/cmcr-plc-date.service";
import {debounceTime} from "rxjs/operators";
import {Subject, Subscription} from "rxjs";
import {ConfigurationPortailDTO} from "../../../../core/dtos/portail/configuration-portail-dto";
import {ToastService} from "../../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-generic-repas-plc',
  templateUrl: './generic-repas-plc.component.html',
  styleUrls: ['./generic-repas-plc.component.scss']
})
export class GenericRepasPlcComponent implements OnInit, OnDestroy {

  @Input() typeEffectif = EffectifMode.Prevision;
  @Input() configPortailPlc: ConfigurationPortailDTO;
  @Output() onEmitPlcRepas = new EventEmitter<PlcRepas>();

  STATUTS_MENUS = STATUTS_MENUS;
  EffectifMode = EffectifMode;
  colWidth: 80;

  selectedCell: ContratMenuConviveRepasPlcDateDTO;

  private subjectChangePlcEffectifRepas = new Subject<any>();
  changePlcEffectifRepas$ = this.subjectChangePlcEffectifRepas.asObservable();
  subChangeEffectifRepas: Subscription;

  _prw: PlcRepasWeek;
  @Input('plcRepasWeek') set prw(value: PlcRepasWeek) {
    this._prw = value;
  };

  constructor(public utils: UtilsService,
              public auth2Svc: Auth2Service,
              private plcSvc: PointDeLivraisonService,
              private cmcrPlcDateSvc: CmcrPlcDateService,
              private toastSvc: ToastService) {
  }

  ngOnInit() {
    this.changePlcEffectifRepasSubscription();
  }

  ngOnDestroy() {
    this.utils.unsubscribe(this.subChangeEffectifRepas);
  }

  changePlcEffectifRepasSubscription = () => {
    this.subChangeEffectifRepas = this.changePlcEffectifRepas$.pipe(debounceTime(300)).subscribe((dataWrapper: any) => {
      if (this.configPortailPlc.minValeurSaisie <= dataWrapper.value && dataWrapper.value <= this.configPortailPlc.maxValeurSaisie) {
        dataWrapper.cell[dataWrapper.propertyName] = dataWrapper.value;
        this.selectedCell = dataWrapper.value;
        // declencher le traitement de sauvegarde d'un effectif repas plc (recalculer aussi les effectifs de ligne de plat)
        this.cmcrPlcDateSvc.saveEffectifRepasPlc(dataWrapper.cell, this.typeEffectif, true).subscribe(response => {
          if (!this.utils.isResponseSupplierError(response)) {
            this.showRepas(response.one, undefined);
          }
        });
      } else {
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, `La valeur n'est pas valide. Rappel : la valeur minimal est égale à ${this.configPortailPlc.minValeurSaisie} et la value maximal est égale à ${this.configPortailPlc.maxValeurSaisie}`);
      }
    });
  };

  getCell = (prw: PlcRepasWeek, rowData: ContratMenuConvive__ContrainteAlimDTO, col: Date) => {
    if (!this.utils.isNullOrEmpty(prw) && !this.utils.isCollectionNullOrEmpty(prw.cells)) {
      return _find(prw.cells, {
        'cmcCaId': rowData.id,
        'dateRepas': col,
      });
    }
    return null;
  };

  showRepas = (cell: ContratMenuConviveRepasPlcDateDTO, $event: MouseEvent | FocusEvent) => {
    this.selectedCell = cell;

    if (this.selectedCell) {
      this.plcSvc.loadPlcRepas(this.selectedCell).subscribe(response => {
        this.onEmitPlcRepas.emit(response.one);
      });
    }
  };

  isEffectifInputDisabled = (cell: ContratMenuConviveRepasPlcDateDTO): boolean => {

    if (this.configPortailPlc.dateLimiteSaisie < new Date(cell.dateRepas)) {
      if (cell.statutMenuCode != STATUTS_MENUS.SAISIE_EFFECTIFS) {
        return true;
      } else {
        return !this.auth2Svc.isSiteLocal(cell.cmSiteId);
      }
    } else {
      return true;
    }
  };

  changeEffectif = ($event: FocusEvent, cell: ContratMenuConviveRepasPlcDateDTO, propertyName: string) => {
    const dataWrapper: any = {
      value: +$event,
      cell: cell,
      propertyName: propertyName
    };
    this.subjectChangePlcEffectifRepas.next(dataWrapper);
  };

}
