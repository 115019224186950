

<p-toolbar styleClass="p-mb-4">
  <ng-template pTemplate="left">
    <div>
      <yo-help class="mg-r-5" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
      <button
        pButton
        label="CRÉER"
        class=" vertical-align"
        [disabled]="false"
        icon="fa fa-plus"
        type="button"
        (click)="openInitProcessus()">
      </button>
    </div>

    <span class="genlabel font-12 pd-t-10" *ngIf="listeBesoinsList.length === 0">
          <i class="fas fa-exclamation-triangle danger-color mg-r-5"></i>
          Aucune liste de besoin n'a été créée
  </span>
  </ng-template>
  <ng-template pTemplate="right">
    <button
      type="button"
      pButton
      (click)="deleteListeBesoinsItems()"
      [disabled]="isActionDisabled"
      pTooltip="Supprimer les listes de besoins sélectionnées"
      icon="fa fa-trash">
    </button>
  </ng-template>
</p-toolbar>

<dx-data-grid
  [dataSource]="dataSource"
  [remoteOperations]="true"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(182)"
  width="100%"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [allowColumnResizing]="true"
  (onEditorPreparing)="onEditorPreparing($event)"
  (onSelectionChanged)="checkDisabledButtons()"
  #grid>
  <dxo-editing mode="cell" [allowUpdating]="true" [allowAdding]="false" [allowDeleting]="false"></dxo-editing>
  <dxo-selection [deferred]="true" [allowSelectAll]="true" [showCheckBoxesMode]="checkBoxesMode" mode="multiple"></dxo-selection>

  <dxi-column dataField="id" caption="Actions" [width]="100" cellTemplate="templateActions" [allowSorting]="false" [allowEditing]="false" alignement="left"></dxi-column>
  <dxi-column dataField="site.libelle" caption="Site" cellTemplate="templateSite" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="libelle" caption="Liste de besoins" cellTemplate="templateLibelle" [setCellValue]="changeValueLibelle" [allowEditing]="true"></dxi-column>
  <dxi-column dataField="date" caption="Date" cellTemplate="templateDate" [allowEditing]="false" alignment="left"></dxi-column>
  <dxi-column dataField="workflowInstance.libelle" caption="Processus" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="workflowInstance.libelleWorkflowStatut" caption="Avancement" cellTemplate="templateAvancement" [allowSorting]="false" [allowEditing]="false" alignment="left"></dxi-column>

  <div *dxTemplate="let cell of 'templateActions'">
    <td>
      <button pButton icon="fas fa-edit"
              class="mg-l-5 p-button-secondary"
              pTooltip="Visualiser la liste de besoins"
              tooltipPosition="top"
              showDelay="500"
              (click)="workflowSvc.openTask(cell.row.data.workflowInstance)"
              [disabled]="!auth2Svc.isSiteLocal(cell.row.data.site.id)">
      </button>
      <button pButton icon="fas fa-search"
              class="mg-l-5 p-button-secondary"
              tooltipPosition="top"
              showDelay="500"
              pTooltip="Infos rapides sur la liste de besoins"
              (click)="showInfosListeBesoin(cell.row.data,$event)"
              [disabled]="!auth2Svc.isSiteLocal(cell.row.data.site.id)">
      </button>
      <yo-button-next-steps
        class="mg-l-5"
        [play]="true"
        (onError)="updateErrors($event)"
        [workflowInstance]="cell.row.data.workflowInstance">
      </yo-button-next-steps>
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateSite'">
    <td>
      <yo-site [siteId]="cell.row.data.site.id"></yo-site>
      {{cell.row.data.site.libelle}}
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateLibelle'">
    <strong>{{ cell.value }}</strong>
  </div>
  <div *dxTemplate="let cell of 'templateDate'">
    <td style="text-align: left;">
      {{cell.row.data.date | date:'E d LLL yyyy' | titlecase}}
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateAvancement'">
    <td (click)="workflowSvc.openTask(cell.row.data.workflowInstance)"
        [class.hover-background-grey]="cell.row.data.workflowInstance.codeWorkflowStatut !== 'end'">
      <div>
        <i class="fas fa-square fa-lg  mg-r-10"
           [style.color]="'#'+cell.row.data.workflowInstance.hexCouleurWorkflowStatut"></i>
        <label class="cursor">{{cell.row.data.workflowInstance.libelleWorkflowStatut  | uppercase}}</label>
      </div>
    </td>
  </div>

  <dxo-sorting mode="single"></dxo-sorting>
  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} liste(s) de besoin">
  </dxo-pager>

</dx-data-grid>

<!--TOOLTIP INFOS LISTE DE BESOINS-->
<p-overlayPanel appendTo="body" #opInfosListeBesoin [dismissable]="true" [showCloseIcon]="true">

  <ng-container *ngIf="!utils.isNullOrEmpty(selectedListeBesoin)">

    <div class="d-flex flex-column width-700">
      <div class="p-2">
        <strong>Liste de besoins :</strong>
      </div>
      <div class="p-2">
        {{selectedListeBesoin.libelle}}
      </div>


      <div class="p-2">
        <strong>Période :</strong>
      </div>

      <div class="p-2">
        <ng-container *ngIf="utils.isNullOrEmpty(selectedListeBesoinDateDebut)">
          aucune période sélectionnée
        </ng-container>
        <ng-container *ngIf="!utils.isNullOrEmpty(selectedListeBesoinDateDebut)">
          <ng-container *ngIf="selectedListeBesoinDateDebut === selectedListeBesoinDateFin">
            le {{selectedListeBesoinDateDebut | date:'dd/MM/yyyy'}}
          </ng-container>
          <ng-container *ngIf="selectedListeBesoinDateDebut != selectedListeBesoinDateFin">
            du {{selectedListeBesoinDateDebut | date:'dd/MM/yyyy'}}
            au {{selectedListeBesoinDateFin | date:'dd/MM/yyyy'}}
          </ng-container>

        </ng-container>

      </div>

      <div class="p-2">
        <strong>Repas :</strong>
      </div>

      <div class="p-2 ">
        <ng-container *ngIf="utils.isCollectionNullOrEmpty(selectedListeBesoinNodes)">
          aucune sélection de repas
        </ng-container>
        <ng-container *ngIf="!utils.isCollectionNullOrEmpty(selectedListeBesoinNodes)">
          <p-tree [value]="selectedListeBesoinNodes"
                  [style]="{'max-height':'400px', 'width':'600px','font-size':'10px','overflow-x':'hidden'}"></p-tree>
        </ng-container>

      </div>


    </div>

  </ng-container>


</p-overlayPanel>

<!--DIALOG CREATION D UN PROCESS DE WORFLOW-->
<yo-init-processus></yo-init-processus>
