import {Component, OnDestroy, OnInit} from "@angular/core";
import {UtilsService} from "../../../../core/utils/utils.service";
import {ConfigurationPortailService} from "../../../../core/services/portail/configuration-portail.service";
import {Subscription} from "rxjs";
import {PointDeLivraisonDTO} from "../../../../core/dtos/point-de-livraison-d-t-o";
import {ResponseWrapper} from "../../../../core/suppliers/wrappers/response-wrapper";
import {PointDeLivraisonService} from "../../../../core/services/entities/point-de-livraison.service";
import {ConfigurationPortailDTO} from "../../../../core/dtos/portail/configuration-portail-dto";
import {JOURS_SEMAINES, MSG_KEY, MSG_SEVERITY, UNITE_BLOCAGE_CONFIGURATION_PORTAIL} from "../../../../core/constants";
import {ToastService} from "../../../../core/services/technique/toast.service";

export enum ModeEnum {
  UPDATE, CREATE
}

@Component({
  selector: 'yo-configuration-portail-plc-form',
  templateUrl: './configuration-portail-plc-form.component.html',
  styleUrls: ['./configuration-portail-plc-form.component.scss']
})
export class ConfigurationPortailPlcFormComponent implements OnInit, OnDestroy {

  titleDialog: string;
  displayDialog: boolean;
  subOpenDialog: Subscription;
  plcList: PointDeLivraisonDTO[] = [];

  mode :ModeEnum;
  unitBlocageOptionList: any[] = UNITE_BLOCAGE_CONFIGURATION_PORTAIL;
  jourLimitSaisiOption = JOURS_SEMAINES;
  portailConfig: ConfigurationPortailDTO;
  plcSelected: number;
  plcsSelected: number[] = [];
  jourLimiteSaisiSelected: any;
  uniteBlocageSelected: any;
  maxValeurSaisie: number;
  minValeurSaisie: number;
  nombreUniteBlocage: number;
  heureLimiteSaisie: number;

  originPlcId: number;

  ModeEnum = ModeEnum;

  constructor(public utils: UtilsService,
              private configurationPortailSvc: ConfigurationPortailService,
              private plcSvc: PointDeLivraisonService,
              private toastSvc: ToastService) {
  }

  ngOnInit(): void {
    this.subcriptionOpenDialogFormConfigPortailPlc();
    this.initData();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subOpenDialog);
  }

  subcriptionOpenDialogFormConfigPortailPlc = (): void => {
    this.subOpenDialog = this.configurationPortailSvc.subjectOpenDialogFormConfigPortailPlc$.subscribe(data => {
      this.displayDialog = true;
      this.initDialog(data);
    });
  }

  initDialog = (data : ConfigurationPortailDTO): void => {
    if (!data.id) {
       this.titleDialog = 'Création d\'un paramétrage portail';
       this.mode = ModeEnum.CREATE;
    } else {
      this.titleDialog = 'Configuration portail pour le point de livraison '+ data.plc.libelle;
      this.mode = ModeEnum.UPDATE;
      this.plcSelected = data?.plc.id;
      this.originPlcId = data?.plc.id;
      this.jourLimiteSaisiSelected = JOURS_SEMAINES.find(j => j.viewValue === data.jourLimiteSaisi.toUpperCase());
      this.uniteBlocageSelected = UNITE_BLOCAGE_CONFIGURATION_PORTAIL.find(u => u.value === data.uniteBlocage);
      this.heureLimiteSaisie = data.heureLimiteSaisie;
      this.nombreUniteBlocage = data.nombreUniteBlocage;
      this.minValeurSaisie = data.minValeurSaisie;
      this.maxValeurSaisie = data.maxValeurSaisie;
    }
    this.portailConfig = data;
  }

  initData = (): void => {
    this.plcSvc.getPlcList().subscribe((data: ResponseWrapper<PointDeLivraisonDTO>) => {
      this.plcList = data.resultList;
    });
  }

  closeDialog = (): void => {
    this.displayDialog = false;
    this.portailConfig.jourLimiteSaisi = null;
    this.portailConfig = {
        id: 0,
        uniteBlocage: 'week',
        nombreUniteBlocage: 1,
        jourLimiteSaisi: 'Lundi',
        minValeurSaisie: 1,
        maxValeurSaisie: 10,
        heureLimiteSaisie: 16,
    } as ConfigurationPortailDTO;
  };

  saveConfigPortail = (): void =>{
    if (this.mode === ModeEnum.CREATE)
      this.portailConfig.plcListToCreate = this.plcsSelected.map(id => ({ id } as PointDeLivraisonDTO));
    else
      this.portailConfig.plc.id = this.plcSelected;

    this.portailConfig.jourLimiteSaisi = this.jourLimiteSaisiSelected.viewValue;
    this.portailConfig.uniteBlocage = this.uniteBlocageSelected.value;
    this.portailConfig.heureLimiteSaisie = this.heureLimiteSaisie;
    this.portailConfig.nombreUniteBlocage = this.nombreUniteBlocage;
    this.portailConfig.minValeurSaisie = this.minValeurSaisie;
    this.portailConfig.maxValeurSaisie = this.maxValeurSaisie;
    this.portailConfig.originPlcId = this.originPlcId;

    this.configurationPortailSvc.saveConfigurationPlc(this.portailConfig).subscribe( data => {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `La configuration a été enregistrée avec succès`);
      this.configurationPortailSvc.announceRefreshConfigPlcList();
      this.closeDialog();
    });
  }

}
