import {Component, OnDestroy, OnInit} from "@angular/core";
import {UtilsService} from "../../../../core/utils/utils.service";
import {MarcheService} from "../../../../core/services/entities/marche.service";
import {GenericDatagridService} from "../../../../core/services/generics/generic-datagrid.service";
import {GenericFormService} from "../../../../core/services/generics/generic-form.service";
import {ActivatedRoute} from "@angular/router";
import {GroupeAchatService, TAB_NAME_GR} from "../../../../core/services/gestion-fournisseurs/groupe-achat.service";
import {GroupeAchatDTO} from "../../../../core/dtos/unite-de-production/groupe-achat-dto";
import {Subscription} from "rxjs";
import {FormGroup} from "@angular/forms";
import {FormFieldBaseSupplier} from "../../../../core/suppliers/form-fieldbase-supplier";
import {GROUP_POSITION, GroupFormFields} from "../../../../core/suppliers/group-formfields";
import {GroupeAchatSidebarSupplier} from "../groupe-achat-sidebar-resolver.service";
import {ResponseWrapper} from "../../../../core/suppliers/wrappers/response-wrapper";
import {HELP_FOLDERS, MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {ToastService} from "../../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-dialog-groupe-achat',
  templateUrl: './fiche-identite-groupe-achat.component.html',
  styleUrls: ['./fiche-identite-groupe-achat.component.scss']
})
export class FicheIdentiteGroupeAchatComponent implements OnInit, OnDestroy {

  groupeAchat : GroupeAchatDTO;
  subscriptionRoute: Subscription;

  form: FormGroup;
  formInfoGenerales: FormGroup = new FormGroup({});
  fieldsInfosGenerales: FormFieldBaseSupplier<any>[];
  groups: GroupFormFields[] = [];

  pathFile: string = HELP_FOLDERS.FOURNISSEURS_GROUPES_ACHATS + '/fournisseur-edition-groupe-achat';

  constructor(public utils: UtilsService,
              private marcheSvc: MarcheService,
              public gds: GenericDatagridService,
              private gfs: GenericFormService,
              private groupeAchatSvc: GroupeAchatService,
              private route: ActivatedRoute,
              private toastSvc: ToastService) {
  }

  ngOnInit() {
    this.initData();
  }

  ngOnDestroy() {
    this.utils.unsubscribe(this.subscriptionRoute);
  }

  initData = () => {
    this.form = new FormGroup({
      formInfoGenerales: this.formInfoGenerales
    });
    this.subscriptionRoute = this.route.parent.data
      .subscribe((data: { groupeAchatSidebarSupplier: GroupeAchatSidebarSupplier }) => {
        this.groupeAchat = data.groupeAchatSidebarSupplier.groupeAchat;
        this.initForm(this.groupeAchat);
        this.groupeAchatSvc.announceActiveMenuItem(TAB_NAME_GR.info);
      });
  };

  initForm = (groupeAchat: GroupeAchatDTO) => {
    this.fieldsInfosGenerales = this.groupeAchatSvc.getFields(groupeAchat);

    //desactiver les placeholder
    this.fieldsInfosGenerales.map(field => field.displayPlaceholder = false);
    this.formInfoGenerales = this.gfs.toFormGroup(this.fieldsInfosGenerales);
    this.form = new FormGroup({
      formInfoGenerales: this.formInfoGenerales
    });

    this.groups = [
      {
        titlePanel: 'Informations générales',
        fields: this.fieldsInfosGenerales,
        form: this.formInfoGenerales,
        titleIcon: 'fas fa-info-circle',
        position: GROUP_POSITION.COL1_AND_COL2,
        colsOrganization: {
          col1: groupeAchat.id > 0 ? ['site','code', 'libelle', 'descriptif', 'actif']
            : ['site', 'libelle', 'descriptif', 'actif']
        }
      },
    ];
  };

  saveFicheIdentite = () => {
    if (this.form.valid) {

      // @ts-ignore
      this.groupeAchat.site = this.utils.getLightSite(this.formInfoGenerales.controls['site'].value);
      this.groupeAchat.libelle = this.formInfoGenerales.controls['libelle'].value;
      this.groupeAchat.descriptif = this.formInfoGenerales.controls['descriptif'].value;
      this.groupeAchat.actif = this.formInfoGenerales.controls['actif'].value;

        this.groupeAchatSvc.saveGroupeAchat(this.groupeAchat)
          .subscribe(
          (response: ResponseWrapper<GroupeAchatDTO>) => {
            this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `Sauvegarde du groupe achat ${response.one.libelle} réalisé avec succès`);
            this.groupeAchatSvc.announceGroupeAchat(response.one, TAB_NAME_GR.info);
          }
        );
    }
  };


  help(){}

  isDisabledSaveBtn(){}

}
