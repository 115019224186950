

<p-toolbar styleClass="p-mb-4">
  <ng-template pTemplate="left">
    <yo-help class="mg-r-5" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
    <button  pButton label="CRÉER"
              class="mg-r-5"
              icon="fa fa-plus"
              type="button"
              pTooltip="Créer une proposition de commande"
              (click)="createCommande.toggle($event)">
    </button>
    <p-menu #createCommande [popup]="true" [model]="createCommandeOptionList"></p-menu>

      <p-calendar
        firstDayOfWeek="1"
        [locale]="fr"
        (onSelect)="onChangeFilterDateLivraison()"
        (onTodayClick)="onChangeFilterDateLivraison()"
        (onClearClick)="onChangeFilterDateLivraison()"
        placeholder="Filtrer par dates de livraison..."
        pTooltip="Filtrer par dates de livraison"
        showDelay="500"
        appendTo="body"
        showButtonBar="true"
        class="mg-r-5"
        selectionMode="range"
        [(ngModel)]="filterDatesLivraisons"
        dateFormat="dd/mm/yy"
        readonlyInput="true"
        [showWeek]="true">
      </p-calendar>

    <span class="d-inline-block mg-input-filter"> <!-- Temporairement en attendant une refonte de l'écran pour responsivité -->
        <dx-tag-box
          [width]="200"
          [multiline]="false"
          [dataSource]="filterItemsFournisseur"
          placeholder="Filtrer par fournisseur..."
          displayExpr="value"
          valueExpr="id"
          [searchEnabled]="true"
          [showSelectionControls]="true"
          (onValueChanged)="onChangeFilterFournisseurs($event)"
        >
        </dx-tag-box>
      </span>

    <span class="d-inline-block mg-input-filter"> <!-- Temporairement en attendant une refonte de l'écran pour responsivité -->
      <dx-tag-box
        [width]="220"
        [multiline]="false"
        [dataSource]="filterItemsUdp"
        placeholder="Filtrer par unité de production..."
        displayExpr="value"
        valueExpr="id"
        [searchEnabled]="true"
        [showSelectionControls]="true"
        (onValueChanged)="onChangeFilterUdps($event)"
      >
      </dx-tag-box>
    </span>

    <span class="d-inline-block mg-input-filter"> <!-- Temporairement en attendant une refonte de l'écran pour responsivité -->
      <dx-tag-box
        [width]="450"
        [dataSource]="filterItemsListeBesoin"
        placeholder="Filtrer par liste de besoins..."
        displayExpr="value"
        valueExpr="id"
        [searchEnabled]="true"
        [showSelectionControls]="true"
        [multiline]="false"
        (onValueChanged)="onChangeFilterListesBesoins($event)"
      >
      </dx-tag-box>
    </span>

  </ng-template>
  <ng-template pTemplate="right">
     <span class="mg-r-5">
       <button type="button" pButton icon="fas fa-play"
                pTooltip="Passer à l'étape suivante"
               [disabled]="isActionDisabled"
               label="" (click)="menu.toggle($event)"></button>
       <p-menu #menu [popup]="true" [model]="nextSteps"></p-menu>
    </span>

    <span class="mg-r-5">
      <button
        pButton
        icon="fa fa-envelope"
        (click)="sendEmail()"
        [disabled]="isActionDisabled"
        pTooltip="Envoyer par mail les commandes aux fournisseurs sélectionnés">
      </button>
    </span>

    <span class="mg-r-5">
      <button
        pButton
        icon="fas fa-print"
        (click)="printPDFCommandes()"
        [disabled]="isActionDisabled"
        pTooltip="Imprimer les propositions sélectionnées au format pdf">
      </button>
    </span>

    <span class="mg-r-5">
      <button
        pButton
        icon="fa fa-trash"
        (click)="deleteBonCfs()"
        [disabled]="isActionDisabled"
        pTooltip="Supprimer les propositions sélectionnées">
      </button>
    </span>
  </ng-template>
</p-toolbar>

<dx-data-grid
  (onSelectionChanged)="checkDisabledButtons()"
  [dataSource]="dataSource"
  [remoteOperations]="true"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(182)"
  width="100%"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [allowColumnResizing]="true"
  #grid>
  <dxo-selection [deferred]="true" [allowSelectAll]="true" [showCheckBoxesMode]="checkBoxesMode" mode="multiple"></dxo-selection>

  <dxi-column dataField="id" caption="Actions" [width]="100" cellTemplate="templateActions" alignement="left" [allowSorting]="false"></dxi-column>
  <dxi-column dataField="numeroBcf" caption="Bon de commande" cellTemplate="templateBonCf" alignement="left"></dxi-column>
  <dxi-column dataField="dateCommande" caption="Date commande" cellTemplate="templateDateCommande" alignement="left"></dxi-column>
  <dxi-column dataField="dateLivraisonAjustee" alignement="left" caption="Date livraison" cellTemplate="templateDateLivraison"></dxi-column>
  <dxi-column dataField="fournisseurLibelle" caption="Fournisseur"></dxi-column>
  <dxi-column dataField="uniteDeProductionLibelle" caption="Unité de production" cellTemplate="templateUdp"></dxi-column>
  <dxi-column dataField="montantHT" caption="Montant HT" alignment="left" cellTemplate="templateMontantHT"></dxi-column>
  <dxi-column dataField="commentaire" caption="Remarque(s)" alignment="left" cellTemplate="templateRemarques"></dxi-column>
  <dxi-column dataField="listeBesoinLibelle" caption="Liste de besoins" alignment="left" cellTemplate="templateLbde"></dxi-column>

  <div *dxTemplate="let cell of 'templateActions'">
    <td>
      <yo-cell-button (yoNavigation)="openEditFournisseurDialog(cell.row.data,false)"
                      [yoTooltipShowDelay]="1500"
                      [yoMaxWidth]="true"
                      [yoWidthPercent]="30"
                      [yoTextAlign]="'center'"
                      class="space"
                      [yoIconClass]="auth2Svc.isSiteLocal(cell.row.data.site.id)? 'fas fa-edit':'fas fa-eye'"
                      [pTooltip]="auth2Svc.isSiteLocal(cell.row.data.site.id)? 'Modifier la commande':'Voir la commande'"
                      tooltipPosition="right"
                      showDelay="500">
      </yo-cell-button>
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateUdp'">
    <td>
      <yo-site [siteId]="cell.row.data.site.id" [siteLibelleTooltip]="cell.row.data.site.libelle" [siteLibelle]="cell.row.data.uniteDeProductionLibelle"></yo-site>
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateDateCommande'">
    <td style="text-align: right;">
      {{ cell.row.data.dateCommande | date:'dd/MM/yyyy' | titlecase }}
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateDateLivraison'">
    <td style="text-align: right;">
      {{ cell.row.data.dateLivraisonAjustee | date:'dd/MM/yyyy' | titlecase }}
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateMontantHT'">
    <td style="text-align: right;">
      {{ cell.row.data.montantHT | currency:'EUR':'symbol':'1.4-4' }}
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateRemarques'">
    <td style="text-align: left;"
    >
      <p [innerHTML]="domSanitizer.bypassSecurityTrustHtml(utils.stripeHtml(cell.row.data.commentaire))">
      </p>
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateLbde'">
    <td style="text-align: left;">
      <a pTooltip="Aller sur la liste de besoins" showDelay="500"
         (click)="wkfSvc.goToListeBesoinsDetails(cell.row.data.workflowInstance)"><span
        class="cursor save-color">{{cell.row.data.listeBesoinLibelle}}</span></a>
    </td>
  </div>
  <div *dxTemplate="let cell of 'templateBonCf'">
      <span class="mg-r-5">{{cell.row.data.numeroBcf}}</span>
      <span class="mg-r-5" *ngIf="cell.row.data.mailHisto">
      <label
        showDelay="1000"
        [pTooltip]="mailHistoSvc.getLabelStatut(cell.row.data.mailHisto.statut)"
        class="badge mg-r-5 cursor"
        [class.badge-warning]="cell.row.data.mailHisto.statut === BONCF_MAIL_STATUT.EN_COURS_ENVOI"
        [class.badge-primary]="cell.row.data.mailHisto.statut === BONCF_MAIL_STATUT.ENVOYE"
        [class.badge-success]="cell.row.data.mailHisto.statut === BONCF_MAIL_STATUT.LU"
        [class.badge-danger]="cell.row.data.mailHisto.statut === BONCF_MAIL_STATUT.EN_ERREUR"
        (click)="openHistoriqueMails(cell.row.data)">
        <span class="fa-layers fa-fw">
        <i class="fas fa-envelope mg-r-5"></i>
        <span class="fa-layers-counter">{{mailHistoSvc.getShortLabel(cell.row.data.mailHisto.statut)}}</span>
        </span>
      </label>
    </span>
  </div>

  <dxo-sorting mode="single"></dxo-sorting>
  <dxo-pager
    [showPageSizeSelector]="true"
    [showNavigationButtons]="false"
    [allowedPageSizes]="[20, 50, 100, 200]"
    [visible]="true"
    [showInfo]="true"
    infoText="{2} proposition(s)">
  </dxo-pager>

</dx-data-grid>

<!--DIALOG MODIFIER UNE PROPOSITION DE COMMANDE-->
<yo-bc-edit-fournisseur [displayDialog]="displayEditDialog" (onSave)="onPropositionAdded()"
                        (onClose)="displayEditDialog=$event" [bonCf]="bonCf"
                        [lignesBonCf]="lignesBonCf"
                        [bonCfs]="propositionsCommandes">
</yo-bc-edit-fournisseur>


<!--DIALOG CRÉER UNE NOUVELLE PROPOSITION DE COMMANDE PAR FOURNISSEUR-->
<yo-create-proposition-fournisseur [displayDialog]="displayAddDialog" [uniteDeProductionList]="uniteDeProductionList"
                    (onSave)="newPropositionCommande($event)" (onClose)="displayAddDialog=$event"></yo-create-proposition-fournisseur>


<!--DIALOG CRÉER UNE NOUVELLE PROPOSITION DE COMMANDE PAR DENREE-->
<yo-create-proposition-denree></yo-create-proposition-denree>


