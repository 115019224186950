import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FamilleProduitDTO} from '../../../core/dtos/famille-produit-dto';
import {FormGroup} from '@angular/forms';
import {GroupFormFields} from '../../../core/suppliers/group-formfields';
import {FamillesProduitService} from '../../../core/services/entities/familles-produit.service';
import {GenericFormService} from '../../../core/services/generics/generic-form.service';
import {FormFieldBaseSupplier} from '../../../core/suppliers/form-fieldbase-supplier';
import {UtilsService} from '../../../core/utils/utils.service';
import {DxTreeListComponent} from 'devextreme-angular';
import {Subscription} from 'rxjs';
import {MSG_KEY, MSG_SEVERITY} from '../../../core/constants';
import {cloneDeep as _cloneDeep} from 'lodash'
import {Auth2Service} from '../../../core/services/security/auth2.service';
import {ResponseWrapper} from "../../../core/suppliers/wrappers/response-wrapper";
import {ToastService} from "../../../core/services/technique/toast.service";


@Component({
  selector: 'yo-dialog-form-famille-produit',
  templateUrl: './dialog-form-famille-produit.component.html',
  styleUrls: ['./dialog-form-famille-produit.component.scss']
})
export class DialogFormFamillesProduitComponent implements OnInit, OnDestroy {

  @ViewChild(DxTreeListComponent, {static: false}) treeParentList: DxTreeListComponent;


  @Input() displayDialog: boolean;
  @Input() parentList: FamilleProduitDTO[];
  @Input() mode: number;
  @Output() onClose = new EventEmitter<boolean>();

  selectedFamilleProduit: FamilleProduitDTO;
  selectedParent: FamilleProduitDTO;

  form: FormGroup;
  formFamilleProduit: FormGroup;
  groups: GroupFormFields [];
  fieldsFamilleProduit: FormFieldBaseSupplier<any>[] = [];

  titleDialog: string;
  isReadOnly: boolean;

  subFamilleProduit: Subscription;
  selectedRowKeys: any[] = [];

  constructor(private familleProduitSvc: FamillesProduitService,
              private gfs: GenericFormService,
              public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private toastSvc: ToastService) {
  }

  ngOnInit(): void {

    this.initForm();

    this.familleProduitSubscription();
  }


  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subFamilleProduit);
  }

  familleProduitSubscription = () => {

    this.selectedRowKeys = [];
    this.subFamilleProduit = this.familleProduitSvc.familleProduit$.subscribe((response:FamilleProduitDTO) => {

      this.selectedFamilleProduit = _cloneDeep(<FamilleProduitDTO>response);

      if (!this.utils.isNullOrEmpty(this.selectedFamilleProduit)) {

        this.initForm();

        // Creation
        if (response.id === 0) {
          this.selectedFamilleProduit = new FamilleProduitDTO();
          this.selectedFamilleProduit.id = 0;
          this.titleDialog = 'Création d\'une famille produit';
        // Edition
        } else {
          this.rightsCheck();
          this.titleDialog = 'Modification du famille produit ' + this.selectedFamilleProduit.libelle;
          this.selectedRowKeys = [];
          if (this.utils.isNullOrEmpty(this.selectedFamilleProduit.parent)) {
            this.selectedParent = this.selectedFamilleProduit.parent;
          } else {
            this.selectedRowKeys.push(this.selectedFamilleProduit.parent.id);
            this.selectedParent = this.selectedFamilleProduit.parent;
          }
        }
      }
    });
  };

  initForm = () => {
    this.fieldsFamilleProduit = this.familleProduitSvc.getFields(this.selectedFamilleProduit);
    this.formFamilleProduit = this.gfs.toFormGroup(this.fieldsFamilleProduit);

    this.form = new FormGroup({
      formFamilleProduit: this.formFamilleProduit
    });
  };

  closeDialog = () => {
    this.displayDialog = false;
    this.onClose.emit(this.displayDialog);
  };

  onChangeParentSelection = (event: any) => {
    this.selectedParent = event.selectedRowsData[0];
    this.selectedFamilleProduit.parent = event.selectedRowsData[0];
  };

  rightsCheck = () => {
    if(!this.utils.isNullOrEmpty(this.selectedFamilleProduit)){
      this.isReadOnly = !this.auth2Svc.isSiteLocal(this.selectedFamilleProduit.site.id);
    }
  };

  save = () => {
    this.gfs.validateAllFormFields(this.form);
    if (this.form.valid) {

      //affectation formGroup au dto
      this.selectedFamilleProduit = this.gfs.mapFormToDTO(this.form) as FamilleProduitDTO;
      this.selectedFamilleProduit.parent = this.selectedParent;
      if (this.checkIfFamilyParentIsItSelf()) {
        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.WARNING, `Le parent selectionné ${this.selectedParent.libelle} ne peut pas être le même famille produit sur lequel vous travaillez`);
        return;
      }

      this.gfs.saveOne(this.selectedFamilleProduit, this.familleProduitSvc.getEntityName()).subscribe(response => {
        let result: ResponseWrapper<boolean> = response;
        if (result.inError)
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Impossible d'enregistrer la famille de produit`);

        this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `La famille produit ${this.selectedFamilleProduit.libelle} a été enregistrée avec succès`);
        this.closeDialog();
        this.familleProduitSvc.getAllFromEnvironnement();

      });
    }
  };

  checkIfFamilyParentIsItSelf = () => !this.utils.isNullOrEmpty(this.selectedParent) && this.selectedParent.id === this.selectedFamilleProduit.id;

  removeParent = () => {
    this.selectedParent = undefined;
    this.selectedRowKeys = [];
  };
}
