import {Component, OnDestroy, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UtilsService} from "../../../../core/utils/utils.service";
import {Auth2Service} from "../../../../core/services/security/auth2.service";
import {RoutemapService} from "../../../../core/services/routemap.service";
import {GenericDatagridService} from "../../../../core/services/generics/generic-datagrid.service";
import {MSG_KEY, MSG_SEVERITY} from "../../../../core/constants";
import {DialogMsgSupplier, Paragraphe} from "../../../../core/suppliers/dialog-msg-supplier";
import {UnitePmsDto} from "../../../../core/dtos/pms/unite-pms-dto";
import {UnitePmsService} from "../../../../core/services/pms/unite-pms.service";
import {SiteDTO} from "../../../../core/dtos/site-dto";
import {ToastService} from "../../../../core/services/technique/toast.service";

@Component({
  selector: 'yo-pms-unite-dialog',
  templateUrl: './pms-unite-dialog.component.html',
  styleUrls: ['./pms-unite-dialog.component.scss']
})
export class PmsUniteDialogComponent implements OnInit, OnDestroy {
  hasPms = false;

  displayDialog = false;

  subOpenDialog: Subscription;

  unite: UnitePmsDto;

  forUpdate: boolean = false;

  form: FormGroup = new FormGroup({});

  dialogTitle = 'Modification d\'une unité';

  sitesList: SiteDTO[];
  siteSelected: SiteDTO;

  constructor(public utils: UtilsService,
              private auth2Svc: Auth2Service,
              private routeMapSvc: RoutemapService,
              public gds: GenericDatagridService,
              private unitePmsSvc: UnitePmsService,
              private toastSvc: ToastService) {
  }

  ngOnInit(): void {
    this.initForm();
    this.initHasPms();
    this.findAllLocalSites();
    this.openDialogVarianteSubscription();
  }

  ngOnDestroy(): void {
    this.utils.unsubscribe(this.subOpenDialog);
  }

  findAllLocalSites = (): void => {
    this.sitesList = this.auth2Svc.utilisateur.siteListLocaux;
  }

  openDialogVarianteSubscription = (): void => {
    this.subOpenDialog = this.unitePmsSvc.openDialog$
      .subscribe((u: UnitePmsDto) => {
        this.displayDialog = true;
        if (!u) {
          this.forUpdate = false;
          this.unite = new UnitePmsDto();
          this.unite.id = null;
          this.dialogTitle = 'Création d\'une unité';
        } else {
          this.forUpdate = true;
          this.unite = u;
          this.siteSelected = u.site;
          this.dialogTitle = 'Modification d\'une unité';
        }
        this.initForm();
      });
  };

  initHasPms = (): void => {
    this.auth2Svc.hasPms$.subscribe(response => this.hasPms = response);
  };

  closeDialog = (): void => {
    this.displayDialog = false;
  };

  canModify = (): boolean => this.unite && this.hasPms;

  save = (): void => {
    if (this.form.valid) {
      this.unite.libelle = this.form.controls['libelle'].value;
      this.unite.abreviation = this.form.controls['abreviation'].value;
      this.unite.site = this.sitesList.find(site => site.id === this.form.controls['site'].value);

      this.unitePmsSvc.save(this.unite)
        .subscribe(response => {
          this.unitePmsSvc.announceSaved(response.one, this.unite.id !== undefined && this.unite.id !== null && this.unite.id !== 0);
          this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.SUCCESS, `La sauvegarde de l'unité a été réalisée avec succès`);
          this.closeDialog();
        });
    } else {
      this.toastSvc.displayToast(MSG_KEY.ROOT, MSG_SEVERITY.ERROR, `Veuillez compléter le formulaire`);
    }
  };

  initForm = (): void => {
    this.form = new FormGroup({
      libelle: new FormControl(this.forUpdate ? this.unite.libelle : '', [Validators.required, Validators.maxLength(10), Validators.minLength(1)]),
      abreviation: new FormControl(this.forUpdate ? this.unite.abreviation : '', [Validators.required, Validators.maxLength(4), Validators.minLength(1)]),
      site: new FormControl(this.forUpdate ? this.unite.site : null, [Validators.required])
    });
  };

  public help = (): DialogMsgSupplier => {
    const dms = new DialogMsgSupplier();
    dms.title = `Unités (Plan de Mesure Sanitaire)`;
    dms.logo = 'fa fa-question-circle  yoni-color';
    const p1: Paragraphe = new Paragraphe();
    p1.title = ``;
    p1.lines = [
    ];

    dms.content = {
      intro: ``,
      paragraphes: []
    };

    return dms;
  };
}
